import { ScaleIcon } from "@heroicons/react/outline"
import { DateTime } from "@/lib/dates"
import { ReactNode } from "react"
import { FiActivity, FiLogIn, FiSunrise } from "react-icons/fi"
import { useLang } from "@/context/lang"
import { useGetDateDiff } from "@/hooks/useShortDate"
import { StatusMessage } from "./StatusMessage"

interface TimelineWrapperProps {
	className?: string
	children: ReactNode
}

export const TimelineWrapper = ({
	className = "",
	...rest
}: TimelineWrapperProps) => (
	<ul className={`${className} space-y-6 overflow-hidden`} {...rest} />
)

const getIcon = (type: string) => {
	if (type === "investment") {
		return <ScaleIcon className="w-6 text-gray-500" />
	}

	if (type === "login") {
		return <FiLogIn size={16} className="text-gray-500" />
	}

	if (type === "project") {
		return <FiSunrise size={16} className="text-gray-500" />
	}

	return <FiActivity size={16} className="text-gray-500" />
}

interface TimelineItemProps {
	type: string
	date: DateTime
	content: string
	position?: "end"
}

export const TimelineItem = ({
	type,
	date,
	content,
	position,
}: TimelineItemProps) => {
	const now = DateTime.local()
	const {
		config: { locale },
	} = useLang()
	const getDateDiff = useGetDateDiff()

	return (
		<li className="relative">
			{position !== "end" && <Divider />}
			<div className="flex items-start space-x-3">
				<IconBubble icon={getIcon(type)} />
				<div className="timeline-item-content flex-1 pt-1.5 text-sm text-gray-500">
					<StatusMessage content={content} />
					<time
						className="whitespace-nowrap"
						title={date.toJSDate().toLocaleString(locale)} // TODO: Clean this up
					>
						{getDateDiff(now, date)}
					</time>
				</div>
			</div>
		</li>
	)
}

const Divider = () => (
	<span
		className="absolute -bottom-4 left-4 top-10 -ml-px w-0.5 bg-gray-200"
		aria-hidden="true"
	/>
)

const IconBubble = ({ icon }: { icon: ReactNode }) => (
	<div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
		{icon}
	</div>
)
