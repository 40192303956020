import ms from "ms"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Card } from "@/components/Card"
import { Odometer } from "@/components/Odometer"
import { Heading } from "@/components/Typography"
import { useDashboardIndexQuery } from "@/api/graphql"

// DateTime
import { DateTime } from "@/lib/dates"
import { apiDateFormat } from "@/misc/constants"

// UI
import { PageTemplate } from "@/templates/PageTemplate"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Queries
import { useFiscalOverviewByYearQuery } from "@/api/graphql"

/**
 * Dashboard
 * @returns
 */
export function Dashboard() {
	const t = {
		dashboard: useTrans("dashboard"),
		investments: useTrans("investments"),
	}
	const { formatCurrency } = useLang()
	const { data } = useFiscalOverviewByYearQueryWithYear()

	const endValue = data?.me?.end?.total_investment_value
		? parseFloat(data.me.end.total_investment_value)
		: 0

	return (
		<>
			<Helmet>
				<title>{t.dashboard("dashboard.title")}</title>
			</Helmet>
			<PageTemplate>
				<div className="flex gap-4">
					<Card>
						<Heading as="h5" className="mb-2">
							My savings
						</Heading>
						<OdometerContainer />
					</Card>
					<Card>
						<Heading as="h5" className="mb-2">
							All projects
						</Heading>
						<OdometerContainerAllProjects />
					</Card>
					<Card>
						<dt className="relative mb-1 text-sm font-medium text-gray-500">
							{t.investments(
								"investments.fiscal.hero.returns_today.title",
							)}
						</dt>
						<dd className="text-sm text-gray-900">
							<Heading as="h2">
								{formatCurrency(endValue)}
							</Heading>
						</dd>
					</Card>
				</div>
			</PageTemplate>
		</>
	)
}

function OdometerContainer() {
	const { data } = useDashboardIndexQuery()
	const [value, set] = useState(
		data?.me?.investor_production_stats?.total_production_for_counter
			? parseFloat(
					data.me.investor_production_stats
						.total_production_for_counter,
			  )
			: 0,
	)

	useEffect(() => {
		function work() {
			set((value) => {
				return (
					value +
					(data?.me?.investor_production_stats?.production_speed
						? parseFloat(
								data.me.investor_production_stats
									.production_speed,
						  )
						: 0)
				)
			})
		}

		// 10 seconds is the production speed
		const timer = setInterval(work, ms("10s"))
		work()
		return () => {
			clearInterval(timer)
		}
	}, [data?.me?.investor_production_stats?.production_speed])

	return (
		<Odometer
			value={value}
			format="d"
			duration={10000}
			auto={false}
			theme="mijnstroom"
		/>
	)
}

function OdometerContainerAllProjects() {
	const { data } = useDashboardIndexQuery()
	const [value, set] = useState(
		data?.project_stats?.total_production_for_counter
			? parseFloat(data.project_stats.total_production_for_counter)
			: 0,
	)

	useEffect(() => {
		function work() {
			set((value) => {
				return (
					value +
					(data?.project_stats?.production_speed
						? parseFloat(data.project_stats.production_speed)
						: 0)
				)
			})
		}
		// 10 seconds is the production speed
		const timer = setInterval(work, ms("10s"))
		work()
		return () => {
			clearInterval(timer)
		}
	}, [data?.project_stats?.production_speed])

	return (
		<Odometer
			value={value}
			format="d"
			duration={10000}
			auto={false}
			theme="mijnstroom"
		/>
	)
}

function useFiscalOverviewByYearQueryWithYear(
	options = { keepPreviousData: false },
) {
	const start: string = DateTime.fromObject({ zone: "Europe/Amsterdam" })
		.set({
			year: parseInt("2024"),
		})
		.startOf("year")
		.toFormat(apiDateFormat)

	const end: string = DateTime.fromObject({ zone: "Europe/Amsterdam" })
		.set({
			year: parseInt("2024") + 1,
		})
		.startOf("year")
		.toFormat(apiDateFormat)

	return useFiscalOverviewByYearQuery(
		{
			start,
			end,
		},
		options,
	)
}
