import { Helmet } from "react-helmet-async"
import { GeneralNotifications } from "@/components/forms/GeneralNotifications"
import { AllProjectNotifications } from "@/components/forms/AllProjectNotifications"
import { useTrans } from "@/i18n"

export const ProfileNotifications = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile.notifications.title")}</title>
			</Helmet>
			<div className="space-y-6 md:space-y-8 lg:space-y-12">
				<GeneralNotifications />
				<AllProjectNotifications />
			</div>
		</>
	)
}
