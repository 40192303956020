// UI
import { Heading } from "@/components/Typography"
import { Card } from "@/components/Card"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { CardWrapper } from "@/components/Card"

// Translations
import { useTrans } from "@/i18n"

// Hooks
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Queries
import { useApiV1ComparisonProjectProductionFactorList } from "@/api/rest/generated/api/api"
import { ProductionFactor } from "@/api/rest/generated/@types"

/**
 * ProductionFactorTable
 * @returns
 */
export function ProductionFactorTable({
	className = "",
}: {
	className?: string
}) {
	const t = useTrans("finance")
	const currentProjectId = useCurrentFinanceProjectId()
	const { data } =
		useApiV1ComparisonProjectProductionFactorList(currentProjectId)

	return (
		<CardWrapper className={className}>
			<Card>
				<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
					{t("finance.performance.production-factor.title")}
				</Heading>
				<Table>
					<TableHead>
						<tr role="row">
							<TableHeading as="th" variant="static">
								{t(
									"finance.performance.production-factor.table.heading.solar-interest-period",
								)}
							</TableHeading>
							<TableHeading as="th" variant="static">
								{t(
									"finance.performance.production-factor.table.heading.production-factor",
								)}
							</TableHeading>
						</tr>
					</TableHead>
					<TableBody role="rowgroup">
						{data && data.length && (
							<>
								{data.map(
									(
										factor: ProductionFactor,
										index: number,
									) => {
										return (
											<TableRowCell
												key={index}
												isOdd={index % 2 === 0}
												role="row"
											>
												<TableDataCell>
													{factor.start}
												</TableDataCell>
												<TableDataCell>
													{factor.production_factor}
												</TableDataCell>
											</TableRowCell>
										)
									},
								)}
							</>
						)}
					</TableBody>
				</Table>
			</Card>
		</CardWrapper>
	)
}
