import { Fragment, Suspense, useMemo, useState } from "react"

import { AnimatePresence, motion } from "@/lib/animations"
import { Helmet } from "react-helmet-async"

import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"

import { useFinancePerformanceQuery } from "@/api/graphql"
import { useTrans } from "@/i18n"
import { sendEvent } from "@/lib/analytics"

// Router
import { useNavigate } from "react-router"
import { Pages } from "@/misc/pages"

// UI
import { classNames } from "@/lib/classnames"
import { Combobox } from "@headlessui/react"
import { CardWrapper } from "@/components/Card"
import { Checkbox } from "@/components/form-controls/Checkbox"
import { Input } from "@/components/form-controls/Input"
import { Label } from "@/components/form-controls/Label"
import { GraphLabels } from "@/components/graphs/GraphLabels"

// Page Template
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Icons
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"

// Hooks
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Graphs
import { ProductionGraphActions } from "@/components/graphs/types/bar/ProductionGraphActions"
import { ProductionCumulative } from "@/components/graphs/types/stacked-area/ProductionCumulative"
import { AreaGraph } from "@/components/graphs/types/area/AreaGraph"
import {
	ProductionGraph,
	useProductionGraphContext,
} from "@/components/graphs/types/bar/ProductionGraph"
import { FinanceGraphCumulativeProduction } from "../components/FinanceGraphCumulativeProduction"
import { FinanceGraphProductionDaily } from "../components/FinanceGraphDailyProduction"
import { FinanceGraphProduction } from "../components/FinanceGraphProduction"
import { FinanceGraphProductionHeader } from "../components/FinanceGraphProductionHeader"
import { FinanceGraphProductionTargetedPerformanceRatio } from "../components/FinanceGraphProductionTargetedPerformanceRatio"
import { ProductionFactorTable } from "../components/ProductionFactorTable"

function useCurrentFinanceProject() {
	const projectId = useCurrentFinanceProjectId()
	const { data } = useFinancePerformanceQuery()

	return data?.projects?.find((project) => project?.id === String(projectId))
}

/**
 * FinancePerformance
 * @returns
 */
export function FinancePerformance() {
	const projectId = useCurrentFinanceProjectId()
	const currentProject = useCurrentFinanceProject()
	const t = { finance: useTrans("finance"), common: useTrans("common") }

	return (
		<>
			<Helmet>
				<title>
					{t.finance("finance.performance.title", {
						projectName: currentProject?.name,
					})}
				</title>
			</Helmet>
			<PageTemplate
				title={t.finance("finance.performance.title", {
					projectName: currentProject?.name,
				})}
				topRightComponent={<FinancePerformanceSelect />}
				backHref={Pages.FinanceProjects}
				tabs={
					<>
						<PageTemplateLink
							to={`${Pages.FinanceProjects}/${projectId}`}
							name={t.common(
								"common.navigation.finance.dashboard.link",
							)}
							analyticsId={t.common(
								"common.navigation.finance.dashboard.link",
							)}
						/>
						<PageTemplateLink
							to={`${Pages.FinanceSolarInterestTariff}/${projectId}`}
							name={t.common(
								"common.navigation.finance.solar_interest_tariff.link",
							)}
							analyticsId={t.common(
								"common.navigation.finance.dashboard.link",
							)}
						/>
					</>
				}
			>
				<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
					<div className="flex flex-col gap-8 md:col-span-4 md:flex-row">
						{/** Daily production */}
						<CardWrapper className="md:w-2/3">
							<ErrorBoundaryWithErrorState>
								<AreaGraph>
									<AreaGraph.Content>
										<FinanceGraphProductionDaily />
									</AreaGraph.Content>
								</AreaGraph>
							</ErrorBoundaryWithErrorState>
						</CardWrapper>
						<ProductionFactorTable className="md:w-1/3" />
					</div>

					{/** Periodic production */}
					<CardWrapper className="md:col-span-4">
						<ErrorBoundaryWithErrorState>
							<ProductionGraph>
								<ProductionGraph.Header>
									<FinanceGraphProductionHeader />
								</ProductionGraph.Header>
								<ProductionGraph.Actions>
									<FinanceGraphActions />
								</ProductionGraph.Actions>
								<ProductionGraph.Content>
									<FinanceGraphProduction />
								</ProductionGraph.Content>
								<ProductionGraph.Footer>
									<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
										<GraphLabels.TotalProduction />
										<GraphLabels.ExpectedProduction />
										<GraphLabels.PerformanceRatio />
										<GraphLabels.ExpectedPerformanceRatio />
									</GraphLabels>
								</ProductionGraph.Footer>
							</ProductionGraph>
						</ErrorBoundaryWithErrorState>
					</CardWrapper>

					{/** Cumulative production */}
					<CardWrapper className="md:col-span-4">
						<ErrorBoundaryWithErrorState>
							<ProductionCumulative>
								<ProductionCumulative.Content>
									<FinanceGraphCumulativeProduction />
								</ProductionCumulative.Content>
								<ProductionCumulative.Footer>
									<GraphLabels className="px-4 pb-3 sm:px-6 sm:pb-5">
										<GraphLabels.TotalProduction />
										<GraphLabels.ExpectedProduction />
									</GraphLabels>
								</ProductionCumulative.Footer>
							</ProductionCumulative>
						</ErrorBoundaryWithErrorState>
					</CardWrapper>
				</div>
			</PageTemplate>
		</>
	)
}

function FinanceGraphActions() {
	const t = useTrans("finance")
	const { knmiDataStatus, setKnmiDataStatus } = useProductionGraphContext()

	return (
		<div className="items-center space-y-4 lg:flex lg:space-x-8 lg:space-y-0">
			<AnimatePresence>
				<Suspense fallback={null}>
					<motion.div
						animate={{ opacity: 1 }}
						initial={{ opacity: 0 }}
						exit={{ opacity: 0 }}
					>
						<FinanceGraphProductionTargetedPerformanceRatio />
					</motion.div>
				</Suspense>
			</AnimatePresence>
			<Label className="!mb-0 flex">
				<Checkbox
					id="knmi"
					name="knmi"
					className="mr-2"
					checked={knmiDataStatus === "included"}
					onChange={(e) => {
						setKnmiDataStatus(
							e.target.checked ? "included" : "excluded",
						)
					}}
				/>

				{t("finance.production_graph.toggle_knmi")}
			</Label>
			<ProductionGraphActions />
		</div>
	)
}

function FinancePerformanceSelect() {
	const { data } = useFinancePerformanceQuery()
	const currentProject = useCurrentFinanceProject()
	const [query, setQuery] = useState<string>(currentProject?.name ?? "")
	const navigate = useNavigate()

	const sortedProjects = useMemo(() => {
		return (
			data?.projects?.sort(
				(a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0,
			) ?? []
		)
	}, [data?.projects])

	const filteredProjects =
		query === "" || query === currentProject?.name
			? sortedProjects
			: sortedProjects?.filter((project) => {
					return project?.name
						?.toLowerCase()
						.includes(query.toLowerCase())
			  })

	function onChange(id: string) {
		sendEvent("finance", "performance_project_filter", {
			label: id,
		})
		navigate(`${Pages.FinanceProjects}/${id}`)

		// set query to next project name
		const nextProject = data?.projects?.find(
			(project) => project?.id === id,
		)?.name
		if (nextProject) {
			setQuery(nextProject)
		}
	}

	return (
		<Combobox
			as={Fragment}
			value={currentProject?.id}
			onChange={(value) => {
				if (value) onChange(value)
			}}
		>
			<div className="relative mt-1 w-full max-w-xs">
				<Combobox.Button as={Fragment}>
					<Input
						className="w-full"
						onChange={(e) => {
							setQuery(e.currentTarget.value)
						}}
						value={query ?? ""}
					/>
				</Combobox.Button>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<SelectorIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Combobox.Button>

				{filteredProjects && filteredProjects?.length > 0 && (
					<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredProjects.map((project) => (
							<Combobox.Option
								key={project?.id}
								value={project?.id}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active
											? "bg-primary-500"
											: "text-gray-900",
									)
								}
							>
								{({ selected }) => (
									<>
										<div className="flex">
											<span
												className={classNames(
													"truncate",
													selected && "font-semibold",
												)}
											>
												{project?.name}
											</span>
										</div>

										{selected && (
											<span className="text-black-500 absolute inset-y-0 right-0 flex items-center pr-4">
												<CheckIcon
													className="h-5 w-5"
													aria-hidden="true"
												/>
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}
