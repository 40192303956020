import { Helmet } from "react-helmet-async"
import { Navigate, Route, Routes } from "react-router-dom"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { useTrans } from "@/i18n"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"
import { Investments as InvestmentsView } from "./components/Investments"

// Pages
import { Fiscal } from "./Fiscal"
import { Production } from "./Production"
import { Pages } from "@/misc/pages"
import { ProvisionalInvestments } from "./Investments"

// UI
import { InvestmentOppertunityBanner } from "@/components/banners/InvestmentOppertunityBanner"

export function InvestmentsPage() {
	const t = useTrans("investments")
	const transCommon = useTrans("common")

	return (
		<>
			<Helmet>
				<title>{t("investments.title")}</title>
			</Helmet>
			<PageTemplate
				title={t("investments.title")}
				tabs={
					<>
						<PageTemplateLink
							to={Pages.InvestmentsProjectsDashboard}
							data-testid="investments.header"
							name={t("investments.header")}
							analyticsId="investments"
						/>
						<PageTemplateLink
							to={Pages.ProvisionalInvestments}
							data-testid="investments.provisional_investments"
							name={transCommon(
								"common.nav.provisional_investments",
							)}
							analyticsId="common.nav.provisional_investments"
						/>
						<PageTemplateLink
							to={Pages.InvestmentsProjectsFiscalOverview}
							data-testid="investments.fiscal.header"
							name={transCommon("common.nav.fiscal")}
							analyticsId="investments.fiscal"
						/>
						<PageTemplateLink
							to={Pages.InvestmentsProjectsProduction}
							data-testid="investments.production.header"
							name={transCommon("common.nav.total_production")}
							analyticsId="investments.production"
						/>
					</>
				}
			>
				<Routes>
					<Route
						path="provisional"
						element={<ProvisionalInvestments />}
					/>
					<Route path="fiscal-overview" element={<Fiscal />} />
					<Route path="production" element={<Production />} />
					<Route path="/" element={<Index />} />
					<Route element={<Navigate to="." />} />
				</Routes>
			</PageTemplate>
		</>
	)
}

function Index() {
	const t = useTrans("investments")

	return (
		<>
			<InvestmentOppertunityBanner />
			<Card className="mb-8 mt-5">
				<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
					{t("investments.header")}
				</Heading>
				<p className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
					{t("investments.copy")}
				</p>
			</Card>
			<Card variant="gray">
				<InvestmentsView />
			</Card>
		</>
	)
}
