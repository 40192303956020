import {
	ComponentProps,
	ReactElement,
	Dispatch,
	SetStateAction,
	createContext,
	Fragment,
	ReactNode,
	useContext,
	useState,
	forwardRef,
	Ref,
	useEffect,
} from "react"

// Debug tools
import { mountVercelToolbar } from "@vercel/toolbar"

// Router
import { NavLink as RouterNavLink, useMatch } from "react-router-dom"
import logoBlack from "@/assets/icons/zpd/black.svg"
import logo from "@/assets/icons/zpd/logo.svg"
import { Pages } from "@/misc/pages"

// UI
import { classNames } from "@/lib/classnames"
import { Link } from "@/components/Anchor"
import { Heading } from "@/components/Typography"
import WelcomeText from "@/components/WelcomeText"
import { Dialog, Menu, Transition } from "@headlessui/react"
import Tippy from "@tippyjs/react"

// Animations
import { motion, getTransitionPopoverProps } from "@/lib/animations"

// Icons
import { FiUser, FiChevronLeft, FiChevronsRight } from "react-icons/fi"
import { MenuIcon } from "@/components/MenuIcon"
import { CurrencyEuroIcon, HomeIcon } from "@heroicons/react/outline"

import { UserRoles } from "@/misc/constants"

// Translations
import { Trans, useTrans } from "@/i18n"

// Misc
import { useAuth } from "@/context/auth"
import { useToasts } from "@/context/toasts"
import { useCurrentUserRoles } from "@/context/user"
import { useNextLayoutQuery } from "@/api/graphql"
import { sendEvent } from "@/lib/analytics"
import { normaliseString } from "@/lib/js"

// Sub menu structures
import { QuickMenu } from "@/components/navigation/QuickMenu"
import { InvestmentsSubMenu } from "@/components/navigation/InvestmentsSubMenu"
import { FinanceSubMenu } from "@/components/navigation/FinanceSubMenu"
import { RevenueSubMenu } from "@/components/navigation/RevenueSubMenu"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// Env variables
const EXTERNAL_PLATFORM_INFO_CENTER =
	process.env.REACT_APP_EXTERNAL_PLATFORM_INFO_CENTER
const API_URL = process.env.REACT_APP_API_URL
const REACT_APP_VERCEL_ENV = process.env.REACT_APP_VERCEL_ENV

// Types
interface NextLayoutProps {
	children?: ReactNode
}

export const NextLayoutContext = createContext<{
	isMobileMenuOpen: boolean
	isDesktopMenuOpen: boolean
	setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
	setIsDesktopMenuClosed: Dispatch<SetStateAction<boolean>>
}>(null!)

export function NextLayout({ children }: NextLayoutProps) {
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
	const [isDesktopMenuOpen, setIsDesktopMenuClosed] = useState(true)
	const { data } = useNextLayoutQuery()
	const t = useTrans()
	const { getFeatureFlagValue } = useFeatureFlags()

	function setMobileMenuOpenProxy(
		context: string = "mobilemenuiconontoggle",
	) {
		const nextValue = !isMobileMenuOpen
		sendEvent("layout", context, {
			label: nextValue ? "open" : "close",
		})
		setMobileMenuOpen(nextValue)
	}

	function setIsDesktopMenuClosedProxy() {
		const nextValue = !isDesktopMenuOpen
		sendEvent("layout", "desktopmenuicon", {
			label: nextValue ? "open" : "close",
		})
		setIsDesktopMenuClosed(nextValue)
	}

	return (
		<NextLayoutContext.Provider
			value={{
				isMobileMenuOpen,
				isDesktopMenuOpen,
				setMobileMenuOpen,
				setIsDesktopMenuClosed,
			}}
		>
			<div className="h-full min-h-full bg-gray-50 md:flex md:h-auto">
				{/* sidebar: desktop menu, a.k.a. the left nav on md: viewports and above */}
				<motion.div
					className="hidden min-h-full flex-shrink-0 bg-white text-black md:block"
					initial={{ width: 256 }}
					animate={{ width: isDesktopMenuOpen ? 256 : 40 }}
					transition={{
						duration: 0.4,
						ease: [0.22, 0.65, 0.275, 1],
					}}
				>
					<div className="sticky top-0 flex h-screen w-[256px] flex-col overflow-y-auto">
						<div
							className={classNames(
								"flex h-14 flex-shrink-0 items-center bg-primary-500 shadow-[0px_1px_3px_rgba(0,0,0,0.25)] 2xl:h-16",
								"pl-2",
							)}
						>
							{isDesktopMenuOpen === true && <Logo />}

							{/* menu icon for collapsble nav on desktop */}
							{getFeatureFlagValue(
								"ENABLE_MENU_COLLAPSE_ON_DESKTOP",
							) === true && (
								<button
									onClick={setIsDesktopMenuClosedProxy}
									className="hidden h-full items-center justify-center whitespace-nowrap hover:bg-gray-50 md:flex"
								>
									{isDesktopMenuOpen === true ? (
										<FiChevronLeft
											aria-hidden="true"
											className="h-4 w-4 text-gray-500"
										/>
									) : (
										<FiChevronsRight
											aria-hidden="true"
											className="h-4 w-4 text-gray-500"
										/>
									)}
								</button>
							)}
						</div>

						{/** Main nav */}
						<Nav />

						{/** Settings nav */}
						<div className="mt-auto border-t border-gray-200 pt-3">
							<QuickMenu />
						</div>
					</div>
				</motion.div>
				{/* mobile nav */}
				<Transition.Root show={isMobileMenuOpen} as={Fragment}>
					<Dialog
						as="div"
						className="md:hidden"
						onClose={() =>
							setMobileMenuOpenProxy("mobilemenuclose")
						}
					>
						<div className="fixed inset-0 z-40 flex h-full">
							<Transition.Child
								as={Fragment}
								enter="transition-opacity ease-linear duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity ease-linear duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
							</Transition.Child>
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<div className="relative flex w-full max-w-[256px] flex-1 flex-col bg-white sm:max-w-sm">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute right-0 top-1 -mr-14 p-1">
											<button
												type="button"
												className="flex h-12 w-12 items-center justify-center"
												onClick={() =>
													setMobileMenuOpenProxy(
														"mobilemenuiconclose",
													)
												}
											>
												<MenuIcon
													className="h-6 w-6 text-white"
													aria-hidden="true"
													isOpen={false}
												/>
												<span className="sr-only">
													{t(
														"common.navigation.close_sidebar.sr_text",
													)}
												</span>
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-screen flex-1 flex-col overflow-y-auto pt-5">
										<div className="pl-2">
											<Logo />
										</div>
										<div className="flex-1">
											<Nav />
										</div>
										<div className="flex-shrink-0 space-y-3 border-t border-gray-200 pt-4">
											<QuickUserMenu />
											<QuickMenu />
										</div>
									</div>
								</div>
							</Transition.Child>
							<div
								className="w-14 flex-shrink-0"
								aria-hidden="true"
							>
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>
				{/* content area */}
				{/* "padding top" here is to offset the top bar on mobile */}
				<div className="flex min-h-full w-full flex-grow flex-col pt-14 md:max-w-[calc(100%_-_256px)] md:pt-0">
					<div className="fixed top-0 z-30 grid h-14 w-full grid-cols-[auto_auto] justify-between gap-3 bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.125)] md:static md:grid-cols-[1fr_auto] md:px-4 2xl:h-16">
						{/* welcome text */}
						<div className="text-md hidden h-full items-center overflow-hidden text-gray-400 md:flex">
							<Tippy
								content={t("common.layout.welcome.tippy")}
								className="text-center"
							>
								<p className="truncate">
									<Trans
										i18nKey="common.layout.welcome"
										ns="common"
										values={{ name: data?.me?.first_name }}
										components={{
											strong: (
												<span className="pr-1 text-primary-500" />
											),
											message: <WelcomeText />,
										}}
									/>
								</p>
							</Tippy>
						</div>
						{/* mobile nav menu icon */}
						<button
							type="button"
							className="order-2 flex h-full w-14 items-center justify-center px-3 md:order-1 md:hidden"
							onClick={() => setMobileMenuOpenProxy()}
							data-testid="navigation.open_sidebar"
						>
							<span className="sr-only">
								{t("common.navigation.open_sidebar.sr_text")}
							</span>
							<MenuIcon
								isOpen={true}
								aria-hidden="true"
								className="h-3 w-6"
							/>
						</button>
						{/* zpd logo */}
						<div className="flex items-center md:hidden">
							<RouterNavLink to="/" className="px-4">
								<img
									src={logo}
									alt={t("common.layout.title")}
									className="h-auto w-8 md:w-6"
								/>
							</RouterNavLink>
						</div>
						{/* user profile icon */}
						<div className="ml-auto hidden h-full items-center gap-3 md:flex md:gap-2">
							<UserMenu />
							<UserAndRole className="text-md hidden text-right text-gray-400 md:text-left" />
						</div>
					</div>
					{children}

					{/** Footer */}
					<div className="flex-1 px-4 pb-6 sm:px-7">
						<div className="flex max-w-7xl">
							<div className="flex-1">
								{" "}
								&copy; ZonnepanelenDelen 2024
							</div>
							<a
								href={EXTERNAL_PLATFORM_INFO_CENTER}
								target="_blank"
								className="flex-1 text-right font-medium text-secondary-300 hover:text-secondary-700"
								rel="noreferrer"
							>
								{t("common.layout.footer.faq")}
							</a>
						</div>
					</div>
				</div>
			</div>
		</NextLayoutContext.Provider>
	)
}

function QuickUserMenu() {
	const t = useTrans()
	const { isMobileMenuOpen, setMobileMenuOpen } =
		useContext(NextLayoutContext)

	function onClick() {
		const nextValue = !isMobileMenuOpen
		sendEvent("layout", "quickmenuprofile", {
			label: nextValue ? "open" : "close",
		})
		setMobileMenuOpen(nextValue)
	}

	return (
		<div className="px-1">
			<Link
				href="/profile"
				className="flex items-center px-2"
				onClick={onClick}
			>
				<div className="relative mr-3 block h-6 w-6 overflow-hidden rounded-[50%] border-2 border-gray-400 bg-white px-2 py-2">
					<span className="sr-only">
						{t("common.layout.open_user_menu")}
					</span>
					<UserIcon className="absolute -bottom-1 left-[50%] -translate-x-1/2" />
				</div>
				<UserAndRole className="text-md truncate text-left text-gray-400" />
			</Link>
		</div>
	)
}

function UserIcon({ className = "" }) {
	return (
		<>
			<FiUser
				className={classNames(
					className,
					"h-[95%] w-[95%] fill-gray-400 text-gray-400",
				)}
			/>
		</>
	)
}

/**
 * UserMenu
 * @returns
 */
function UserMenu() {
	const t = useTrans()
	const auth = useAuth()
	const toasts = useToasts()
	const { getFeatureFlagValue } = useFeatureFlags()
	const { hasOneOfRoles } = useCurrentUserRoles()

	// Determine user navigation
	let userNavigation =
		getFeatureFlagValue("DISPLAY_LINKS_IN_USER_MENU") === true
			? [
					{
						name: t("common.layout.quick_menu.your_profile"),
						type: "link",
						href: Pages.SettingsProfilePersonalDetails,
					},
					{
						name: t("common.layout.quick_menu.sign_out"),
						type: "callback",
						onClick: async () => {
							try {
								auth.logOut()
							} catch (e) {
								console.error("Could not log out", e)
								toasts.addToast({
									id: "common.layout.quick_menu.sign_out",
									text: "Failed to log out, try again later.",
								})
							}
						},
					},
			  ]
			: []

	// On Mount, check for admin permissions
	useEffect(() => {
		if (hasOneOfRoles(UserRoles.admin)) {
			// Show vercel toolbar
			if (REACT_APP_VERCEL_ENV !== "production") {
				mountVercelToolbar()
			}

			// Add App info page
			userNavigation.push({
				name: "App information",
				type: "link",
				href: Pages.Info,
			})
		}
	}, [hasOneOfRoles])

	return (
		<Menu as="div" className="relative order-1 md:order-2">
			{({ open }) => (
				<>
					<Menu.Button
						className="relative h-8 w-8 overflow-hidden rounded-[50%] border-2 border-gray-400 bg-white"
						onClick={() => {
							sendEvent("layout", "usermenu_icon_onclick")
						}}
					>
						<span className="sr-only">
							{t("common.layout.open_user_menu")}
						</span>
						<UserIcon className="absolute -bottom-1 left-[50%] -translate-x-1/2" />
					</Menu.Button>
					<Transition {...getTransitionPopoverProps()} show={open}>
						<Menu.Items
							static
							className={classNames(
								"absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white pt-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
								// just a bit more padding if there are menu.items!
								getFeatureFlagValue(
									"DISPLAY_LINKS_IN_USER_MENU",
								) === true && "pb-1",
							)}
						>
							<UserAndRole
								className={classNames(
									"px-4 py-2 text-right text-sm text-gray-600 md:text-left",
									// seperates the menu.items from the button
									getFeatureFlagValue(
										"DISPLAY_LINKS_IN_USER_MENU",
									) === true && "border-b border-b-gray-200",
								)}
							/>
							{userNavigation.map((item) => (
								<Menu.Item key={item.name}>
									{({ active }) =>
										item.type === "link" && item.href ? (
											<Link
												href={item.href}
												className={classNames(
													active && "bg-gray-100",
													"flex items-center px-4 py-2 text-sm text-gray-700",
												)}
												onClick={() => {
													sendEvent(
														"layout",
														"usermenu_item_onclick",
														{ label: item.name },
													)
												}}
											>
												{item.name}
											</Link>
										) : (
											<p
												onClick={() => {
													sendEvent(
														"layout",
														"usermenu_item_onclick",
														{ label: item.name },
													)
													item.onClick?.()
												}}
												className={classNames(
													active && "bg-gray-100",
													"flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700",
												)}
											>
												{item.name}
											</p>
										)
									}
								</Menu.Item>
							))}
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	)
}

function UserAndRole({ className = "" }) {
	const { data } = useNextLayoutQuery()

	return (
		<p className={classNames(className)}>
			{data?.me?.first_name} {data?.me?.last_name}
		</p>
	)
}

function Logo() {
	const t = useTrans()

	return (
		<>
			{true ? (
				<Heading
					as="h2"
					styleAs="h5"
					className="flex h-full w-full items-center truncate pl-1 text-black md:px-4"
				>
					<a href={API_URL} className="flex">
						<img
							src={logo}
							className="mr-2 h-6 w-6 brightness-0 grayscale"
							alt=""
						/>
						<Trans
							ns="common"
							i18nKey="common.layout.title"
							components={{
								thin: <span className="font-medium" />,
							}}
						/>
					</a>
				</Heading>
			) : (
				<div className="pl-1 md:px-4">
					<img
						src={logoBlack}
						alt={t("common.layout.title")}
						className="h-auto w-[179px]"
					/>
				</div>
			)}
		</>
	)
}

/**
 * Nav
 * @returns
 */
function Nav() {
	const t = useTrans()
	const { hasOneOfRoles } = useCurrentUserRoles()
	const { getFeatureFlagValue } = useFeatureFlags()

	return (
		<>
			<nav className="mt-8 space-y-1 px-1 pb-4">
				{/** RevenueSubMenu */}
				{getFeatureFlagValue("ENABLE_MENU_ITEM_REVENUE") && (
					<NavItem
						name={"Earnings"}
						datda-testid="common.nav.dashboard"
						icon={HomeIcon}
					>
						<RevenueSubMenu />
					</NavItem>
				)}

				{/** InvestmentsSubMenu */}
				<NavItem
					name={t("common.navigation.investments.title")}
					datda-testid="common.nav.dashboard"
					icon={HomeIcon}
				>
					<InvestmentsSubMenu />
				</NavItem>

				{/** FinanceSubMenu */}
				{hasOneOfRoles(
					UserRoles.energyProvider,
					UserRoles.admin,
					UserRoles.installer,
					UserRoles.staff,
					UserRoles.projectOwner,
				) ? (
					<NavItem
						name={t("common.navigation.finance.title")}
						icon={CurrencyEuroIcon}
						datda-testid="common.nav.investments"
					>
						<FinanceSubMenu />
					</NavItem>
				) : null}
			</nav>
		</>
	)
}

/**
 * NavItem
 * @param param0
 * @returns
 */
export function NavItem({
	href,
	icon,
	name,
	children,
}: {
	href?: string
	icon: (props: ComponentProps<"svg">) => ReactElement
	name: string
	children?: ReactNode
}) {
	return (
		<div>
			<NavLink href={href} icon={icon} name={name} children={children} />
			<div>
				{children ? (
					<div className="w-full overflow-hidden pl-10">
						<div className="py-1">{children}</div>
					</div>
				) : null}
			</div>
		</div>
	)
}

const NavLink = forwardRef<
	HTMLDivElement | HTMLAnchorElement,
	{
		href?: string
		icon: (props: ComponentProps<"svg">) => ReactElement
		name: string
		children?: ReactNode
		open?: boolean
	}
>(({ name, children, icon: Icon, href = "", open }, ref) => {
	const { setMobileMenuOpen } = useContext(NextLayoutContext)

	const current = useMatch(href === "/" ? { path: href, end: true } : href)

	const NavContent = () => {
		return (
			<>
				<Icon
					className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
					aria-hidden="true"
				/>
				{name}
			</>
		)
	}

	return href ? (
		<RouterNavLink
			ref={ref as Ref<HTMLAnchorElement>}
			to={href}
			className={classNames(
				current
					? "bg-gray-100 text-gray-900"
					: "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
				"group flex items-center rounded-md px-2 py-2 text-sm font-medium",
			)}
			onClick={() => {
				setMobileMenuOpen(false)
				sendEvent("layout", "navitem_onclick", {
					label: normaliseString(name),
				})
			}}
		>
			<NavContent />
		</RouterNavLink>
	) : (
		<div
			className={classNames(
				current
					? "pointer-events-none bg-gray-100 text-gray-900"
					: "pointer-events-none text-gray-600 hover:bg-gray-50 hover:text-gray-900",
				"group flex items-center rounded-md px-2 py-2 text-sm font-medium",
			)}
		>
			<NavContent />
		</div>
	)
})
