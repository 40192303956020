import { Helmet } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"
import { useTrans } from "@/i18n"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"
import { useCurrentProject } from "./hooks"

// Pages
import { Pages } from "@/misc/pages"
import { NotFound } from "@/pages/NotFound"
import { ProjectCommunications } from "./pages/Communications"
import { ProjectDashboard } from "./pages/ProjectDashboard"
import { ProjectDocumentation } from "./pages/Documentation"
import { ProjectNotifications } from "./pages/Notifications"
import { ProjectProduction } from "./pages/Production"
import { ProjectRevenue } from "./pages/Revenue"
import { ProjectNotFound } from "./ProjectNotFound"
import { isDevelopment } from "@/misc/helpers"

// Queries
import { ProjectTypeEnum } from "@/api/graphql"

export const Project = () => {
	const { data, refetch } = useCurrentProject()
	const t = useTrans("project")

	if (data?.project === null) {
		return <ProjectNotFound onReset={refetch} />
	}

	return (
		<>
			<Helmet>
				<title>{data?.project?.name}</title>
			</Helmet>
			<PageTemplate
				title={data?.project?.name ?? ""}
				backHref={Pages.InvestmentsProjectsDashboard}
				tabs={
					<>
						<PageTemplateLink
							to="./dashboard"
							end
							data-testid="project.dashboard"
							name={t("project.dashboard.title")}
							analyticsId="project.dashboard"
						/>
						<PageTemplateLink
							to="./revenue"
							data-testid="project.revenue"
							name={t("project.revenue.title")}
							analyticsId="project.revenue"
						/>
						{data?.project?.type !==
							("PORTFOLIO" as ProjectTypeEnum) && (
							<PageTemplateLink
								to="./data"
								data-testid="project.data"
								name={t("project.data.title")}
								analyticsId="project.data"
							/>
						)}
						<PageTemplateLink
							to="./notifications"
							data-testid="project.notifications"
							name={t("project.notifications.title")}
							analyticsId="project.notifications"
						/>
						{isDevelopment() && (
							<PageTemplateLink
								to="./communications"
								data-testid="project.communications"
								name={t("project.communications.title")}
								analyticsId="project.communications"
							/>
						)}
						<PageTemplateLink
							to="./documentation"
							data-testid="project.documentation"
							name={t("project.documentation.title")}
							analyticsId="project.documentation"
						/>
					</>
				}
			>
				<Routes>
					<Route path="revenue" element={<ProjectRevenue />} />
					<Route
						path="notifications"
						element={<ProjectNotifications />}
					/>
					<Route path="data" element={<ProjectProduction />} />
					<Route
						path="communications"
						element={<ProjectCommunications />}
					/>
					<Route
						path="documentation"
						element={<ProjectDocumentation />}
					/>
					<Route path="dashboard" element={<ProjectDashboard />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</PageTemplate>
		</>
	)
}
