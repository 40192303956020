import { useTrans } from "@/i18n"

// Forms
import { Yup } from "@/lib/yup"
import { FormikProvider, useFormik } from "formik"
import { SelectButton, Select, FormGroup } from "@/components/form-controls"
import { FiChevronDown } from "react-icons/fi"
import {
	FormikError,
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { LabelSubtitle } from "@/components/form-controls/Label"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat, apiDateFormat } from "@/misc/constants"

// UI
import { CheckIcon } from "@heroicons/react/outline"
import { Heading } from "@/components/Typography"
import { CardBody, CardFooter, CardWrapper, Card } from "@/components/Card"
import { useToasts } from "@/context/toasts"

// Queries
import { RiskKycStateChoices, useUserKycStatusQuery } from "@/api/graphql"
import { usersKycDocumentsUploadCreate } from "@/api/rest/generated/users/users"
import {
	KycVerificationDocument,
	DocumentTypeEnum,
} from "@/api/rest/generated/@types"

/**
 * InvestorIdentityForm
 * @returns
 */
export const InvestorIdentityForm = () => {
	const toasts = useToasts()
	const t = { investor: useTrans("investor") }
	const { data, refetch } = useUserKycStatusQuery()

	// Form
	const form = useFormik({
		initialValues: {
			document: "",
			document_back: "",
			document_type: undefined as DocumentTypeEnum | undefined,
			expiry_date: "",
			full_first_name: "",
		},
		validationSchema: Yup.object().shape({
			expiry_date: Yup.string().required(
				t.investor(
					"investor.identity.form.field.expiry-date.error.required",
				),
			),
			full_first_name: Yup.string().required(
				t.investor(
					"investor.identity.form.field.first-name.error.required",
				),
			),
		}),
		onSubmit: async (values, helpers) => {
			helpers.setStatus("")
			try {
				const uuid = data?.me?.uuid
				const response = await usersKycDocumentsUploadCreate(uuid, {
					document: values.document,
					document_back: values.document_back,
					document_type: values.document_type as DocumentTypeEnum,
					expiry_date: DateTime.fromFormat(
						values.expiry_date,
						dateFormat,
					).toFormat(apiDateFormat),
					full_first_name: values.full_first_name,
				} as KycVerificationDocument)

				// When payment has been confirmed
				if (response.ok === true) {
					toasts.addToast({
						variant: "success",
						id: `usersKycDocumentsUploadCreate-${Date.now()}`,
						text: t.investor("investor.identity.form.success"),
					})
					refetch()
				} else {
					// Parse all fields in response, assuming these are errors!
					Object.keys(form.values).forEach((field) => {
						// @ts-ignore
						if (response?.[field]) {
							// @ts-ignore
							form.setFieldError(field, response?.[field]?.[0])
						}
					})

					// Show generic error toast
					toasts.addToast({
						variant: "alert",
						id: `usersKycDocumentsUploadCreate-${Date.now()}`,
						text: t.investor("investor.identity.form.error"),
					})
				}
			} catch (e) {
				toasts.addToast({
					variant: "alert",
					id: `usersKycDocumentsUploadCreate-${Date.now()}`,
					text: t.investor("investor.identity.form.error"),
				})
			}
		},
	})

	return (
		<FormikProvider value={form}>
			<CardWrapper className="relative">
				{/** Show blocking overlay when status is NOT requested */}
				{data?.me?.kyc?.state !==
					RiskKycStateChoices.VerificationRequested && (
					<StepCompletedOverlay
						state={data?.me?.kyc?.state}
						message={data?.me?.kyc?.human_readable_kyc_error}
						last_verified_on={data?.me?.kyc?.last_verified_on}
					/>
				)}

				<form onSubmit={form.handleSubmit}>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
							data-testid="investor.identity.title"
						>
							{t.investor("investor.identity.title")}
						</Heading>
						<FormGroup
							heading={
								<>
									<p>
										{t.investor("investor.identity.copy")}
									</p>
									<ul className="ml-4 list-disc">
										<li>
											{t.investor(
												"investor.identity.photo-requirements.req.color",
											)}
										</li>
										<li>
											{t.investor(
												"investor.identity.photo-requirements.req.visibility",
											)}
										</li>
										<li>
											{t.investor(
												"investor.identity.photo-requirements.req.edges",
											)}
										</li>
										<li>
											{t.investor(
												"investor.identity.photo-requirements.req.no-watermark",
											)}
										</li>

										{/** Requirement: Backside */}
										{(form.values.document_type ===
											DocumentTypeEnum.driving_license ||
											form.values.document_type ===
												DocumentTypeEnum.national_id_number ||
											form.values.document_type ===
												DocumentTypeEnum.residence_permit) && (
											<li>
												{t.investor(
													"investor.identity.photo-requirements.req.back-side",
													{
														documentType:
															t.investor(
																`investor.identity.form.field.document-type.options.${form.values.document_type}`,
															),
													},
												)}
											</li>
										)}
										<li>
											{t.investor(
												"investor.identity.photo-requirements.req.mask-bsn",
											)}
										</li>
									</ul>
								</>
							}
						>
							<div className="space-y-6">
								<div className="relative flex space-x-6">
									{/** Example img */}
									<img
										className="align-center w-1/2"
										src={
											{
												default:
													"/assets/kyc/choose_id.jpg",
												driving_license:
													"/assets/kyc/driving_license_example.jpg",
												national_id_number:
													"/assets/kyc/national_id_example.jpg",
												passport:
													"/assets/kyc/passport_example.jpg",
												residence_permit:
													"/assets/kyc/residence_permit_example.jpg",
											}[
												form.values.document_type ||
													"default"
											]
										}
										alt={form.values.document_type}
									/>

									{/** Document Type */}
									<div className="relative flex-col">
										<FormikLabel>
											{t.investor(
												"investor.identity.form.field.document-type.label",
											)}
											<LabelSubtitle>
												{t.investor(
													"investor.identity.form.field.document-type.sublabel",
												)}
											</LabelSubtitle>
										</FormikLabel>
										<SelectButton className="w-full">
											{form.values.document_type ===
											undefined
												? t.investor(
														"investor.identity.form.field.document-type.label",
												  )
												: t.investor(
														`investor.identity.form.field.document-type.options.${form.values.document_type}`,
												  )}
											<FiChevronDown className={"ml-2"} />
											<Select
												name="document_type"
												className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
												onChange={(evt) =>
													form.setFieldValue(
														"document_type",
														evt.currentTarget
															.value as DocumentTypeEnum,
													)
												}
												value={
													form.values.document_type ??
													undefined
												}
											>
												{/** Default option */}
												<option
													disabled
													selected={
														form.values
															.document_type ===
														undefined
													}
												>
													{t.investor(
														"investor.identity.form.field.document-type.label",
													)}
												</option>
												{Object.entries(
													DocumentTypeEnum,
												).map(([key, value]) => (
													<option
														value={value}
														key={value}
													>
														{t.investor(
															`investor.identity.form.field.document-type.options.${value}`,
														)}
													</option>
												))}
											</Select>
										</SelectButton>
									</div>
								</div>

								{form.values.document_type !== undefined && (
									<>
										{/** Document File Front */}
										<FormikLabel htmlFor="document">
											{t.investor(
												"investor.identity.form.field.photo-front-side.label",
											)}
											<LabelSubtitle>
												{t.investor(
													"investor.identity.form.field.photo.sublabel",
												)}
											</LabelSubtitle>
										</FormikLabel>
										<input
											type="file"
											name="document"
											onChange={(e) => {
												form.setFieldValue(
													"document",
													e.target.files?.[0],
												)
											}}
										/>
										<FormikError
											field="document"
											namespace="investor"
										/>

										{/** Document File Back */}
										{form.values.document_type !==
											DocumentTypeEnum.passport && (
											<>
												<FormikLabel htmlFor="document_back">
													{t.investor(
														"investor.identity.form.field.photo-back-side.label",
													)}
													<LabelSubtitle>
														{t.investor(
															"investor.identity.form.field.photo.sublabel",
														)}
													</LabelSubtitle>
												</FormikLabel>
												<input
													type="file"
													name="document_back"
													onChange={(event) => {
														form.setFieldValue(
															"document_back",
															event.target
																.files?.[0],
														)
													}}
												/>
												<FormikError
													field="document_back"
													namespace="investor"
												/>
											</>
										)}

										{/** First name */}
										<div>
											<FormikLabel htmlFor="full_first_name">
												{t.investor(
													"investor.identity.form.field.first-name.label",
												)}
												<LabelSubtitle>
													{t.investor(
														"investor.identity.form.field.first-name.sublabel",
													)}
												</LabelSubtitle>
											</FormikLabel>
											<FormikInput
												name="full_first_name"
												className="block w-full"
												classNameWrapper="lg:w-1/2"
												aria-label={t.investor(
													"investor.identity.form.field.first-name.label",
												)}
											/>
											<FormikError
												field="full_first_name"
												namespace="investor"
											/>
										</div>

										{/** Expiry date */}
										<div>
											<FormikLabel htmlFor="expiry_date">
												{t.investor(
													"investor.identity.form.field.expiry-date.label",
												)}
												<LabelSubtitle>
													{t.investor(
														"investor.identity.form.field.expiry-date.sublabel",
													)}
												</LabelSubtitle>
											</FormikLabel>
											<FormikInput
												name="expiry_date"
												placeholder={t.investor(
													"investor.identity.form.field.expiry-date.placeholder",
												)}
												className="block w-full"
												classNameWrapper="lg:w-1/2"
												aria-label={t.investor(
													"investor.identity.form.field.expiry-date.label",
												)}
											/>
											<FormikError
												field="expiry_date"
												namespace="investor"
											/>
										</div>
									</>
								)}

								<div>
									<FormikErrors i18nNamespace="investor" />
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton
							disabled={
								data?.me?.kyc?.state !==
								RiskKycStateChoices.VerificationRequested
							}
						>
							{t.investor("investor.identity.form.button.submit")}
						</FormikSubmitButton>
					</CardFooter>
				</form>
			</CardWrapper>
		</FormikProvider>
	)
}

/**
 * StepCompletedOverlay
 * @param param0
 * @returns
 */
const StepCompletedOverlay = ({
	state,
	message,
	last_verified_on,
}: {
	state: string | undefined
	message?: string | null
	last_verified_on?: string
}) => {
	const t = { investor: useTrans("investor") }

	return (
		<div
			className="absolute inset-0 z-10 bg-gray-600 bg-opacity-50 p-5 text-center text-lg"
			data-testid="StepCompletedOverlay"
		>
			<Card className="mx-auto max-w-md">
				<div className="space-y-4 text-center">
					{/** State */}
					<Heading
						as="h2"
						styleAs="h5"
						className="flex justify-center gap-2"
					>
						<div className="flex items-center">
							<CheckIcon
								aria-hidden="true"
								className="h-5 w-5 text-lg text-green-500"
							/>
						</div>
						{t.investor(`investor.identity.status.${state}`)}
					</Heading>

					{/** Show human readable message */}
					{message && <p className="text-gray-500">{message}</p>}

					{/** When succesfully verified */}
					{RiskKycStateChoices.VerificationSuccess &&
						last_verified_on && (
							<>
								{DateTime.fromISO(last_verified_on).toFormat(
									dateFormat,
								)}
							</>
						)}
				</div>
			</Card>
		</div>
	)
}
