// React
import {
	createContext,
	Suspense,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react"

// Animations
import { motion } from "@/lib/animations"

import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { generateSendEventDebounced, sendEvent } from "@/lib/analytics"
import { classNames } from "@/lib/classnames"

// UI
import { ProjectCard, ProjectCardPreloader } from "@/components/ProjectCardNext"
import { Heading } from "@/components/Typography"
import {
	PaginationAsButtons,
	PaginationAsButtonsLoadingState,
} from "@/components/PaginationAsButtons"
import { Button } from "@/components/Button"
import { Card } from "@/components/Card"
import { SearchInput } from "@/components/form-controls/Input"
import { Select } from "@/components/form-controls"

// Icons
import { FiArrowDown, FiChevronDown } from "react-icons/fi"

// Constants
import { projectPageSizes } from "@/misc/constants"

// GraphQL
import {
	ProjectStateEnum,
	useInvestmentsComponentQuery,
	ProjectTypeEnum,
} from "@/api/graphql"

// Types
import {
	ALL_PROJECT_STATES,
	ALL_PROJECT_TYPES,
	ProjectStateEnumExtended,
	ProjectTypeEnumExtended,
} from "@/types"

// State
import { useSelector, useDispatch } from "@/state/StateProvider"
import {
	InvestmentsOverviewState,
	setPerPage,
} from "@/state/features/investmentsOverviewSlice"

// Translations
import { useTrans } from "@/i18n"

interface InvestmentsProps {
	className?: string
}

const onSearchSendEvent = generateSendEventDebounced(1000)

const InvestmentsContext = createContext<{
	search: string
	setSearch: (search: string) => void
	statusFilter: ProjectStateEnumExtended
	setStatusFilter: (statusFilter: ProjectStateEnumExtended) => void
	typeFilter: ProjectTypeEnumExtended
	setTypeFilter: (typeFilter: ProjectTypeEnumExtended) => void
	sort: "asc" | "desc" | null
	setSort: (sort: "asc" | "desc" | null) => void
	limit: number
}>(null!)

export const Investments = ({ className = "" }: InvestmentsProps) => {
	const t = {
		investments: useTrans("investments"),
		common: useTrans("common"),
	}

	// State
	const [search, setSearch] = useState("")
	const [statusFilter, setStatusFilter] =
		useState<ProjectStateEnumExtended>(ALL_PROJECT_STATES)
	const [typeFilter, setTypeFilter] =
		useState<ProjectTypeEnumExtended>(ALL_PROJECT_TYPES)
	const [sort, setSort] = useState<"asc" | "desc" | null>("asc")
	const dispatch = useDispatch()
	const limit = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview.perPage,
	)

	return (
		<InvestmentsContext.Provider
			value={{
				search,
				setSearch,
				statusFilter,
				setStatusFilter,
				typeFilter,
				setTypeFilter,
				sort,
				setSort,
				limit,
			}}
		>
			<div
				className={`${className} flex flex-1 flex-col space-y-4 sm:flex-row sm:space-y-0`}
				data-testid="myinvestments"
			>
				{/** Search bar */}
				<div className="sm:order-3 sm:ml-auto">
					<SearchInput
						onChange={(evt) => {
							onSearchSendEvent("investments", "on_search", {
								label: evt.currentTarget.value,
							})
							setSearch(evt.currentTarget.value)
						}}
						label={t.investments("investments.search.placeholder")}
						className="md:width-auto min-w-full"
					/>
				</div>
				<div className="order-1 flex-row space-y-4 sm:flex md:space-x-4 md:space-y-0">
					{/** Sort and filter */}
					<div className="flex space-x-4">
						{/** Sort A-Z */}
						<Button
							size="small"
							variant="transparent"
							onClick={() => {
								const next = sort === "asc" ? "desc" : "asc"
								sendEvent("investments", "on_sort", {
									label: next,
								})
								setSort(next)
							}}
						>
							{t.investments("investments.sort_by_name")}
							<FiArrowDown
								className={classNames(
									"ml-2",
									sort === "asc" && "rotate-180 transform",
								)}
							/>
						</Button>

						{/** Filter on project type */}
						<div className="relative">
							<label htmlFor="projectType">
								<Button size="small" variant="transparent">
									{typeFilter.toUpperCase() ===
									ALL_PROJECT_TYPES
										? t.investments(
												"investments.filter.types",
										  )
										: t.common(
												`common.project.type.${typeFilter}`,
										  )}
									<FiChevronDown className={"ml-2"} />
									<Select
										name="projectType"
										className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
										onChange={(evt) => {
											sendEvent(
												"investments",
												"on_type_filter",
												{
													label: evt.currentTarget
														.value,
												},
											)
											setTypeFilter(
												evt.currentTarget.value.toUpperCase() as ProjectTypeEnumExtended,
											)
										}}
										value={typeFilter ?? undefined}
									>
										<option value={ALL_PROJECT_TYPES}>
											{t.investments(
												"investments.filter.all",
											)}
										</option>
										{Object.entries(ProjectTypeEnum).map(
											([key, value]) => (
												<option
													value={value}
													key={value}
												>
													{t.common(
														`common.project.type.${value}`,
													)}
												</option>
											),
										)}
									</Select>
								</Button>
							</label>
						</div>

						{/** Filter on project status */}
						<div className="relative">
							<label htmlFor="projectStatus">
								<Button size="small" variant="transparent">
									{statusFilter.toUpperCase() ===
									ALL_PROJECT_STATES
										? t.investments(
												"investments.filter.status",
										  )
										: t.common(
												`common.project.status.${statusFilter}`,
										  )}
									<FiChevronDown className={"ml-2"} />
									<Select
										name="projectStatus"
										className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
										onChange={(evt) => {
											sendEvent(
												"investments",
												"on_status_filter",
												{
													label: evt.currentTarget
														.value,
												},
											)
											setStatusFilter(
												evt.currentTarget.value.toUpperCase() as ProjectStateEnumExtended,
											)
										}}
										value={statusFilter ?? undefined}
									>
										<option value={ALL_PROJECT_STATES}>
											{t.investments(
												"investments.filter.all",
											)}
										</option>
										{Object.entries(ProjectStateEnum).map(
											([key, value]) => (
												<option
													value={value}
													key={value}
												>
													{t.common(
														`common.project.status.${value}`,
													)}
												</option>
											),
										)}
									</Select>
								</Button>
							</label>
						</div>
					</div>

					{/** Set page size */}
					<div className="relative">
						<label htmlFor="pageSize w-full">
							<Button
								size="small"
								variant="transparent"
								className="w-full"
							>
								{t.investments(
									"investments.pagination.set_page_size",
									{
										count: limit,
									},
								)}
								<FiChevronDown className={"ml-2"} />
								<Select
									name="pageSize"
									className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
									onChange={(evt) =>
										dispatch(
											setPerPage(
												Number(evt.currentTarget.value),
											),
										)
									}
									value={limit ?? undefined}
								>
									{projectPageSizes.map((item) => (
										<option value={item} key={item}>
											{item}
										</option>
									))}
								</Select>
							</Button>
						</label>
					</div>
				</div>
			</div>
			<ErrorBoundaryWithErrorState errorBoundaryClassName="mt-5">
				<Suspense fallback={<LoadingState />}>
					<InvestmentsGrid />
				</Suspense>
			</ErrorBoundaryWithErrorState>
		</InvestmentsContext.Provider>
	)
}

function InvestmentsGrid() {
	const {
		search,
		setSearch,
		setStatusFilter,
		statusFilter,
		typeFilter,
		setTypeFilter,
		setSort,
		sort,
		limit,
	} = useContext(InvestmentsContext)
	const [currentPage, setCurrentPage] = useState(0)

	const statusFilterMemoized = useMemo<ProjectStateEnum | undefined>(() => {
		if (statusFilter && statusFilter !== ALL_PROJECT_STATES) {
			return statusFilter as ProjectStateEnum
		}
	}, [statusFilter])
	const typeFilterMemoized = useMemo<ProjectTypeEnum | undefined>(() => {
		if (typeFilter && typeFilter !== ALL_PROJECT_TYPES) {
			return typeFilter as ProjectTypeEnum
		}
	}, [typeFilter])

	const options = {
		limit,
		offset: currentPage * limit,
		name: search,
		ordering: sort ? (sort === "asc" ? "name" : "-name") : undefined,
		state: statusFilterMemoized,
		type: typeFilterMemoized,
	}

	const { data, isPreviousData } = useInvestmentsComponentQuery(options)

	// Projects
	const projects = useMemo(
		() => data?.me?.investment_projects?.results ?? [],
		[data?.me?.investment_projects?.results],
	)

	const onReset = useCallback(() => {
		setSearch("")
		setStatusFilter(ALL_PROJECT_STATES)
		setTypeFilter(ALL_PROJECT_TYPES)
		setSort("asc")
		setCurrentPage(0)
	}, [setSearch, setStatusFilter, setSort])

	return (
		<>
			<dl
				className={classNames(
					"mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3",
					isPreviousData && "opacity-50",
				)}
				data-testid="grid"
			>
				{projects.length === 0 && (
					<div className="sm:col-span-2 lg:col-span-1 lg:col-start-2">
						<NoResults onReset={onReset} />
					</div>
				)}
				{projects.map((project) => (
					<div key={`${project?.id}`}>
						<ProjectCard
							analyticsContext="investments"
							className="h-full"
							key={project?.id}
							totalRepayment={
								project?.investor_shares_value_stats
									?.total_repaid_for_project
									? parseFloat(
											project.investor_shares_value_stats
												.total_repaid_for_project,
									  )
									: 0
							}
							hasEnergySupplierMessage={false}
							projectName={project?.name ?? ""}
							amountInvested={
								project?.investor_shares_value_stats
									?.total_investment_for_project
									? parseFloat(
											project.investor_shares_value_stats
												.total_investment_for_project,
									  )
									: 0
							}
							energySupplier={project?.installer?.name ?? ""}
							comingInterestPeriod={
								project?.current_interest_period?.end
									? new Date(
											project.current_interest_period.end,
									  )
									: null
							}
							slug={project?.id ?? ""}
							energySavings={
								project?.investor_production_stats
									?.investor_generated_power_in_kwh
									? parseFloat(
											project.investor_production_stats
												.investor_generated_power_in_kwh,
									  )
									: 0
							}
							status={project?.state}
							type={project?.type}
							image={project?.image_url ?? ""}
						/>
					</div>
				))}
			</dl>
			<div className="mt-6 flex justify-center md:mt-8">
				<PaginationAsButtons
					countPerPage={limit}
					totalCount={data?.me?.investment_projects?.totalCount ?? 0}
					itemType={"common.pagination.item_types.project"}
					currentPage={currentPage + 1}
					currentItemsAmount={projects?.length ?? 0}
					onNextPage={() =>
						setCurrentPage((currentPage) => currentPage + 1)
					}
					onPrevPage={() =>
						setCurrentPage((currentPage) =>
							Math.max(currentPage - 1, 0),
						)
					}
					analyticsId="investments"
				/>
			</div>
		</>
	)
}

function LoadingState() {
	return (
		<>
			<dl className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
				{Array(6)
					.fill(true)
					.map((_, index) => (
						<div key={index} data-testid="spinner">
							<ProjectCardPreloader />
						</div>
					))}
			</dl>
			<div className="mt-6 flex justify-center md:mt-8 md:gap-x-4">
				<PaginationAsButtonsLoadingState />
			</div>
		</>
	)
}

const NoResults = ({ onReset }: { onReset: () => void }) => {
	const t = { investments: useTrans("investments") }

	return (
		<motion.div
			data-testid="investment-item-noresults"
			layoutId="no-results"
			exit={{
				scale: 0.95,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
			}}
			initial={{
				scale: 0.95,
				opacity: 0,
			}}
		>
			<Card>
				<div className="space-y-4 text-center">
					<Heading as="h2" styleAs="h5">
						{t.investments("investments.no_results.title")}
					</Heading>
					<p className="text-gray-500">
						{t.investments("investments.no_results.copy")}
					</p>
					<Button onClick={onReset}>
						{t.investments("investments.no_results.button")}
					</Button>
				</div>
			</Card>
		</motion.div>
	)
}
