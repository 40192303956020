import debounce from "lodash.debounce"

export const generateSendEventDebounced = (limit = 1000) => {
	return debounce(sendEvent, limit)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendEvent = (category: string, action: string, args?: any) => {
	if (typeof window.gtag !== "undefined") {
		window.gtag("event", category, {
			action,
			...args,
		})
	}
}
