import { Helmet } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"

// UI
import { classNames } from "@/lib/classnames"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Context
import { useFeatureFlags, useCurrentUserIdentityType } from "@/context/user"

// Error handling
import { ErrorBoundary } from "@/components/errors/ErrorBoundary"
import { ErrorState } from "@/components/errors/ErrorState"

// Translations
import { useTrans } from "@/i18n"

// Pages
import { Pages } from "@/misc/pages"
import { ProfileChangePassword } from "./pages/ChangePassword"
import { ProfileNotifications } from "./pages/ProfileNotifications"
import { ProfileSecurity } from "./pages/ProfileSecurity"
import { ProfileCompanyDetails } from "./pages/ProfileCompanyDetails"
import { BankDetails } from "@/components/forms/BankDetails"
import { PersonalDetails } from "@/components/forms/PersonalDetails"
import { NotFound } from "@/pages/NotFound"

// GraphQL
import { UsersUserProfileIdentityTypeChoices } from "@/api/graphql"

// Environment variables
const API_URL = process.env.REACT_APP_API_URL

export function Profile() {
	const t = useTrans("profile")
	const { getFeatureFlagValue } = useFeatureFlags()
	const identity_type: UsersUserProfileIdentityTypeChoices =
		useCurrentUserIdentityType()

	return (
		<>
			<Helmet>
				<title>{t("profile.my_profile.title")}</title>
			</Helmet>
			<PageTemplate
				title={t("profile.header")}
				tabs={
					<>
						<PageTemplateLink
							to={Pages.SettingsProfilePersonalDetails}
							data-testid="profile.personal_details"
							name={t("profile.personal_details.title")}
							analyticsId="profile.personal_details"
						/>

						{/** Show tab only if user has organisation account */}
						{identity_type ===
							UsersUserProfileIdentityTypeChoices.Business && (
							<PageTemplateLink
								to={Pages.SettingsProfileCompanyDetails}
								data-testid="profile.company_details"
								name={t("profile.company_details.title")}
								analyticsId="profile.company_details"
							/>
						)}
						<PageTemplateLink
							to={Pages.SettingsProfileBankDetails}
							data-testid="profile.bank_details"
							name={t("profile.bank_details.title")}
							analyticsId="profile.bank_details"
						/>
						<PageTemplateLink
							to={Pages.SettingsProfileChangePassword}
							data-testid="profile.change_password"
							name={t("profile.change_password.title")}
							analyticsId="profile.change_password"
						/>
						<a
							href={`${API_URL}account/mfa`}
							className={classNames(
								"text-gray-500 hover:text-gray-700",
								"border-b border-gray-300",
								"group px-4",
								"whitespace-nowrap text-sm font-medium",
							)}
						>
							<span
								className={classNames(
									"flex h-full translate-y-px transform gap-1 truncate border-b-2 py-3 lg:py-4",
									"border-transparent group-hover:border-b-2 group-hover:border-gray-300",
								)}
							>
								{t("profile.security.title")}
							</span>
						</a>
						<PageTemplateLink
							to={Pages.SettingsProfileNotifications}
							data-testid="profile.notifications"
							name={t("profile.notifications.title")}
							analyticsId="profile.notifications"
						/>

						{getFeatureFlagValue("ENABLE_MFA_SECURITY_PAGE") ===
							true && (
							<PageTemplateLink
								to={Pages.SettingsProfileSecurity}
								data-testid="profile.security"
								name={t("profile.security.title")}
								analyticsId="profile.security"
								end={false}
							/>
						)}
					</>
				}
			>
				<Routes>
					<Route
						path="change-password"
						element={<ProfileChangePassword />}
					/>
					<Route
						path="notifications"
						element={<ProfileNotifications />}
					/>
					<Route
						path="company-details"
						element={<ProfileCompanyDetails />}
					/>
					<Route
						path="bank-details"
						element={<ProfileBankDetails />}
					/>
					<Route
						path="security/*"
						element={<ProfileSecurityComponent />}
					/>
					<Route path="/" element={<ProfilePersonalData />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</PageTemplate>
		</>
	)
}

export const ProfilePersonalData = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile.personal_details.title")}</title>
			</Helmet>
			<div>
				<ErrorBoundary fallback={<ErrorState />}>
					<PersonalDetails />
				</ErrorBoundary>
			</div>
		</>
	)
}

export const ProfileBankDetails = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile.bank_details.title")}</title>
			</Helmet>
			<div>
				<ErrorBoundary fallback={<ErrorState />}>
					<BankDetails />
				</ErrorBoundary>
			</div>
		</>
	)
}

export const ProfileSecurityComponent = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile.security.title")}</title>
			</Helmet>
			<ErrorBoundary fallback={<ErrorState />}>
				<ProfileSecurity />
			</ErrorBoundary>
		</>
	)
}
