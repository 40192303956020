// D3 graphs
import { bisector, extent, max } from "d3-array"
import { timeFormat, timeFormatDefaultLocale } from "d3-time-format"

// Initialize
export const setLang = (lang: string) => {
	// Set locale
	let locale
	switch (lang) {
		case "nl":
			locale = require("../i18n/locales/nl/d3-time-format.json")
			break
		default:
			locale = require("../i18n/locales/en/d3-time-format.json")
	}

	// Set default time format locale
	timeFormatDefaultLocale(locale)
}

// Time formats commonly used troughout the app
export const formatYear = timeFormat("%Y")
export const formatMonth = timeFormat("%b %y")
export const formatDay = timeFormat("%d")

// Export
export { bisector, extent, max, timeFormat }
