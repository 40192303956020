import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface InvestmentsOverviewState {
	perPage: number
}

export const initialState: InvestmentsOverviewState = {
	perPage: 6,
}

const investmentsOverviewSlice = createSlice({
	name: "investments/overview",
	initialState,
	reducers: {
		setPerPage: (state, action: PayloadAction<number>) => {
			state.perPage = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const { setPerPage } = investmentsOverviewSlice.actions

export default investmentsOverviewSlice.reducer
