import { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// UI
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Label } from "@/components/form-controls/Label"
import { useToasts } from "@/context/toasts"
import { TableWarning } from "@/components/table-controls/TableWarning"

// Graphql
import {
	CoreInvestmentTestCategoryChoices,
	useInvestorTestMutationsInputMutation,
	InvestmentTestType,
} from "@/api/graphql"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Forms
import {
	FormikSubmitButton,
	FormikInput,
	FormikCheckbox,
} from "@/components/form-controls/formik"
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"

// Icons
import { CheckIcon } from "@heroicons/react/outline"

// Context
import { useInvestmentTests } from "@/context/investmentTests"
import { useHiddenGetParamsContext } from "@/context/hiddenGetParams"
import { useCurrentUserId } from "@/context/user"

// Environment variables
const API_URL = process.env.REACT_APP_API_URL

/**
 * Calculate total net worth
 * @param totalNormalIncome
 * @param totalExtraIncome
 * @param totalLiquidAssets
 * @param totalYearlyExpenses
 * @returns
 */
function calculateTotalNetWorth(
	totalNormalIncome: number,
	totalExtraIncome: number,
	totalLiquidAssets: number,
	totalYearlyExpenses: number,
) {
	return (
		Number(totalNormalIncome) +
		Number(totalExtraIncome) +
		Number(totalLiquidAssets) -
		Number(totalYearlyExpenses)
	)
}

/**
 * Calculate total capacity for loss
 * @param totalNormalIncome
 * @param totalExtraIncome
 * @param totalLiquidAssets
 * @param totalYearlyExpenses
 */
function calculateTotalCapacityForLoss(totalNetWorth: number) {
	return (Number(totalNetWorth) / 100) * 10
}

/**
 * Converts string values to number with two decimals
 *
 * TODO: Make this work for large numbers like 10.000 as well!
 * @param amount
 * @returns
 */
function convertMoneyStringToNumberFormat(amount: string) {
	const stringWithDotsInsteadOfComma = amount.replace(",", ".")
	const parsedValue = parseFloat(stringWithDotsInsteadOfComma)
	const roundedValue = Number(parsedValue.toFixed(2))
	return isNaN(roundedValue) ? "" : roundedValue
}

/**
 * Risk Calculator
 *
 * TODO: Unit test these calculations and make sure they dont give any weird negative values!
 * @returns
 */
export const InvestorRisk = () => {
	// State
	const [showLanguageDisclaimer, setShowLanguageDisclaimer] =
		useState<boolean>(false)

	// Context
	const { investorTestRisk, refetch } = useInvestmentTests()
	const { next } = useHiddenGetParamsContext()
	const { id: userId } = useCurrentUserId()
	const { investorTestQuestions } = useInvestmentTests()

	// Translations
	const t = { investor: useTrans("investor") }
	const { lang, setLang, formatCurrency } = useLang()

	// Hack to always reset to lang=NL on this page because we don't have the legal english texts yet
	useEffect(() => {
		if (lang !== "nl") {
			setShowLanguageDisclaimer(true)
			setLang("nl")
		}
	}, [lang, setLang])

	// Internal state
	const [totalNetWorth, setTotalNetWorth] = useState<number>()
	const [totalCapacityForLoss, setTotalCapacityForLoss] = useState<number>()

	// Router
	const toasts = useToasts()

	// Mutation
	const createNewTestSubmission = useInvestorTestMutationsInputMutation({
		onSuccess: async (response) => {
			// do we have data? then success
			if (response?.investment_test_update?.investor_test?.id) {
				toasts.addToast({
					variant: "success",
					id: `notifications-success-${Date.now()}`,
					text: t.investor("Bedankt voor het invullen van de test"),
				})
				refetch()

				// When 'next' param is set in context, redirect there in the API
				if (next && Boolean(investorTestQuestions)) {
					window.location.href = `${API_URL}${next}`
				}
			}
		},
	})

	// Forms
	const formCalculation = useFormik({
		initialValues: {
			totalNormalIncome: 0,
			totalExtraIncome: 0,
			totalLiquidAssets: 0,
			totalYearlyExpenses: 0,
		},
		validationSchema: Yup.object().shape({
			totalNormalIncome: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
			totalExtraIncome: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
			totalLiquidAssets: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
			totalYearlyExpenses: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
		}),
		onSubmit: async (values) => {
			const totalNetWorth = calculateTotalNetWorth(
				values.totalNormalIncome,
				values.totalExtraIncome,
				values.totalLiquidAssets,
				values.totalYearlyExpenses,
			)
			setTotalNetWorth(totalNetWorth)
			setTotalCapacityForLoss(
				calculateTotalCapacityForLoss(totalNetWorth),
			)
		},
	})
	const formAcceptTerms = useFormik({
		initialValues: {
			acceptedTerms: false,
		},
		validationSchema: Yup.object().shape({
			acceptedTerms: Yup.bool().oneOf([true]),
		}),
		onSubmit: async () => {
			await createNewTestSubmission.mutateAsync({
				input: {
					investor: userId,
					category: CoreInvestmentTestCategoryChoices.LossCalculator,
				},
			})
		},
	})

	const handleFormFieldBlur = (
		event: React.ChangeEvent<HTMLInputElement>,
		setFieldValue: Function,
	) => {
		const { value, name } = event.target
		setFieldValue(name, convertMoneyStringToNumberFormat(value))
	}

	return (
		<>
			<Helmet>
				<title>{t.investor("investor.risk.title")}</title>
			</Helmet>
			<CardWrapper className="relative">
				{Boolean(investorTestRisk) === true && (
					<StepCompletedOverlay
						investorTestRisk={
							investorTestRisk as InvestmentTestType
						}
					/>
				)}

				<CardBody>
					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t.investor("investor.risk.heading")}
					</Heading>

					{showLanguageDisclaimer === true && (
						<TableWarning
							className="mb-3"
							title={t.investor(
								"investor.generic.legal-disclaimer.dutch.title",
							)}
							message={t.investor(
								"investor.generic.legal-disclaimer-dutch.message",
							)}
						/>
					)}

					<p>{t.investor("investor.risk.privacy")}</p>

					<div className="flex flex-col gap-4 md:gap-8 lg:flex-row">
						<FormikProvider value={formCalculation}>
							<form
								className="w-full lg:w-1/2"
								onSubmit={formCalculation.handleSubmit}
							>
								<div className="mt-4">
									<Label>
										{t.investor(
											"investor.risk.form.fields.regular-income.title",
										)}
									</Label>
									<p className="mb-1">
										{t.investor(
											"investor.risk.form.fields.regular-income.subtitle",
										)}
									</p>

									<FormikInput
										name="totalNormalIncome"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>
								<div className="mt-4">
									<Label>
										{t.investor(
											"investor.risk.form.fields.extra-income.title",
										)}
									</Label>
									<p className="mb-1">
										{t.investor(
											"investor.risk.form.fields.extra-income.subtitle",
										)}
									</p>

									<FormikInput
										name="totalExtraIncome"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>
								<div className="mt-4">
									<Label>
										{t.investor(
											"investor.risk.form.fields.liquid-assets.title",
										)}
									</Label>
									<p className="mb-1">
										{t.investor(
											"investor.risk.form.fields.liquid-assets.subtitle",
										)}
									</p>

									<FormikInput
										name="totalLiquidAssets"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>
								<div className="mt-4">
									<Label>
										{t.investor(
											"investor.risk.form.fields.expenses.title",
										)}
									</Label>
									<p className="mb-1">
										{t.investor(
											"investor.risk.form.fields.expenses.subtitle",
										)}
									</p>

									<FormikInput
										name="totalYearlyExpenses"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>

								<FormikSubmitButton className="mt-4">
									{t.investor(
										"investor.risk.form.buttons.calculate",
									)}
								</FormikSubmitButton>
							</form>
						</FormikProvider>
						<div className="flex w-full lg:w-1/2">
							{totalCapacityForLoss && (
								<div className="self-end">
									<p className="w-456 rounded-md border border-gray-300 bg-gray-100 p-4">
										<strong className="font-bold">
											{t.investor(
												"investor.risk.result.advice-amount",
											)}
										</strong>
										<br />
										{t.investor(
											"investor.risk.result.advice-text",
											{
												totalNetWorth: formatCurrency(
													totalNetWorth ?? 0,
												),
												totalCapacityForLoss:
													formatCurrency(
														totalCapacityForLoss ??
															0,
													),
											},
										)}
									</p>
									<FormikProvider value={formAcceptTerms}>
										<form
											onSubmit={
												formAcceptTerms.handleSubmit
											}
										>
											<div className="mt-4">
												<FormikCheckbox
													name="acceptedTerms"
													className="mr-2"
												/>
												<label htmlFor="acceptedTerms">
													{t.investor(
														"investor.risk.result.agree",
													)}
												</label>
											</div>

											<FormikSubmitButton className="mt-4">
												{t.investor(
													"investor.risk.result.save",
												)}
											</FormikSubmitButton>
										</form>
									</FormikProvider>
								</div>
							)}
						</div>
					</div>

					{/** Continue investment flow */}
					{next && (
						<TableWarning
							className={"mt-4"}
							message="Je investeerdersprofiel is bijna compleet. Nadat de stappen zijn afgerond kun je doorgaan met je investering."
							title={""}
						/>
					)}
				</CardBody>
			</CardWrapper>
		</>
	)
}

const StepCompletedOverlay = ({
	investorTestRisk,
}: {
	investorTestRisk: InvestmentTestType
}) => {
	return (
		<div className="absolute inset-0 z-10 bg-gray-600 bg-opacity-50 p-5 text-center text-lg">
			<Card className="mx-auto max-w-md">
				<div className="space-y-4 text-center">
					<Heading
						as="h2"
						styleAs="h5"
						className="flex justify-center gap-2"
					>
						<div className="flex items-center">
							<CheckIcon
								aria-hidden="true"
								className="h-5 w-5 text-lg text-green-500"
							/>
						</div>
						Voltooid op{" "}
						{DateTime.fromISO(
							investorTestRisk?.created_at,
						).toFormat(dateFormat)}
					</Heading>
				</div>
			</Card>
		</div>
	)
}
