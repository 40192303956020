import i18n from "i18next"
import backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
import { loadLocales } from "./utils"

// State
import { store } from "@/state/store"
const DETECTED_LANGUAGE = store.getState().userPreferences.lang

const ajax = async (url: string, _: any, callback: any) => {
	try {
		const [lang, namespace] = url.split("/")
		const data = await loadLocales(lang, namespace)
		callback(data, { status: "200" })
	} catch (e) {
		console.error("Failed to load locale", e)
		callback(null, { status: "404" })
	}
}

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// load translation using
	// learn more: https://github.com/i18next/i18next-xhr-backend
	// @TODO i18next-xhr-backend is now deprecated. i18next-http-backend https://github.com/i18next/i18next-http-backend
	.use(backend)
	.init({
		debug: process.env.REACT_APP_DEBUG_I18N === "true",
		ns: ["common"],
		fallbackLng: DETECTED_LANGUAGE,
		defaultNS: "common",
		keySeparator: false,
		// we currently treat first part of key as a namespace, e.g. "namespace.this.is_a_key". Can be refactored with ":" + "."
		nsSeparator: false,
		lng: DETECTED_LANGUAGE,
		interpolation: {
			// react is already safe from xss
			escapeValue: false,
		},
		backend: {
			loadPath: `{{lng}}/{{ns}}`,
			parse: (data: any) => data,
			ajax,
			crossDomain: true,
			withCredentials: false,
		},
		saveMissing: true,
		missingKeyHandler: (
			ngs,
			ns,
			key,
			fallbackValue,
			updateMissing,
			options,
		) => {
			/* TODO: Re-enable this on production whenever it doesnt cause spam anymore
				
			captureException(new Error("Missing i18n key"), {
				extra: {
					ngs,
					ns,
					key,
					fallbackValue,
					updateMissing,
					options,
				},
			}) */
			console.log("Missing i18n key", {
				ngs,
				ns,
				key,
				fallbackValue,
				updateMissing,
				options,
			})
		},
	})

export { i18n }
