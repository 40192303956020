import { Helmet } from "react-helmet-async"
import { Link, useLocation } from "react-router-dom"
import IconZPD from "@/assets/icons/zpd/logo.svg"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { useTrans } from "@/i18n"
import { SingleScreenLayout } from "@/layouts/SingleScreenLayout"

export const Confirm = () => {
	const t = useTrans("confirm")
	const location = useLocation()
	const state = location.state as { email?: string }

	return (
		<>
			<Helmet>
				<title>{t("confirm.title")}</title>
			</Helmet>
			<SingleScreenLayout
				content={
					<>
						<div className="text-center">
							<picture>
								<img
									src={IconZPD}
									className="mx-auto h-12"
									alt=""
								/>
							</picture>
							<Heading styleAs="h3" className="mt-6">
								{t("confirm.subtitle")}
							</Heading>
						</div>
						<Card className="mt-6 text-sm text-gray-800">
							{state?.email ? (
								<p>
									{t("confirm.text_with_email", {
										email: state.email,
									})}
								</p>
							) : (
								<p>{t("confirm.text_without_email")}</p>
							)}
							<p className="mt-4">{t("confirm.post_text")}</p>
						</Card>
						<Card className="mt-6 text-sm" variant="gray">
							<Link
								to="/"
								className="font-medium text-secondary-300 hover:text-secondary-700"
								data-testid="confirm.back.text"
							>
								{t("confirm.back.text")}
							</Link>
						</Card>
					</>
				}
			/>
		</>
	)
}
