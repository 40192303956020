import { useContext } from "react"

// Navigation
import { matchPath, useLocation } from "react-router-dom"
import { Pages } from "@/misc/pages"

// Context
import { NextLayoutContext } from "@/layouts/NextLayout"

// UI
import { classNames } from "@/lib/classnames"
import { Link } from "@/components/Anchor"

/**
 * RevenueSubMenu
 * @returns
 */
export function RevenueSubMenu() {
	const { setMobileMenuOpen } = useContext(NextLayoutContext)
	const location = useLocation()

	/**
	 * The Pages.InvestmentsProjectsDashboard page is a special case because it has nested routes in the url
	 * that need to be rendered on the same level and not both be marked as active
	 *
	 * @returns
	 */
	function isProjectSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Pages.InvestmentsProjectsDashboard) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investments/projects/:id/:tab",
				end: true,
			},
			location.pathname,
		)

		// Now exclude the FiscalOverview and Production pages from being marked as active
		return (
			matchesPath !== null &&
			!location.pathname.includes(
				Pages.InvestmentsProjectsFiscalOverview,
			) &&
			!location.pathname.includes(Pages.InvestmentsProjectsProduction)
		)
	}

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			<Link
				className={classNames(
					isProjectSpecificSubPath() && "active-navigation-item",
					"block truncate text-sm hover:text-gray-500",
				)}
				href={Pages.InvestmentsDashboard}
				onClick={closeMenu}
			>
				{"Statistics"}
			</Link>
		</nav>
	)
}
