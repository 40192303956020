import { DateTime, Duration, DurationObjectUnits } from "luxon"

// Vars
export const DEFAULT_TIMEZONE = "Europe/Amsterdam"

// TODO: Clean up this file!
export const dateToLocaleString = (
	dateText: string,
	options: Intl.DateTimeFormatOptions = localeStringOptions,
) => DateTime.fromISO(dateText).toLocaleString(options)

export const localeStringOptionsWithYear: Intl.DateTimeFormatOptions = {
	day: "numeric",
	month: "short",
	weekday: "short",
	year: "numeric",
}

export const localeStringOptions: Intl.DateTimeFormatOptions = {
	day: "numeric",
	month: "short",
	weekday: "short",
}

export const today = DateTime.local()

// Transforms "EUROPE_AMSTERDAM" into "Europe/Amsterdam"
export function transformTimezoneConstantToJSTimezone(
	inputString: string | undefined,
): string {
	if (inputString === undefined) {
		return DEFAULT_TIMEZONE
	} else {
		return inputString
			.split("_")
			.map(
				(word) =>
					word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
			)
			.join("/")
	}
}

// Export default from Luxon
export { DateTime, Duration }

// Types
export type { DurationObjectUnits }
