import { Helmet } from "react-helmet-async"
import { useTrans } from "@/i18n"

// UI
import { Label } from "@/components/form-controls"
import { Heading } from "@/components/Typography"
import { CardBody, CardFooter, CardWrapper } from "@/components/Card"

// Forms
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import {
	FormikSubmitButton,
	FormikCheckbox,
	FormikInput,
} from "@/components/form-controls/formik"

export const InvestorFunds = () => {
	const t = { investor: useTrans("investor") }

	// Form
	const form = useFormik({
		initialValues: {
			origin: {
				work: false,
				savings: false,
				inheritance: false,
				investments: false,
				"real-estate": false,
				business: false,
				family: false,
				misc: false,
			},
		},
		validationSchema: Yup.object().shape({
			origin: Yup.object().test(
				"at-least-one-true",
				"At least one child must be true",
				(origin) => {
					return Object.values(origin).some((value) => value === true)
				},
			),
		}),
		onSubmit: async (values) => {
			console.log(values)
		},
	})

	return (
		<>
			<Helmet>
				<title>{t.investor("investor.funds.title")}</title>
			</Helmet>

			<CardWrapper>
				<FormikProvider value={form}>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t.investor("investor.funds.form.origin.title")}
						</Heading>

						<div className="flex">
							<div className="w-full lg:w-2/3">
								<form onSubmit={form.handleSubmit}>
									<Label>
										<FormikCheckbox
											name={`origin.work`}
											checked={form.values.origin.work}
											onChange={(event) => {
												form.setFieldValue(
													"origin.work",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>

										{t.investor(
											"investor.funds.form.origin.field.work",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.savings`}
											checked={form.values.origin.savings}
											onChange={(event) => {
												form.setFieldValue(
													"origin.savings",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.savings",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.inheritance`}
											checked={
												form.values.origin.inheritance
											}
											onChange={(event) => {
												form.setFieldValue(
													"origin.inheritance",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.inheritance",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.investments`}
											checked={
												form.values.origin.investments
											}
											onChange={(event) => {
												form.setFieldValue(
													"origin.investments",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.investments",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.real-estate`}
											checked={
												form.values.origin[
													"real-estate"
												]
											}
											onChange={(event) => {
												form.setFieldValue(
													"origin.real-estate",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.real-estate",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.business`}
											checked={
												form.values.origin.business
											}
											onChange={(event) => {
												form.setFieldValue(
													"origin.business",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.business",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.family`}
											checked={form.values.origin.family}
											onChange={(event) => {
												form.setFieldValue(
													"origin.family",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.family",
										)}
									</Label>
									<Label>
										<FormikCheckbox
											name={`origin.misc`}
											checked={form.values.origin.misc}
											onChange={(event) => {
												form.setFieldValue(
													"origin.misc",
													event.target.checked,
												)
											}}
											className="order-0 mr-2"
										/>
										{t.investor(
											"investor.funds.form.origin.field.misc",
										)}

										{form.values.origin.misc === true && (
											<FormikInput
												name="misc-comment"
												className="mt-4"
												type="text"
												placeholder="namelijk.."
											/>
										)}
									</Label>
								</form>
							</div>
						</div>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton>
							{t.investor("investor.identity.form.button.submit")}
						</FormikSubmitButton>
					</CardFooter>
				</FormikProvider>
			</CardWrapper>
		</>
	)
}
