import { useContext } from "react"

// UI
import { Link } from "@/components/Anchor"

// Icons
import { IoLanguageSharp } from "react-icons/io5"
import { IdentificationIcon, LogoutIcon } from "@heroicons/react/outline"

// Constants
import { Pages } from "@/misc/pages"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"
import { configs, Languages } from "@/i18n/config"

// Misc
import { useToasts } from "@/context/toasts"
import { classNames } from "@/lib/classnames"

// Context
import { NextLayoutContext } from "@/layouts/NextLayout"
import { useAuth } from "@/context/auth"

/**
 * QuickMenu
 * @returns
 */
export function QuickMenu() {
	const t = useTrans()
	const { config, lang, setLang } = useLang()
	const toasts = useToasts()
	const auth = useAuth()
	const { setMobileMenuOpen } = useContext(NextLayoutContext)

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="px-1 pb-4">
			<Link
				href={Pages.SettingsProfile}
				className={classNames(
					"mt-1 text-gray-600 hover:bg-gray-50 hover:text-gray-900",
					"group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium",
				)}
				onClick={closeMenu}
			>
				<>
					<IdentificationIcon
						className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
						aria-hidden="true"
					/>
					{t("common.layout.quick_menu.settings")}
				</>
			</Link>
			<label
				className={classNames(
					"relative mt-1 text-gray-600 hover:bg-gray-50 hover:text-gray-900",
					"group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium",
				)}
			>
				<IoLanguageSharp
					className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
					aria-hidden="true"
				/>
				{config.display}
				<select
					className="absolute left-0 top-0 h-full w-full cursor-pointer p-0 opacity-0"
					onChange={(evt) =>
						setLang(evt.currentTarget.value as Languages)
					}
					value={lang}
					data-testid="language-selector"
				>
					{Object.values(configs).map((config) => (
						<option value={config.path} key={config.path}>
							{config.display}
						</option>
					))}
				</select>
			</label>
			<button
				onClick={async () => {
					try {
						auth.logOut()
					} catch (e) {
						console.error("Could not log out", e)
						toasts.addToast({
							id: "common.layout.quick_menu.sign_out",
							text: "Failed to log out, try again later.",
						})
					}
				}}
				className={classNames(
					"mt-1 text-gray-600 hover:bg-gray-50 hover:text-gray-900",
					"group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium",
				)}
			>
				<LogoutIcon
					className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
					aria-hidden="true"
				/>
				{t("common.layout.quick_menu.sign_out")}
			</button>
		</nav>
	)
}
