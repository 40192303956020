// React
import { useMemo } from "react"

// Not found template
import { NotFound } from "@/pages/NotFound"

// Translations
import { useLang } from "@/context/lang"
import { Trans, useTrans } from "@/i18n"
import { onlyCoreLocationCountryChoices } from "@/lib/countries"

// UI
import { useToasts } from "@/context/toasts"
import { Heading } from "../Typography"
import { CardBody, CardFooter, CardWrapper } from "../Card"
import Tippy from "@tippyjs/react"

// Icons
import { FiChevronDown, FiInfo } from "react-icons/fi"

// Forms
import { FormikProvider, useFormik } from "formik"
import { handleErrorWithFormik } from "@/lib/formik"
import { FormikError } from "../form-controls/Errors"
import { FormGroup } from "../form-controls/FormGroup"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
} from "../form-controls/formik"
import { Select, SelectButton } from "../form-controls"

// GraphQL
import {
	useCompanyDetailsQueryQuery,
	useCompanyDetailsMutationMutation,
	Country,
	CoreLocationCountryChoices,
} from "@/api/graphql"

/**
 * CompanyDetails
 * TODO: In theory users can have multiple organisations, but for now we only support one (hardcoded me.organisations[0])
 *
 * @returns
 */
export const CompanyDetails = () => {
	const toasts = useToasts()

	// Translations
	const t = useTrans("profile")
	const { getCountryName } = useLang()

	// Sorted countries list
	const sortedCountries = useMemo(() => {
		return onlyCoreLocationCountryChoices
			.map((country) => ({
				...country,
				localisedCountry: getCountryName(country.code) ?? country.name,
			}))
			.sort((a, b) =>
				a.localisedCountry.localeCompare(b.localisedCountry),
			)
	}, [getCountryName])

	// GraphQL
	const { data, refetch } = useCompanyDetailsQueryQuery()

	const updateCompanyDetails = useCompanyDetailsMutationMutation({
		onSuccess: async (response) => {
			// map each error from graphql to the field it's related to
			for (const error of response?.organisation_update?.errors ?? []) {
				if (error?.field) {
					form.setFieldError(error.field, error.messages[0])
				}
			}

			// do we have data? then success
			if (
				!response?.organisation_update?.errors ||
				response.organisation_update.errors?.length === 0
			) {
				toasts.addToast({
					variant: "success",
					id: `bank-details-success-${Date.now()}`,
					text: t(`profile.personal_details.success_message`),
				})
				await refetch()
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.attention_required",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	// Form
	const form = useFormik({
		initialValues: data ?? {},
		enableReinitialize: true,
		onSubmit: async (values) => {
			await updateCompanyDetails.mutateAsync({
				input: {
					id: values?.me?.organisations?.[0]?.id,
					location: {
						address_line_primary:
							values?.me?.organisations?.[0]?.location
								?.address_line_primary ?? "",
						address_line_secondary:
							values?.me?.organisations?.[0]?.location
								?.address_line_secondary ?? "",
						city:
							values?.me?.organisations?.[0]?.location?.city ??
							"",
						country:
							(values?.me?.organisations?.[0]?.location
								?.country as Country) ?? "",
						postal_code:
							values?.me?.organisations?.[0]?.location
								?.postal_code ?? "",
					},
				},
			})
		},
	})

	// Check if users has organisations
	if (data?.me?.organisations?.[0]) {
		return (
			<FormikProvider value={form}>
				<CardWrapper>
					<form onSubmit={form.handleSubmit}>
						<CardBody>
							<Heading
								as="h2"
								styleAs="h5"
								className="mb-3 sm:truncate"
							>
								{t("profile.company_details.title")}
							</Heading>
							<FormGroup
								heading={
									<p>
										<Trans
											ns="profile"
											i18nKey={`profile.company_details.copy`}
										/>
									</p>
								}
							>
								<div className="space-y-6">
									{/** Organisation name */}
									<div>
										<FormikLabel
											htmlFor="me.organisations[0].business_legal_name"
											className="flex"
										>
											<Tippy
												content={
													<p>
														{t(
															"profile.company_details.organisation_name.tooltip",
														)}
													</p>
												}
											>
												<span className="flex items-center">
													{t(
														"profile.company_details.organisation_name.title",
													)}
													<FiInfo className="ml-2" />
												</span>
											</Tippy>
										</FormikLabel>
										<FormikInput
											name="me.organisations[0].business_legal_name"
											value={
												form.values.me
													?.organisations?.[0]
													?.business_legal_name ?? ""
											}
											type="text"
											disabled
											autoComplete="organization"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
											aria-label={t(
												"profile.company_details.organisation_name.title",
											)}
										/>
										<FormikError
											field="organisationName"
											namespace="profile"
										/>
									</div>

									{/** Chamber of Commerce number */}
									<div>
										<FormikLabel
											htmlFor="me.organisations[0].business_nr"
											className="flex"
										>
											<Tippy
												content={
													<p>
														{t(
															"profile.company_details.chamber_of_commerce_number.tooltip",
														)}
													</p>
												}
											>
												<span className="flex items-center">
													{t(
														"profile.company_details.chamber_of_commerce_number.title",
													)}
													<FiInfo className="ml-2" />
												</span>
											</Tippy>
										</FormikLabel>
										<FormikInput
											name="me.organisations[0].business_nr"
											value={
												form.values.me
													?.organisations?.[0]
													?.business_nr ?? ""
											}
											type="text"
											disabled
											autoComplete="organization"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
											aria-label={t(
												"profile.company_details.chamber_of_commerce_number.title",
											)}
										/>
										<FormikError
											field="chamberOfCommerceNumber"
											namespace="profile"
										/>
									</div>

									{/** Street name */}
									<div>
										<FormikLabel htmlFor="newPasswordConfirmation">
											{t(
												"profile.personal_details.streetname.title",
											)}
										</FormikLabel>
										<FormikInput
											name="me.organisations[0].location.address_line_primary"
											value={
												form.values.me
													?.organisations?.[0]
													?.location
													?.address_line_primary ?? ""
											}
											type="text"
											required
											autoComplete="organization"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
											aria-label={t(
												"profile.personal_details.streetname.title",
											)}
										/>
										<FormikError
											field="chamberOfCommerceNumber"
											namespace="profile"
										/>
									</div>

									{/** Housenumber + suffix */}
									<div>
										<FormikLabel
											htmlFor="me.organisations[0].location.address_line_secondary"
											className="truncate"
										>
											<Trans
												ns="profile"
												i18nKey="profile.personal_details.houseNumber.title"
												components={{
													small: (
														<span className="ml-2 font-normal text-gray-500" />
													),
												}}
											/>
										</FormikLabel>
										<FormikInput
											name="me.organisations[0].location.address_line_secondary"
											value={
												form.values.me
													?.organisations?.[0]
													?.location
													?.address_line_secondary ??
												""
											}
											type="text"
											required
											autoComplete="organization"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
											aria-label={t(
												"profile.personal_details.houseNumber.title",
											)}
										/>
										<FormikError
											field="chamberOfCommerceNumber"
											namespace="profile"
										/>
									</div>

									{/** Postal code */}
									<div>
										<FormikLabel htmlFor="newPasswordConfirmation">
											{t(
												"profile.personal_details.postcode.title",
											)}
										</FormikLabel>
										<FormikInput
											name="me.organisations[0].location.postal_code"
											value={
												form.values.me
													?.organisations?.[0]
													?.location?.postal_code ??
												""
											}
											type="text"
											required
											autoComplete="organization"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
											aria-label={t(
												"profile.personal_details.postcode.title",
											)}
										/>
										<FormikError
											field="chamberOfCommerceNumber"
											namespace="profile"
										/>
									</div>

									{/** City */}
									<div>
										<FormikLabel htmlFor="newPasswordConfirmation">
											{t(
												"profile.personal_details.city.title",
											)}
										</FormikLabel>
										<FormikInput
											name="me.organisations[0].location.city"
											value={
												form.values.me
													?.organisations?.[0]
													?.location?.city ?? ""
											}
											type="text"
											required
											autoComplete="organization"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
											aria-label={t(
												"profile.personal_details.city.title",
											)}
										/>
										<FormikError
											field="chamberOfCommerceNumber"
											namespace="profile"
										/>
									</div>

									{/** Country */}
									<div className="2xl:col-span-2">
										<FormikLabel
											htmlFor="me.organisations[0].location.country"
											className="truncate"
										>
											{t(
												"profile.personal_details.country.title",
											)}
										</FormikLabel>
										<label className="mt-1">
											<SelectButton className="relative lg:w-1/2">
												{getCountryName(
													form.values?.me
														?.organisations?.[0]
														?.location
														?.country as CoreLocationCountryChoices,
												)}
												<FiChevronDown className="ml-2" />
												<Select
													onChange={(evt) => {
														evt.preventDefault()
														form.setFieldValue(
															"me.organisations[0].location.country",
															evt.currentTarget
																.value,
														)
													}}
													name="me.organisations[0].location.country"
													id="me.organisations[0].location.country"
													value={
														form.values?.me
															?.organisations?.[0]
															?.location
															?.country ?? ""
													}
												>
													{sortedCountries.map(
														(item) => (
															<option
																value={
																	item.code
																}
																key={item.code}
															>
																{
																	item.localisedCountry
																}
															</option>
														),
													)}
												</Select>
											</SelectButton>
											<FormikError field="me.investor.country" />
										</label>
									</div>

									{/** Errors */}
									<div>
										<FormikErrors i18nNamespace="profile" />
									</div>
								</div>
							</FormGroup>
						</CardBody>
						<CardFooter className="text-right">
							<FormikSubmitButton>
								{t("profile.company_details.form_action.save")}
							</FormikSubmitButton>
						</CardFooter>
					</form>
				</CardWrapper>
			</FormikProvider>
		)
	}

	// Show not found template
	return <NotFound />
}
