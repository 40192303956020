import * as Sentry from "@sentry/react"
import { NetworkError } from "@/api/rest/network-errors"

// Ignore these errors
const IGNORE_ERRORS = [
	NetworkError.NETWORK_ERROR,
	NetworkError.FAILED_TO_FETCH,
	NetworkError.NETWORK_ERROR_RESOURCE,
]

// Env variables
const REACT_APP_VERCEL_ENV = process.env.REACT_APP_VERCEL_ENV
const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const REACT_APP_SENTRY_DEBUG = process.env.REACT_APP_SENTRY_DEBUG
const REACT_APP_VERCEL_GIT_COMMIT_SHA =
	process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA

// Init Sentry
export const initSentry = () => {
	Sentry.init({
		dsn: REACT_APP_SENTRY_DSN,
		debug: !!REACT_APP_SENTRY_DEBUG,
		environment: REACT_APP_VERCEL_ENV,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// Tracing
		integrations: [
			new Sentry.BrowserTracing(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),

			/**
			 * Sentry Feedback widget
			 * DOCS: https://docs.sentry.io/platforms/javascript/user-feedback/configuration/
			 */
			Sentry.feedbackIntegration({
				colorScheme: "light",
				buttonLabel: "Feedback",
				formTitle: "Feedback",
				nameLabel: "Naam",
				emailLabel: "Email",
				messagePlaceholder: "Hoe zou je jouw ervaring beoordelen?",
				submitButtonLabel: "Verstuur feedback",
			}),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: REACT_APP_VERCEL_ENV === "production" ? 0.2 : 1.0,

		// Release info
		release: REACT_APP_VERCEL_GIT_COMMIT_SHA,

		// Beforesend hook
		beforeSend: async (event) => {
			try {
				for (const error of event?.exception?.values ?? []) {
					for (const matcher of IGNORE_ERRORS) {
						if (error.value?.match(matcher)) {
							return null
						}
					}
				}
			} catch {}
			return event
		},
	})
}

// Set user
export const setSentryUser = (id: string) => {
	Sentry.setUser({
		id,
	})
}

// Capture exception
export const captureException = (
	...args: Parameters<typeof Sentry.captureException>
) => {
	try {
		return Sentry.captureException(...args)
	} catch (e) {
		console.error("Failed to log exception", e)
	}
}

// Add breadcrumb
export const addBreadcrumb = (
	...args: Parameters<typeof Sentry.addBreadcrumb>
) => {
	try {
		return Sentry.addBreadcrumb({
			...args,
			level: "info",
		})
	} catch (e) {
		console.error("Failed to set breadcrumb", e)
	}
}

// Error Boundary
export const ErrorBoundary = Sentry.ErrorBoundary
export type { ErrorBoundaryProps } from "@sentry/react/types/errorboundary"
