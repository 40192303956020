import { useState, Suspense, Fragment, useMemo } from "react"
import { formatToDecimalPlaces } from "@/lib/math"
import { sendEvent } from "@/lib/analytics"

// Icons
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"

// SEO meta
import { Helmet } from "react-helmet-async"

// Template
import { PageTemplate } from "@/templates/PageTemplate"

// Constants
import { mapCoordinatesCenterOfNetherlands } from "@/misc/constants"

// GraphQL
import {
	useFinanceDashboardMapsQuery,
	useFinanceDashboardQuery,
	useFinancePerformanceQuery,
} from "@/api/graphql"

// Router
import { Link } from "react-router-dom"
import { Pages } from "@/misc/pages"
import { useNavigate } from "react-router"

// UI
import { classNames } from "@/lib/classnames"
import { Combobox } from "@headlessui/react"
import { Input } from "@/components/form-controls/Input"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { CardWrapper } from "@/components/Card"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"
import { FinanceProjectsMapsOverview } from "../components/FinanceProjectsMapsOverview"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

/*
  OFFLINE = 0  # all the sensors are offline
    CHECK_REQUIRED = 1  # part of the sensors is offline or have malformed data
    OK = 2
    INTEREST_NOT_STARTED = 3
    IN_DEVELOPMENT = 4
	*/
const statusMap: { [key: number]: string } = {
	0: "finance.finance_dashboard.table.status.name.offline",
	1: "finance.finance_dashboard.table.status.name.check_required",
	2: "finance.finance_dashboard.table.status.name.ok",
	3: "finance.finance_dashboard.table.status.name.interest_not_started",
	4: "finance.finance_dashboard.table.status.name.in_development",
}

const statusClassNameMap: { [key: number]: string } = {
	0: "text-red-500",
	1: "text-orange-500",
	2: "text-green-500",
	3: "text-orange-500",
	4: "text-orange-500",
}

const statusIconClassNameMap: { [key: number]: string } = {
	0: "bg-red-500",
	1: "bg-orange-500",
	2: "bg-green-500",
	3: "bg-orange-500",
	4: "bg-orange-500",
}

/**
 * FinanceDashboard
 * @returns
 */
export function FinanceDashboard() {
	const t = useTrans("finance")
	const { data } = useFinanceDashboardMapsQuery({
		latitude: mapCoordinatesCenterOfNetherlands.lat,
		longitude: mapCoordinatesCenterOfNetherlands.lng,
		radius: "500",
	})

	return (
		<>
			<Helmet>
				<title>{t("finance.finance_dashboard.title")}</title>
			</Helmet>
			<PageTemplate title={t("finance.finance_dashboard.title")}>
				<div className="flex md:gap-8">
					<CardWrapper className="w-full md:w-1/2">
						<ErrorBoundaryWithErrorState>
							<FinanceProjectsMapsOverview
								center={{
									lat: Number(
										mapCoordinatesCenterOfNetherlands.lat,
									),
									lng: Number(
										mapCoordinatesCenterOfNetherlands.lng,
									),
								}}
								zoom={7}
								points={data?.projects_with_location?.results}
							/>
						</ErrorBoundaryWithErrorState>
					</CardWrapper>
					<div className="w-full md:w-1/2">
						<div className="mb-2 flex flex-col items-end">
							<FinanceProjectsSearch />
						</div>

						<CardWrapper>
							<Suspense
								fallback={<FinanceProjectsTableLoading />}
							>
								<FinanceProjectsTable />
							</Suspense>
						</CardWrapper>
					</div>
				</div>
			</PageTemplate>
		</>
	)
}

/**
 * FinanceProjectsSearch
 * @returns
 */
function FinanceProjectsSearch() {
	const { data } = useFinancePerformanceQuery()
	const [query, setQuery] = useState<string>("")
	const navigate = useNavigate()

	const sortedProjects = useMemo(() => {
		return (
			data?.projects?.sort(
				(a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0,
			) ?? []
		)
	}, [data?.projects])

	const filteredProjects =
		query === ""
			? sortedProjects
			: sortedProjects?.filter((project) => {
					return project?.name
						?.toLowerCase()
						.includes(query.toLowerCase())
			  })

	function onChange(id: string) {
		sendEvent("finance", "performance_project_filter", {
			label: id,
		})
		navigate(`${Pages.FinanceProjects}/${id}`)

		// set query to next project name
		const nextProject = data?.projects?.find(
			(project) => project.id === id,
		)?.name
		if (nextProject) {
			setQuery(nextProject)
		}
	}

	return (
		<Combobox
			as={Fragment}
			value={query}
			onChange={(value) => {
				if (value) onChange(value)
			}}
		>
			<div className="relative mt-1 w-full max-w-xs self-end">
				<Combobox.Button as={Fragment}>
					<Input
						className="w-full"
						onChange={(e) => {
							setQuery(e.currentTarget.value)
						}}
						value={query ?? ""}
					/>
				</Combobox.Button>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<SelectorIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Combobox.Button>

				{filteredProjects && filteredProjects?.length > 0 && (
					<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredProjects.map((project) => (
							<Combobox.Option
								key={project.id}
								value={project.id}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active
											? "bg-primary-500"
											: "text-gray-900",
									)
								}
							>
								{({ selected }) => (
									<>
										<div className="flex">
											<span
												className={classNames(
													"truncate",
													selected && "font-semibold",
												)}
											>
												{project.name}
											</span>
										</div>

										{selected && (
											<span className="text-black-500 absolute inset-y-0 right-0 flex items-center pr-4">
												<CheckIcon
													className="h-5 w-5"
													aria-hidden="true"
												/>
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}

/**
 * FinanceProjectsTable
 *
 * @returns
 */
function FinanceProjectsTable() {
	// Constants
	const projectsPerPage = 10

	// State
	const [currentPage, setCurrentPage] = useState<number>(0)

	const t = useTrans("finance")
	const { data } = useFinanceDashboardQuery({
		limit: projectsPerPage,
		offset: currentPage * projectsPerPage,
	})
	const { formatNumber } = useLang()

	return (
		<>
			<Table>
				<TableHead>
					<tr role="row">
						<TableHeading as="th" variant="static">
							{t("finance.finance_dashboard.table.project.title")}
						</TableHeading>
						<TableHeading as="th" variant="static">
							{t("finance.finance_dashboard.table.status.title")}
						</TableHeading>
						<TableHeading as="th" variant="static">
							{t(
								"finance.finance_dashboard.table.performance_ratio.title",
							)}
						</TableHeading>
						<TableHeading as="th" variant="static">
							{t(
								"finance.finance_dashboard.table.total_production.title",
							)}
						</TableHeading>
						<TableHeading as="th" variant="static">
							{t(
								"finance.finance_dashboard.table.production_delta.title",
							)}
						</TableHeading>
						<TableHeading as="th" variant="static">
							{t(
								"finance.finance_dashboard.table.yearly_production_factor.title",
							)}
						</TableHeading>
					</tr>
				</TableHead>
				<TableBody role="rowgroup">
					{data?.me?.finance_projects?.results?.map(
						(project, index) => {
							return (
								<TableRowCell
									key={project?.id}
									isOdd={index % 2 === 0}
									role="row"
									data-testid={`tablerow-${project?.id}`}
								>
									<TableDataCell className="!p-0">
										<Link
											to={`${Pages.FinanceProjects}/${project?.id}`}
											className="block h-full px-6 py-4 font-bold text-gray-700 hover:text-gray-900"
										>
											{project?.name}
										</Link>
									</TableDataCell>
									<TableDataCell
										className={classNames(
											"flex items-center gap-2",
											statusClassNameMap[
												project?.overview
													?.monitoring_status
													?.status ?? 0
											],
										)}
									>
										<span
											className={classNames(
												"block h-3 w-3 rounded-full",
												statusIconClassNameMap[
													project?.overview
														?.monitoring_status
														?.status ?? 0
												],
											)}
										/>
										{t(
											statusMap[
												project?.overview
													?.monitoring_status
													?.status ?? 0
											],
										)}
										{project?.overview?.monitoring_status
											?.data?.length ? (
											<span>
												(
												{project?.overview?.monitoring_status?.data?.join(
													", ",
												)}
												)
											</span>
										) : null}
									</TableDataCell>
									<TableDataCell
										className={classNames(
											// "font-bold",
											// if the performance ratio is over the target,
											// then make it green. otherwise it is under the targeted
											// performance ratio so let's make it red.
											// taken from horizon:
											// horizon/dashboard/app/dashboard/projectStatusTable.model.js:83
											project?.overview
												?.performance_ratio &&
												project?.overview
													?.targeted_performance_ratio
												? project?.overview
														.performance_ratio >
												  project?.overview
														.targeted_performance_ratio
													? "text-green-500"
													: "text-red-500"
												: null,
										)}
									>
										{t(
											"finance.finance_dashboard.table.performance_ratio.content",
											{
												value: project?.overview
													?.performance_ratio
													? formatNumber(
															parseFloat(
																project
																	?.overview
																	.performance_ratio,
															),
													  )
													: 0,
											},
										)}
									</TableDataCell>
									<TableDataCell>
										{project?.overview?.total_production
											? formatNumber(
													parseFloat(
														project?.overview
															.total_production,
													),
											  )
											: 0}
									</TableDataCell>
									<TableDataCell
										className={classNames(
											// "font-bold",
											// is the production delta over 100? then it's a good signal
											// so we apply green/positive styles
											// taken from horizon:
											// horizon/dashboard/app/dashboard/projectStatusTable.model.js:104
											project?.overview?.production_delta
												? parseFloat(
														project?.overview
															.production_delta,
												  ) > 100
													? "text-green-500"
													: "text-red-500"
												: null,
										)}
									>
										{t(
											"finance.finance_dashboard.table.production_delta.content",
											{
												value: project?.overview
													?.production_delta
													? formatNumber(
															parseFloat(
																project
																	?.overview
																	.production_delta,
															),
													  )
													: 0,
											},
										)}
									</TableDataCell>
									<TableDataCell>
										{project?.overview
											?.yearly_production_factor
											? formatNumber(
													formatToDecimalPlaces(
														parseFloat(
															project?.overview
																.yearly_production_factor,
														),
													),
											  )
											: t(
													"finance.finance_dashboard.table.yearly_production_factor.empty_content",
											  )}
									</TableDataCell>
								</TableRowCell>
							)
						},
					)}
				</TableBody>
			</Table>

			<div className="mt-6 flex w-full justify-center px-4 py-5 sm:p-6 md:mt-8">
				<PaginationAsButtons
					countPerPage={projectsPerPage}
					totalCount={data?.me?.finance_projects?.totalCount ?? 0}
					itemType={"common.pagination.item_types.project"}
					currentPage={currentPage + 1}
					currentItemsAmount={
						data?.me?.finance_projects?.results?.length ?? 0
					}
					onNextPage={() =>
						setCurrentPage((currentPage) => currentPage + 1)
					}
					onPrevPage={() =>
						setCurrentPage((currentPage) =>
							Math.max(currentPage - 1, 0),
						)
					}
					analyticsId="investments"
				/>
			</div>
		</>
	)
}

/**
 * FinanceProjectsTable
 * @returns
 */
function FinanceProjectsTableLoading() {
	const t = useTrans("finance")
	const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]

	return (
		<Table>
			<TableHead>
				<tr role="row">
					<TableHeading as="th" variant="static">
						{t("finance.finance_dashboard.table.project.title")}
					</TableHeading>
					<TableHeading as="th" variant="static">
						{t("finance.finance_dashboard.table.status.title")}
					</TableHeading>
					<TableHeading as="th" variant="static">
						{t(
							"finance.finance_dashboard.table.performance_ratio.title",
						)}
					</TableHeading>
					<TableHeading as="th" variant="static">
						{t(
							"finance.finance_dashboard.table.total_production.title",
						)}
					</TableHeading>
					<TableHeading as="th" variant="static">
						{t(
							"finance.finance_dashboard.table.production_delta.title",
						)}
					</TableHeading>
					<TableHeading as="th" variant="static">
						{t(
							"finance.finance_dashboard.table.yearly_production_factor.title",
						)}
					</TableHeading>
				</tr>
			</TableHead>
			<TableBody role="rowgroup">
				{data.map((project, index) => {
					return (
						<TableRowCell
							key={index}
							isOdd={index % 2 === 0}
							role="row"
							data-testid={`tablerow-${index}`}
						>
							<TableDataCell className="!p-0">
								<span className="block h-full px-6 py-4 font-bold text-gray-700 hover:text-gray-900">
									...
								</span>
							</TableDataCell>
							<TableDataCell
								className={classNames(
									"flex items-center gap-2",
								)}
							></TableDataCell>
							<TableDataCell></TableDataCell>
							<TableDataCell></TableDataCell>
							<TableDataCell></TableDataCell>
							<TableDataCell></TableDataCell>
						</TableRowCell>
					)
				})}
			</TableBody>
		</Table>
	)
}
