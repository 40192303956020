import { useEffect } from "react"
import { Helmet } from "react-helmet-async"

// Router
import { Route, Routes, useLocation } from "react-router-dom"

// Translations
import { useTrans } from "@/i18n"

// UI
import { CardWrapper } from "@/components/Card"
import { PageTemplate } from "@/templates/PageTemplate"

// Pages
import { Payments as PaymentsList } from "./components/Payments"
import { PaymentDetailProjectOwner } from "./PaymentDetailProjectOwner"
import { PaymentDetailEnergySupplier } from "./PaymentDetailEnergySupplier"

/**
 * Payments
 * @returns
 */
export const Payments = () => {
	const t = { payments: useTrans("payments"), finance: useTrans("finance") }
	const { pathname } = useLocation()

	useEffect(() => {
		if (pathname !== "/finance/interest-payments/") {
			window.scrollTo(0, 0)
		}
	}, [pathname])

	return (
		<>
			<Helmet>
				<title>{t.payments("payments.title")}</title>
			</Helmet>
			<Routes>
				<Route
					path="/supplier/:id"
					element={
						<PageTemplate>
							<div className="space-y-5 md:space-y-6 lg:space-y-8">
								<PaymentDetailEnergySupplier />
							</div>
						</PageTemplate>
					}
				/>
				<Route
					path="/owner/:id"
					element={
						<PageTemplate>
							<div className="space-y-5 md:space-y-6 lg:space-y-8">
								<PaymentDetailProjectOwner />
							</div>
						</PageTemplate>
					}
				/>
				<Route
					path="/"
					element={
						<PageTemplate title={t.payments("payments.title")}>
							<div className="space-y-5 md:space-y-6 lg:space-y-8">
								<CardWrapper>
									<PaymentsList />
								</CardWrapper>
							</div>
						</PageTemplate>
					}
				/>
			</Routes>
		</>
	)
}
