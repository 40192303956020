import { Suspense } from "react"

// Router
import { Navigate, Route, Routes } from "react-router-dom"

// Auth
import { AuthenticatedRoute } from "@/components/AuthenticatedRoute"
import { UserRoles } from "@/misc/constants"
import {
	AuthInactivityIndicator,
	useAuth,
	AuthStateStatusEnum,
} from "@/context/auth"

// Pages
import { NextLayout } from "@/layouts/NextLayout"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { Pages, LazyLoadingPages } from "./misc/pages"

/**
 * App
 * @returns
 */
function App() {
	const auth = useAuth()

	// Loggout out pages
	if (
		[
			AuthStateStatusEnum.LOGGED_OUT,
			AuthStateStatusEnum.AUTHENTICATING,
		].includes(auth.status)
	) {
		return (
			<>
				<Routes>
					<Route
						path={Pages.Login}
						element={<LazyLoadingPages.SignIn />}
					/>
					<Route
						path={Pages.Register}
						element={<LazyLoadingPages.Register />}
					/>
					<Route
						path={Pages.RegisterConfirm}
						element={<LazyLoadingPages.Confirm />}
					/>

					<Route
						path={Pages.RecoverPassword}
						element={<LazyLoadingPages.Recover />}
					/>
					<Route
						path={Pages.ResetPassword}
						element={<LazyLoadingPages.Reset />}
					/>
					<Route
						path="*"
						element={<LoadingScreen className="h-full" />}
					/>
				</Routes>
			</>
		)
	}

	// Logged in pages
	return (
		<>
			<AuthInactivityIndicator />
			<Suspense fallback={<LoadingScreen className="h-full" />}>
				<NextLayout>
					<Suspense
						fallback={<LoadingScreen className="flex-grow" />}
					>
						<Routes>
							{/** Hack to make the login page show dashboard overview */}
							<Route
								path={Pages.Login}
								element={
									<Navigate
										to={Pages.InvestmentsProjectsDashboard}
									/>
								}
							/>
							<Route
								path="/"
								element={
									<Navigate
										to={Pages.InvestmentsProjectsDashboard}
									/>
								}
							/>
							<Route
								path={Pages.Investments}
								element={
									<Navigate to={Pages.InvestmentsDashboard} />
								}
							/>
							<Route
								path={Pages.InvestmentsDashboard}
								element={<LazyLoadingPages.Dashboard />}
							/>
							<Route
								path={`${Pages.SettingsProfile}/*`}
								element={<LazyLoadingPages.Profile />}
							/>
							<Route
								path={`${Pages.InvestorProfile}/*`}
								element={<LazyLoadingPages.Investor />}
							/>
							<Route
								path={Pages.Info}
								element={
									<AuthenticatedRoute
										roles={[UserRoles.admin]}
									>
										<LazyLoadingPages.Info />
									</AuthenticatedRoute>
								}
							/>
							{/**
							 * If the same component is used as the child of multiple <Route>s
							 * at the same point in the component tree, React will see this
							 * as the same component instance and the component’s state
							 * will be preserved between route changes. So we can use this to our
							 * advantage to ensure that the follow routes are rendered okay.
							 * Since we need a project by :id, and also these static routes
							 * to be on the same path.
							 */}
							<Route
								path={`${Pages.InvestmentsProjectsDashboard}/*`}
								element={<LazyLoadingPages.Investments />}
							/>
							<Route
								path={`${Pages.InvestmentsProjects}/:id/*`}
								element={<LazyLoadingPages.Project />}
							/>
							<Route
								path={Pages.InvestmentsProjects}
								element={
									<Navigate
										to={Pages.InvestmentsProjectsDashboard}
									/>
								}
							/>
							<Route
								path={Pages.Communications}
								element={<LazyLoadingPages.NotFound />}
							/>

							{/** Finance projects */}
							<Route
								path={`${Pages.FinanceProjects}/:id`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinancePerformance />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={Pages.FinanceProjects}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceDashboard />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceInterestPayments}/*`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.Payments />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceSolarInterestTariff}`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceSolarInterestTariff />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceSolarInterestTariff}/:id/*`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceSolarInterestTariff />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path="*"
								element={<LazyLoadingPages.NotFound />}
							/>
						</Routes>
					</Suspense>
				</NextLayout>
			</Suspense>
		</>
	)
}

export default App
