import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { Anchor } from "@/components/Anchor"
import { ActivateEmail } from "@/components/auth/ActivateEmail"
import { RecoveryCodes } from "@/components/auth/RecoveryCodes"
import { Card } from "@/components/Card"
import { SignInForm } from "@/components/forms/SignIn"
import { TwoFactorAuthForm } from "@/components/forms/TwoFactorAuthForm"
import { LanguageSelector } from "@/components/LanguageSelector"
import { SecondaryHeading } from "@/components/Typography"
import { SignInProvider, useSignIn } from "@/context/signIn"
import { Trans, useTrans } from "@/i18n"
import { SingleScreenLayout } from "@/layouts/SingleScreenLayout"

// Images
import logoDark from "@/assets/icons/zpd/colour.svg"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// Env variables
const EXTERNAL_PLATFORM_PRIVACY_POLICY =
	process.env.REACT_APP_EXTERNAL_PLATFORM_PRIVACY_POLICY

/**
 * SignIn
 * @returns
 */
export const SignIn = () => {
	const t = useTrans("sign-in")
	const { getFeatureFlagValue } = useFeatureFlags()

	return (
		<>
			<Helmet>
				<title>{t("sign-in.title")}</title>
			</Helmet>
			<SingleScreenLayout
				content={
					<SignInProvider>
						<div className="absolute right-0 top-0 md:fixed">
							<LanguageSelector className="px-3 py-3 lg:px-6 lg:py-4 2xl:px-9 2xl:py-7" />
						</div>
						<div className="mb-8 mt-20 md:mb-24 md:mt-10">
							<div className="mb-6">
								<Header />
							</div>
							<div className="text-sm">
								<Forms />
							</div>
							{getFeatureFlagValue(
								"SHOW_REGISTER_LINK_ON_LOGIN_PAGE",
							) === true && <Notice />}
						</div>
						<div className="h-auto w-full bg-white p-4 text-center md:fixed md:bottom-0 md:left-0">
							<p className="text-sm text-gray-500">
								<Trans
									ns="sign-in"
									i18nKey="sign-in.footer"
									components={{
										a: (
											// eslint-disable-next-line jsx-a11y/anchor-has-content
											<Anchor
												className="font-medium text-gray-700 hover:text-gray-900"
												href={
													EXTERNAL_PLATFORM_PRIVACY_POLICY
												}
											/>
										),
									}}
								/>
							</p>
						</div>
					</SignInProvider>
				}
			/>
		</>
	)
}

const Header = () => {
	const { state } = useSignIn()
	const t = useTrans("sign-in")

	let langKey
	if (state === "activate-email") {
		langKey = "sign-in.activate_email.subtitle"
	} else if (state === "confirm") {
		langKey = "sign-in.code.confirm_login"
	}

	return (
		<div className="text-center" data-testid={state}>
			<picture>
				<img src={logoDark} className="mx-auto h-7" alt="" />
			</picture>
			{langKey && (
				<SecondaryHeading styleAs="h2" className="mt-6 md:mb-9">
					{t(langKey)}
				</SecondaryHeading>
			)}
		</div>
	)
}

const Notice = () => {
	const { state } = useSignIn()

	// do not show sign up link at the moment
	if (state !== "sign-in") {
		return null
	}

	return (
		<Card className="mt-6 text-sm">
			<p>
				<Trans
					ns="sign-in"
					i18nKey="sign-in.no_account_prompt"
					components={{
						Link: (
							<Link
								to="/register"
								data-testid="sign-in.no_account_prompt"
								className="font-medium text-secondary-300 hover:text-secondary-700"
							/>
						),
					}}
				/>
			</p>
		</Card>
	)
}

const Forms = () => {
	const { state } = useSignIn()

	return (
		<>
			{state === "sign-in" && <SignInForm />}
			{state === "recovery-codes" && <RecoveryCodes />}
			{state === "activate-email" && <ActivateEmail />}
			{state === "confirm" && <TwoFactorAuthForm />}
		</>
	)
}
