import { Fragment, useEffect, useMemo } from "react"

// Router
import { useParams } from "react-router-dom"

// Icons
import {
	FiDownload,
	FiPaperclip,
	FiCalendar,
	FiHash,
	FiMapPin,
	FiTrash2,
	FiEdit3,
	FiX,
} from "react-icons/fi"

// Animations
import { AnimatePresence, motion } from "@/lib/animations"

// Utils
import { transformErrorToText } from "@/api/rest/utils"
import { dateFormat } from "@/misc/constants"

// Forms
import { useFormik, FormikProvider } from "formik"
import { Yup } from "@/lib/yup"
import { FormGroup } from "@/components/form-controls/FormGroup"
import {
	FormikInput,
	FormikTextArea,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { FormikCheckbox } from "@/components/form-controls"
import { Label } from "@/components/form-controls/Label"

// Context
import {
	usePaymentPatchList,
	PaymentPatchesProvider,
	PaymentPatchesActionType,
} from "@/context/paymentPatches"

// UI
import { classNames } from "@/lib/classnames"
import { CardWrapper, CardBody, CardFooter } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Button } from "@/components/Button"
import { Card } from "@/components/Card"
import { useToasts } from "@/context/toasts"

// GraphQL
import { apiV1PaymentSupplierVerificationCreate } from "@/api/rest/generated/api/api"
import {
	PaymentEntryType,
	PaymentStateEnum,
	usePaymentDetailEnergySupplierQuery,
} from "@/api/graphql"

// Types
import { ActionEnum, PaymentEntryCorrection } from "@/api/rest/generated/@types"

import { DateTime } from "@/lib/dates"
import { Helmet } from "react-helmet-async"
import { formatToDecimalPlaces } from "@/lib/math"

// Translations
import { Trans, useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Tables
import { PaymentOverviewForEnergySupplier } from "./components/PaymentOverviewForEnergySupplier"

/**
 * useCurrentPaymentDetail with only data that Energy suppliers should be able to see!
 * @returns
 */
export function useCurrentPaymentDetail() {
	const { id } = useParams()

	if (!id) {
		throw new Error("Could not read ID from URL parameters")
	}

	return usePaymentDetailEnergySupplierQuery({ id: id })
}

/**
 * @returns PaymentDetailEnergySupplier
 */
export function PaymentDetailEnergySupplier() {
	const t = useTrans("payments")
	const { id } = useParams()

	// Get Data
	const { data, refetch } = useCurrentPaymentDetail()
	const interestPayment = data?.interest_payments?.results?.[0]

	return (
		<div className="space-y-6 md:space-y-8" data-id={id}>
			<Card>
				<div className="md:flex md:space-x-3">
					<Heading as="h4" className="mb-3 mr-auto sm:truncate">
						{t("payments.copy.title")}
					</Heading>
					<div className="flex space-x-3">
						<Button
							variant="primary"
							onClick={() => {
								window.location.href =
									data?.interest_payments?.results?.[0]
										?.download_pdf_url || ""
							}}
						>
							<FiDownload className="mr-2" />
							{t("payments.pdf.button")}
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								window.location.href =
									data?.interest_payments?.results?.[0]
										?.download_csv_url || ""
							}}
						>
							<FiDownload className="mr-2" />
							{t("payments.csv.button")}
						</Button>
					</div>
				</div>
				{t("payments.copy.supplier", {
					name: data?.me?.first_name,
					energySupplierName:
						data?.interest_payments?.results?.[0]?.project?.supplier
							?.name,
				})
					.split("\n\n")
					.map((text, index) => (
						<p
							key={`${index}.${text}`}
							className="mt-4 text-sm text-gray-700"
						>
							{text.split("\n").map((text, index) => (
								<Fragment key={`${index}.${text}`}>
									{text}
									<br />
								</Fragment>
							))}
						</p>
					))}
				<dt className="mt-8 text-sm font-medium text-gray-500">
					{t("payments.info_list.attachments.heading")}
				</dt>
				<dd className="mt-1 text-sm text-gray-700">
					<ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
						<DownloadPDF />
						<DownloadCSV />
					</ul>
				</dd>
			</Card>

			{/** Payment info list */}
			<PaymentInfoList />

			<PaymentPatchesProvider>
				<CardWrapper data-testid="paymentoverviewforenergysupplier">
					<PaymentOverviewForEnergySupplier />
				</CardWrapper>

				{/** Show if pending supplier */}
				{interestPayment?.state === PaymentStateEnum.PendingSupplier ? (
					<PaymentDetailConfirmation refetch={refetch} />
				) : (
					<CardWrapper>
						<CardBody>
							<Heading as="h3" className="mb-3">
								{t("payments.confirmation.finished.date", {
									date: DateTime.fromISO(
										interestPayment?.energy_supplier_acceptance_date_time,
									).toFormat(dateFormat),
								})}
							</Heading>
							{t("payments.confirmation.finished.title")}
						</CardBody>
					</CardWrapper>
				)}
			</PaymentPatchesProvider>
		</div>
	)
}

function DownloadPDF() {
	const { data } = useCurrentPaymentDetail()
	const t = useTrans("payments")

	if (!data?.interest_payments?.results?.[0]?.download_pdf_url) return null

	return (
		<a
			href={data.interest_payments.results[0]?.download_pdf_url}
			target="_blank"
			rel="noreferrer"
			className="group flex w-full items-center justify-between py-3 pl-3 pr-4 text-sm hover:bg-gray-100"
			data-testid="downloadpdf"
		>
			<div className="flex items-center">
				<FiPaperclip
					className="h-5 w-5 flex-shrink-0 text-gray-400"
					aria-hidden="true"
				/>
				<span className="ml-2 flex-1 truncate">
					{t("payments.info_list.attachments.pdf.cta", {
						name: data.interest_payments.results[0]?.project?.name,
					})}
				</span>
			</div>
			<div className="ml-4 font-medium text-secondary-300 group-hover:text-secondary-700">
				{t("payments.info_list.attachments.pdf.download")}
			</div>
		</a>
	)
}

function DownloadCSV() {
	const { data } = useCurrentPaymentDetail()
	const t = useTrans("payments")

	if (!data?.interest_payments?.results?.[0]?.download_csv_url) return null

	return (
		<a
			href={data.interest_payments.results[0]?.download_csv_url}
			target="_blank"
			rel="noreferrer"
			className="group flex w-full items-center justify-between py-3 pl-3 pr-4 text-sm hover:bg-gray-100"
			data-testid="downloadcsv"
		>
			<div className="flex items-center">
				<FiPaperclip
					className="h-5 w-5 flex-shrink-0 text-gray-400"
					aria-hidden="true"
				/>
				<span className="ml-2 flex-1 truncate">
					{t("payments.info_list.attachments.csv.cta", {
						name: data.interest_payments.results[0]?.project?.name,
					})}
				</span>
			</div>
			<div className="ml-4 font-medium text-secondary-300 group-hover:text-secondary-700">
				{t("payments.info_list.attachments.csv.download")}
			</div>
		</a>
	)
}

/**
 * PaymentInfoList
 * @returns
 */
function PaymentInfoList() {
	const { formatDate, config, formatNumber } = useLang()
	const t = useTrans("payments")

	const { data } = useCurrentPaymentDetail()

	const interestPayment = data?.interest_payments?.results?.[0]

	const paymentEntries = interestPayment?.payment_entries ?? []
	const paymentEntriesWithSupplier =
		interestPayment?.payment_entries_with_supplier ?? []

	const allClientAmount = paymentEntries.length
	const withSupplierAmount = paymentEntriesWithSupplier.length

	const currencyCentsFormatter = useMemo(
		() =>
			new Intl.NumberFormat(config.locale, {
				style: "currency",
				maximumFractionDigits: 8,
				currency: config.currency,
			}),
		[config.currency, config.locale],
	)

	return (
		<>
			<Helmet>
				<title>
					{t("payments.info_list.title", {
						name: interestPayment?.project?.name,
					})}
				</title>
			</Helmet>
			<CardWrapper className="bg-white">
				<CardBody className="border-b border-gray-200">
					<div className="flex justify-between">
						<Heading as="h3" className="mb-1">
							{t("payments.info_list.header", {
								name: interestPayment?.project?.name,
							})}
						</Heading>
					</div>
					<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
						{/** Interest period date */}
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<FiCalendar
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							<span className="text-gray-400">
								<Trans
									ns="payments"
									i18nKey="payments.info_list.date"
									values={{
										from: DateTime.fromISO(
											interestPayment?.interestperiod
												?.start,
										).toFormat(
											t(
												"payments.info_list.date.date_format",
											),
										),
										to: DateTime.fromISO(
											interestPayment?.interestperiod
												?.end,
										).toFormat(
											t(
												"payments.info_list.date.date_format",
											),
										),
									}}
									components={{
										strong: (
											<span className="font-medium" />
										),
									}}
								/>
							</span>
						</div>
						{/** ID */}
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<FiHash
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{t("payments.info_list.transaction_id", {
								id: interestPayment?.transaction_id,
							})}
						</div>
						{/** Location */}
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<FiMapPin
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{t("payments.info_list.location", {
								location: interestPayment?.project?.city,
							})}
						</div>
					</div>
				</CardBody>
				<dl>
					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.tariff.title")}
						</dt>
						<dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{/* cost_kwh is in cents */}
							{/* TODO: round currency to 4 digits. compare with mijnstroom dashboard */}
							{interestPayment?.interestperiod?.cost_kwh
								? currencyCentsFormatter.format(
										interestPayment.interestperiod
											.cost_kwh / 100,
								  )
								: 0}
						</dd>
					</div>
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t(
								"payments.info_list.rental_period_from_until.title",
							)}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{formatDate(
								DateTime.fromISO(
									interestPayment?.interestperiod?.start,
								),
								{ variant: "verbose" },
							)}
							{" / "}
							{formatDate(
								DateTime.fromISO(
									interestPayment?.interestperiod?.end,
								),
								{ variant: "verbose" },
							)}
						</dd>
					</div>

					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.number_of_shares.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{interestPayment?.project?.total_shares
								? formatNumber(
										interestPayment.project.total_shares,
								  )
								: 0}
						</dd>
					</div>
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.client_split.title", {
								energyProvider:
									interestPayment?.project?.supplier?.name,
							})}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{t("payments.info_list.client_split.copy", {
								amount: withSupplierAmount,
								total: allClientAmount,
								percentage: formatToDecimalPlaces(
									(withSupplierAmount / allClientAmount) *
										100,
								),
							})}
						</dd>
					</div>

					{/** What zonnedelers have been generating */}
					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t(
								"payments.info_list.total_production_of_investors_with_supplier.title",
								{
									energySupplierName:
										interestPayment?.project?.supplier
											?.name,
								},
							)}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{t(
								"payments.info_list.total_production_investor.copy",
								{
									number: interestPayment?.total_production_of_investors_with_supplier
										? formatNumber(
												parseFloat(
													interestPayment.total_production_of_investors_with_supplier,
												),
										  )
										: 0,
								},
							)}
						</dd>
					</div>

					{/** What zonnedelers have been generating, LIMITED by CAP / FLOOR */}
					{interestPayment?.interest_per_share_limit_applied && (
						<div
							className={classNames(
								"hover:bg-gray-100",
								"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
							)}
						>
							<dt className="text-sm text-gray-500">
								{t(
									"payments.info_list.total_production_of_investors_with_supplier_limited.title",
									{
										energySupplierName:
											interestPayment?.project?.supplier
												?.name,
										interest_per_share_limit_applied:
											interestPayment?.interest_per_share_limit_applied,
									},
								)}
							</dt>
							<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
								{t(
									"payments.info_list.total_production_investor.copy",
									{
										number: interestPayment?.total_production_of_investors_with_supplier_limited
											? formatNumber(
													parseFloat(
														interestPayment.total_production_of_investors_with_supplier_limited,
													),
											  )
											: 0,
									},
								)}
							</dd>
						</div>
					)}
				</dl>
			</CardWrapper>
		</>
	)
}

function PaymentDetailConfirmation({ refetch }: { refetch: Function }) {
	const t = useTrans("payments")
	const { id } = useParams()
	const toasts = useToasts()
	const { patchList } = usePaymentPatchList()

	// Forms
	const form = useFormik({
		initialValues: {
			notes: "",
			orderNumber: "",
			status: "" as ActionEnum,
			patchList: {},
		},
		validationSchema: Yup.object().shape({
			orderNumber: Yup.string().required(),
			patchList: Yup.mixed().test(
				"all-true-map",
				"All entries in the map must have the approved property set to true",
				(map) => {
					// Check if the value is a Map
					if (map instanceof Map) {
						// Convert the Map to an array and iterate over it to check the 'approved' property
						return Array.from(map.values()).every(
							(value) => value.approved === true,
						)
					}
					return false // Invalid if not a Map
				},
			),
		}),
		onSubmit: async (values, helpers) => {
			helpers.setStatus("")
			try {
				let correctionEntries: PaymentEntryCorrection[] = []
				patchList.forEach((value, key) => {
					let paymentEntryCorrection: PaymentEntryCorrection = {
						paymententry: Number(key), // This rest API expects numbers instead of string
					}

					// Delete
					if (value.type === PaymentPatchesActionType.Delete) {
						paymentEntryCorrection.correction = "is_not_client"
					}
					if (value.type === PaymentPatchesActionType.Edit) {
						paymentEntryCorrection.correction = "is_client" // TODO: This hack is in place because the backend does not accept 'supplier_account' without the is_client value.
						paymentEntryCorrection.supplier_account = String(
							value.data.get("supplier_account"),
						)
					}

					// Push to array
					correctionEntries.push(paymentEntryCorrection)
				})

				const response = await apiV1PaymentSupplierVerificationCreate(
					Number(id),
					{
						action: values.status as ActionEnum,
						reference: values.orderNumber,
						message: values.notes,
						correction_entries: correctionEntries,
					},
				)

				// When payment has been confirmed
				if (response.ok === true) {
					toasts.addToast({
						variant: "success",
						id: `handleApiV1PaymentSupplierVerificationCreate-${Date.now()}`,
						text:
							values.status === ActionEnum.accept
								? t("payments.confirmation.success.accept")
								: t("payments.confirmation.success.reject"),
					})
					refetch()
				}
			} catch (e) {
				helpers.setStatus(
					transformErrorToText(e, {
						filteredErrorMessages: new Set("insufficient_rights"),
					}),
				)
			}
		},
	})

	// Set form value if patchList changes TODO: review this useEffect hook
	useEffect(() => {
		form.setFieldValue("patchList", patchList)
	}, [patchList])

	const hasErrorStatus =
		Boolean(form.status) && typeof form.status === "string"

	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit}>
				<CardWrapper>
					<CardBody>
						<Heading as="h3" className="mb-3">
							{t("payments.confirmation.title")}
						</Heading>
						<FormGroup heading={t("payments.confirmation.copy")}>
							<div className="space-y-6 sm:space-y-5">
								<div>
									<AnimatePresence initial={false}>
										{patchList.size > 0 && (
											<motion.div
												initial={{
													height: 0,
													opacity: 0,
												}}
												animate={{
													height: "auto",
													opacity: 1,
												}}
												exit={{ height: 0, opacity: 0 }}
												transition={{ ease: "easeOut" }}
												className="overflow-hidden"
											>
												<div className="pb-6 sm:pb-5 ">
													<PaymentDetailPatchList />
												</div>
											</motion.div>
										)}
									</AnimatePresence>

									{/** Order number */}
									<div>
										<Label
											className="truncate"
											htmlFor="orderNumber"
										>
											{t(
												"payments.confirmation.order_number",
											)}
										</Label>
										<FormikInput
											name="orderNumber"
											required
											aria-label={t(
												"payments.confirmation.order_number",
											)}
											className="block w-full lg:w-3/4"
										/>
									</div>
								</div>
								<div>
									<Label className="truncate" htmlFor="notes">
										{t("payments.confirmation.note")}
									</Label>
									<FormikTextArea
										name="notes"
										rows={5}
										aria-label="notes"
										className="block w-full lg:w-1/2"
									/>
								</div>
								<div>
									<p className="text-sm text-gray-500">
										{t(
											"payments.confirmation.supplier.approve_deny.copy",
										)}
									</p>
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<div className="flex items-center justify-end space-x-3">
							{hasErrorStatus && (
								<p className="mr-4 font-medium text-red-500">
									{t(
										`payments.confirmation.form.error.${form.status}`,
									)}
								</p>
							)}
							<FormikSubmitButton
								onClick={() =>
									form.setFieldValue("status", "accept")
								}
								variant={"primary"}
							>
								{t("payments.confirmation.form.send")}
							</FormikSubmitButton>
						</div>
					</CardFooter>
				</CardWrapper>
			</form>
		</FormikProvider>
	)
}

/**
 * PaymentDetailPatchList
 * @returns
 */
function PaymentDetailPatchList() {
	const t = useTrans("payments")
	const { patchList, onPatchCell } = usePaymentPatchList()

	const { data } = useCurrentPaymentDetail()

	const patchListEntries = Array.from(patchList.entries())

	const deletedEntries = patchListEntries.reduce((acc, [, value]) => {
		if (value.type === "delete") return acc + 1
		return acc
	}, 0)

	const editedEntries = patchListEntries.reduce((acc, [, value]) => {
		if (value.type === "edit") return acc + 1
		return acc
	}, 0)

	const changedEntries = patchListEntries.reduce((acc, [, value]) => {
		if (value.type === "edit") return acc + value.data.size
		return acc
	}, 0)

	function getLabelText(field: keyof PaymentEntryType) {
		return t(`payments.confirmation.changes.text.field.${String(field)}`)
	}

	return (
		<div className="lg:w-3/4">
			<p className="text-base font-medium text-gray-700">
				{t("payments.confirmation.changes.title")}
			</p>
			<ul className="lg:mt-2">
				<AnimatePresence initial={false}>
					{patchListEntries.map(([key, value]) => {
						const paymententryId = key

						// Find and display user info
						const user =
							data?.interest_payments?.results?.[0]?.payment_entries_with_supplier?.find(
								(paymentEntry) =>
									paymentEntry.id === String(paymententryId),
							)

						if (!user) {
							return (
								<motion.div
									key={key}
									initial={{ height: 0 }}
									animate={{ height: "auto" }}
									exit={{ height: 0 }}
									transition={{ ease: "easeOut" }}
									className="border-b border-gray-200"
								>
									{t(
										"payments.confirmation.changes.text.error",
									)}
								</motion.div>
							)
						}

						return (
							<motion.li
								key={key}
								initial={{ height: 0, opacity: 1 }}
								animate={{ height: "auto", opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
								transition={{ ease: "easeOut" }}
								className="overflow-hidden border-b border-gray-200"
							>
								<div className="flex py-4">
									<div className="mr-3 flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 text-gray-500">
										{value.type ===
										PaymentPatchesActionType.Delete ? (
											<FiTrash2 size={14} />
										) : (
											<FiEdit3 size={14} />
										)}
									</div>
									<div>
										<div className="space-y-2">
											<p className="text-sm font-medium text-gray-900">
												{user.name}
											</p>
											{value.type ===
												PaymentPatchesActionType.Delete && (
												<p className="text-sm text-gray-500">
													{t(
														"payments.confirmation.changes.deleted",
													)}
												</p>
											)}
											{value.type ===
											PaymentPatchesActionType.Edit
												? Array.from(
														value.data.entries(),
												  ).map(
														([
															dataKey,
															dataValue,
														]) => (
															<p
																key={`${String(
																	dataKey,
																)}.${key}`}
																className="text-sm text-gray-500"
															>
																<Trans
																	ns="payments"
																	i18nKey="payments.confirmation.changes.text"
																	values={{
																		field: getLabelText(
																			dataKey,
																		),
																		from:
																			// @ts-ignore
																			user[
																				dataKey
																			],
																		to: dataValue,
																	}}
																	components={{
																		strong: (
																			<strong />
																		),
																	}}
																/>
															</p>
														),
												  )
												: null}
										</div>
									</div>
									<div className="m-0 ml-auto flex items-end">
										<div className="flex h-10 w-10 cursor-pointer items-center justify-center p-0 text-sm text-gray-500 outline-0">
											<FormikCheckbox
												name={`origin.work`}
												checked={value.approved}
												onChange={() => {
													// @ts-ignore
													onPatchCell({
														paymententryId,
														approved:
															!value.approved,
													})
												}}
												className="order-0 mr-2"
											/>
										</div>

										<button
											className="flex h-10 w-10 cursor-pointer items-center justify-center p-0 text-sm text-gray-500 outline-0"
											onClick={(event) => {
												event.preventDefault()
												onPatchCell({
													paymententryId,
													type: PaymentPatchesActionType.Clear,
												})
											}}
										>
											<FiX className="block" />
										</button>
									</div>
								</div>
							</motion.li>
						)
					})}
				</AnimatePresence>
			</ul>
			<div className="mb-4 border-b border-gray-200 py-3 pl-11">
				{deletedEntries > 0 && (
					<p className="text-sm text-gray-500">
						<Trans
							ns="payments"
							i18nKey="payments.confirmation.changes.total.deleted"
							values={{ count: deletedEntries }}
							components={{
								strong: (
									<strong className="font-medium text-gray-700" />
								),
							}}
						/>
					</p>
				)}
				{editedEntries > 0 ? (
					<p className="text-sm text-gray-500">
						<Trans
							ns="payments"
							i18nKey={
								editedEntries === changedEntries
									? "payments.confirmation.changes.total.edited"
									: "payments.confirmation.changes.total.edited.with_changes"
							}
							values={{
								count: editedEntries,
								text: t(
									"payments.confirmation.changes.total.changes",
									{ count: changedEntries },
								),
							}}
							components={{
								strong: (
									<strong className="font-medium text-gray-700" />
								),
							}}
						/>
					</p>
				) : null}
			</div>
		</div>
	)
}
