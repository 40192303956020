import { ChangeEvent } from "react"
import { FormikProvider, useFormik } from "formik"
import { Helmet } from "react-helmet-async"
import { Link, useNavigate } from "react-router-dom"
import { ApiErrorResponseInterface } from "@/api/rest"
import { Card } from "@/components/Card"
import { Checkbox } from "@/components/form-controls/Checkbox"
import { FormikError } from "@/components/form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { Input } from "@/components/form-controls/Input"
import { Label } from "@/components/form-controls/Label"
import { Heading } from "@/components/Typography"
import { Trans, useTrans } from "@/i18n"
import { SingleScreenLayout } from "../layouts/SingleScreenLayout"
import { sendEvent } from "@/lib/analytics"
import { Yup } from "@/lib/yup"

// Queries
import { apiAuthUsersCreate } from "@/api/rest/generated/api/api"

// Images
import IconZPD from "@/assets/icons/zpd/logo.svg"

export const Register = () => {
	const t = useTrans("register")

	return (
		<>
			<Helmet>
				<title>{t("register.title")}</title>
			</Helmet>
			<SingleScreenLayout
				content={
					<>
						<div className="text-center">
							<picture>
								<img
									src={IconZPD}
									className="mx-auto h-12"
									alt=""
								/>
							</picture>
							<Heading styleAs="h3" className="mt-6">
								{t("register.title")}
							</Heading>
						</div>
						<Card className="mt-6" variant="gray">
							<RegisterForm />
						</Card>
						<Card className="mt-6 text-sm">
							<p>
								<Trans
									ns="register"
									i18nKey="register.already_have_an_account_no_account_prompt"
									components={{
										Link: (
											<Link
												to="/login"
												className="font-medium text-secondary-300 hover:text-secondary-700"
											/>
										),
									}}
								/>
							</p>
						</Card>
					</>
				}
			/>
		</>
	)
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("register.form_errors.name.required"),
	email: Yup.string()
		.email("register.form_errors.email.invalid")
		.required("register.form_errors.email.required"),
	password: Yup.string().required("register.form_errors.password.required"),
	passwordConfirmation: Yup.string()
		.oneOf(
			[Yup.ref("password")],
			"register.form_errors.password_confirmation.must_match",
		)
		.required("register.form_errors.password_confirmation.required"),
	termsAndConditions: Yup.bool().oneOf(
		[true],
		"register.form_errors.terms_and_conditions.requried",
	),
})

const initialValues = {
	name: "",
	email: "",
	password: "",
	passwordConfirmation: "",
	termsAndConditions: false,
	errors: { local: "", common: "" },
}

const RegisterForm = () => {
	const navigate = useNavigate()
	const t = useTrans("register")

	const form = useFormik({
		validationSchema,
		initialValues,
		onSubmit: async (values, helpers) => {
			sendEvent("register", "submit")
			try {
				const response = await apiAuthUsersCreate({
					// @ts-ignore
					name: values.name,
					password: values.password,
					email: values.email,
				})

				if (!response) {
					throw new Error("No response")
				}

				sendEvent("register", "submit-success")
				navigate("/confirm")
			} catch (e) {
				sendEvent("register", "submit-error")
				const error = e as ApiErrorResponseInterface | null

				if (error?.json?.message === "duplicate_user_registration") {
					helpers.setFieldError(
						"errors.local",
						"register.form_errors.duplicate_user_registration",
					)
				} else if (error?.json?.message) {
					helpers.setFieldError("errors.local", error?.json?.message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	const handleChangeWithAnalytics = (e: ChangeEvent<any>) => {
		sendEvent("register", `onchange-${e.currentTarget.name}`)
		return form.handleChange(e)
	}

	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit} className="space-y-6">
				<div>
					<Label
						htmlFor="name"
						className="block truncate"
						hasError={!!form.errors.name && form.touched.name}
						data-testid="register.name.label"
					>
						{t("register.form_field.name.label")}
					</Label>
					<div>
						<FormikInput
							name="name"
							autoComplete="name"
							required
							className="block w-full"
							placeholder={t(
								"register.form_field.name.placeholder",
							)}
							hasError={!!form.errors.name && form.touched.name}
							data-testid="register.name"
							aria-label="name"
							autoFocus
						/>
						<FormikError field="name" namespace="register" />
					</div>
				</div>
				<div>
					<Label
						htmlFor="email"
						className="block truncate"
						hasError={!!form.errors.email && form.touched.email}
						data-testid="register.email.label"
					>
						{t("register.form_field.email.label")}
					</Label>
					<div>
						<FormikInput
							name="email"
							type="email"
							autoComplete="email"
							required
							className="block w-full"
							placeholder={t(
								"register.form_field.email.placeholder",
							)}
							hasError={!!form.errors.email && form.touched.email}
							data-testid="register.email"
							aria-label="email"
						/>
						<FormikError field="email" namespace="register" />
					</div>
				</div>

				<div>
					<Label
						htmlFor="password"
						className="flex-wrap items-center justify-between truncate"
						data-testid="register.password.label"
						hasError={
							!!form.errors.password && form.touched.password
						}
					>
						{t("register.form_field.password.label")}
					</Label>
					<div>
						<Input
							id="password"
							name="password"
							type="password"
							autoComplete="current-password"
							required
							className="block w-full"
							onChange={handleChangeWithAnalytics}
							onBlur={form.handleBlur}
							value={form.values.password}
							data-testid="register.password"
							aria-label="password"
							hasError={
								!!form.errors.password && form.touched.password
							}
						/>
						<FormikError field="password" namespace="register" />
					</div>
				</div>

				<div>
					<Label
						htmlFor="password"
						className="flex-wrap items-center justify-between truncate"
						data-testid="register.password_confirmation.label"
						hasError={
							!!form.errors.passwordConfirmation &&
							form.touched.passwordConfirmation
						}
					>
						{t("register.form_field.password_confirmation.label")}
					</Label>
					<div>
						<Input
							id="passwordConfirmation"
							name="passwordConfirmation"
							type="password"
							required
							className="block w-full"
							onChange={handleChangeWithAnalytics}
							onBlur={form.handleBlur}
							value={form.values.passwordConfirmation}
							data-testid="register.password_confirmation"
							aria-label="passwordConfirmation"
							hasError={
								!!form.errors.passwordConfirmation &&
								form.touched.passwordConfirmation
							}
						/>
						<FormikError
							field="passwordConfirmation"
							namespace="register"
						/>
					</div>
				</div>

				<div>
					<label
						htmlFor="termsAndConditions"
						className="flex text-sm text-gray-900"
						data-testid="register.terms_and_conditions.label"
					>
						<Checkbox
							id="termsAndConditions"
							name="termsAndConditions"
							checked={form.values.termsAndConditions}
							onChange={handleChangeWithAnalytics}
							onBlur={form.handleBlur}
							data-testid="register.terms_and_conditions"
							aria-label="termsAndConditions"
							className="mr-2 mt-1"
						/>
						{t("register.form_field.terms_and_conditions.label")}
					</label>
				</div>

				<div>
					<FormikErrors i18nNamespace="register" />
					<FormikSubmitButton
						data-testid="register.submit"
						className="flex w-full"
						onClick={() => sendEvent("register", "onsubmit")}
					>
						{t(
							form.isSubmitting
								? "register.form_status.submitting"
								: "register.form_action.submit",
						)}
					</FormikSubmitButton>
				</div>
			</form>
		</FormikProvider>
	)
}
