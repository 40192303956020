import { DateTime } from "@/lib/dates"
import { useMemo } from "react"
import { useLang } from "@/context/lang"
import { useCurrentDate } from "@/hooks/useCurrentDate"
import { useTrans } from "@/i18n"
import { useInvestmentsGraphProductionData } from "./InvestmentsGraphProduction"

// Graphs
import {
	AvailableDatePeriods,
	useProductionGraphContext,
} from "@/components/graphs/types/bar/ProductionGraph"
import { GraphHeader } from "@/components/graphs/header/GraphHeader"

function useToday() {
	return DateTime.fromJSDate(useCurrentDate())
}

export function InvestmentsGraphProductionHeader() {
	const t = useTrans("finance")
	const { config, formatNumber } = useLang()
	const today = useToday()
	const { data } = useInvestmentsGraphProductionData()
	const { graphInterval } = useProductionGraphContext()

	const amount = useMemo(() => {
		return data?.me?.investor_production_stats?.production_data?.reduce(
			(acc, curr) => {
				const value = curr?.production ? parseFloat(curr.production) : 0
				return value ? acc + value : acc
			},
			0,
		)
	}, [data])

	return (
		<>
			{graphInterval === AvailableDatePeriods.MONTH ? (
				<GraphHeader
					title={t("finance.data.production.yearly.title", {
						year: today.year,
					})}
					subtitle={t("finance.data.production.yearly.subtitle", {
						date: today
							.toJSDate()
							.toLocaleDateString(config.locale, {
								month: "long",
							}),
						amount: amount ? formatNumber(amount) : 0,
					})}
				/>
			) : (
				<GraphHeader
					title={t("finance.data.production.monthly.title", {
						month: today
							.toJSDate()
							.toLocaleDateString(config.locale, {
								month: "long",
							}),
					})}
					subtitle={t("finance.data.production.monthly.subtitle", {
						date: today.toFormat(
							t("finance.data.production.monthly.date_format"),
						),
						amount: amount ? formatNumber(amount) : 0,
					})}
				/>
			)}
		</>
	)
}
