import { useEffect, useRef } from "react"
import OdometerJSGenerator from "@/lib/odometer"
import "./odometer.css"

const OdometerJS = OdometerJSGenerator() as any

type OdometerProps = {
	animation?: boolean
	auto?: boolean
	duration?: number
	format?: string
	selector?: string
	theme?: string
	value: number
}

export function Odometer(props: OdometerProps) {
	const odometer = useRef<any>(null!)
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!odometer.current) {
			odometer.current = new OdometerJS({
				el: ref.current,
				...props,
			})
		}
		odometer.current.update(props.value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (odometer.current && typeof odometer.current.update === "function") {
			odometer.current.update(props.value)
		}
	}, [props.value])

	return <div ref={ref} />
}
