export const featureFlags: { name: string; value: boolean }[] = [
	{ name: "FISCAL_OVERVIEW_BY_YEAR_DISPLAY_BUTTONS", value: false },
	{ name: "FEATURE_ENABLE_FISCAL_OVERVIEW_DATE_SELECTOR", value: false },
	{ name: "PROJECT_DISPLAY_ENERGY_SUPPLIER_LINK", value: false },
	{ name: "PROJECT_DISPLAY_DATE_SELECTOR", value: false },
	{ name: "ENABLE_EXTENDED_PROJECT_DASHBOARD", value: false },
	{ name: "PROJECT_DISPLAY_MESSAGE_COUNT", value: false },
	{ name: "ENABLE_MFA_SECURITY_PAGE", value: false },
	{ name: "ENABLE_MENU_COLLAPSE_ON_DESKTOP", value: false },
	{ name: "ENABLE_MENU_ITEM_REVENUE", value: false },
	{ name: "ENABLE_MENU_ITEM_ALL_PAYMENTS", value: false },
	{
		name: "ENABLE_PROJECT_DASHBOARD_PRODUCTION_LIVE_PERFORMANCE",
		value: false,
	},
	{ name: "DISPLAY_LINKS_IN_USER_MENU", value: true },
	{ name: "SHOW_REGISTER_LINK_ON_LOGIN_PAGE", value: false },
	{
		name: "FEATURE_ENABLE_INVESTOR_FUND_ORIGINS",
		value: false,
	},
]
