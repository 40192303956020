import { DetailedHTMLProps, LabelHTMLAttributes } from "react"
import { HTMLProps } from "react"
import { classNames } from "@/lib/classnames"

export interface LabelProps<Values extends Object | string>
	extends DetailedHTMLProps<
		LabelHTMLAttributes<HTMLLabelElement>,
		HTMLLabelElement
	> {
	hasError?: boolean
	htmlFor?: KeyOfObjectOrString<Values>
	/**
	 * Stacked gives a margin bottom to the element as it assumes an input will be below it, so the
	 * 	relationship between the label and input is a vertical one.
	 * Grouped meas that the label is used as part of a form group, so the relationship
	 * 	to label and input is a horizontal one.
	 * Solo assume the element is by itself so no margin or padding is added.
	 */
	positioning?: "stacked" | "grouped" | "solo"
}

export const Label = <Data extends Object | string>({
	children,
	hasError,
	positioning = "stacked",
	className = "",
	...props
}: LabelProps<Data>) => (
	<label
		className={classNames(
			className,
			"group block cursor-pointer text-sm",
			hasError
				? `text-red-600 hover:text-red-700`
				: `text-gray-700 hover:text-gray-900`,
			positioning === "stacked" && `mb-1`,
		)}
		{...props}
	>
		{children}
	</label>
)

export const LabelSubtitle = ({
	className,
	...rest
}: HTMLProps<HTMLParagraphElement>) => {
	return (
		<p
			className={classNames(
				className,
				"mb-2 mt-1 text-xs text-gray-500 group-hover:text-gray-700 md:w-8/12",
			)}
			{...rest}
		/>
	)
}

export const LabelWithSubtitle = <Data extends Object | string>({
	subtitle,
	children,
	...props
}: LabelProps<Data> & { subtitle: string }) => {
	return (
		<Label {...props}>
			{children}
			<p className="mb-2 mt-1 text-xs text-gray-500 md:w-8/12"></p>
		</Label>
	)
}
