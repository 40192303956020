import { Fragment, useMemo, useState } from "react"
import { useFinancePerformanceQuery } from "@/api/graphql"
import { sendEvent } from "@/lib/analytics"
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Icons
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import { FiDownload } from "react-icons/fi"

// SEO meta
import { Helmet } from "react-helmet-async"

// Router
import { useNavigate } from "react-router"
import { Pages } from "@/misc/pages"

// Translations
import { useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/classnames"
import { Combobox } from "@headlessui/react"
import { Input } from "@/components/form-controls/Input"
import { GraphLabel, GraphLabels } from "@/components/graphs/GraphLabels"
import { Heading } from "@/components/Typography"
import { Card } from "@/components/Card"
import { SolarInterestTariffGraph } from "@/components/graphs/types/area/SolarInterestTariffGraph"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"

// Template
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// REST queries
import {
	useApiV1ComparisonProjectEndexTariffsList,
	useApiV1ComparisonProjectEndexPriceRetrieve,
} from "@/api/rest/generated/api/api"

// Graphs
import { FinanceGraphSolarInterestTariff } from "../components/FinanceGraphSolarInterestTariff"

// Types
import { EndexTariff } from "@/api/rest/generated/@types"

function useCurrentFinanceProject() {
	const projectId = useCurrentFinanceProjectId()
	const { data } = useFinancePerformanceQuery()

	return data?.projects?.find((project) => project.id === String(projectId))
}

export function FinanceSolarInterestTariff() {
	const t = { finance: useTrans("finance"), common: useTrans("common") }
	const projectId = useCurrentFinanceProjectId() || 1 // TODO: Pick a default

	// Rest Query
	const { data } = useApiV1ComparisonProjectEndexTariffsList(projectId)
	const { data: dataEndexPrice } =
		useApiV1ComparisonProjectEndexPriceRetrieve(projectId)

	return (
		<>
			<Helmet>
				<title>
					{t.finance("finance.solar_interest_tariff.title")}
				</title>
			</Helmet>
			<PageTemplate
				title={t.finance("finance.solar_interest_tariff.title")}
				topRightComponent={<FinancePerformanceSelect />}
				backHref={Pages.FinanceProjects}
				tabs={
					<>
						<PageTemplateLink
							to={`${Pages.FinanceProjects}/${projectId}`}
							name={t.common(
								"common.navigation.finance.dashboard.link",
							)}
							analyticsId={t.common(
								"common.navigation.finance.dashboard.link",
							)}
						/>
						<PageTemplateLink
							to={`${Pages.FinanceSolarInterestTariff}/${projectId}`}
							name={t.common(
								"common.navigation.finance.solar_interest_tariff.link",
							)}
							analyticsId={t.common(
								"common.navigation.finance.dashboard.link",
							)}
						/>
					</>
				}
			>
				<Card className="mb-8">
					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t.finance("finance.solar_interest_tariff.title")}
					</Heading>
					<p className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
						{t.finance("finance.solar_interest_tariff.copy")}
					</p>
				</Card>

				<div className="flex flex-col gap-8 md:flex-row">
					<Card className="md:w-1/2">
						<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
							{t.finance(
								"finance.solar_interest_tariff.block.endex_tariffs.title",
							)}
						</Heading>

						<SolarInterestTariffGraph>
							<SolarInterestTariffGraph.Content>
								<FinanceGraphSolarInterestTariff />
							</SolarInterestTariffGraph.Content>
							<SolarInterestTariffGraph.Footer>
								<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
									<GraphLabel
										type="line"
										variant="primary"
										title={t.finance(
											"finance.solar_interest_tariff.block.endex_tariffs",
										)}
									/>
									<GraphLabel
										type="bar"
										variant="default"
										title={t.finance(
											"finance.solar_interest_tariff.block.average_tariffs",
										)}
									/>
								</GraphLabels>
							</SolarInterestTariffGraph.Footer>
						</SolarInterestTariffGraph>
					</Card>

					<div className="flex flex-col gap-8 md:w-1/2">
						<Card>
							<Heading
								as="h3"
								styleAs="h6"
								className="mr-2 lg:mr-0"
							>
								{t.finance(
									"finance.solar_interest_tariff.block.solar-tariffs.title",
								)}
							</Heading>
							<Table>
								<TableHead>
									<tr role="row">
										<TableHeading as="th" variant="static">
											{t.finance(
												"finance.solar_interest_tariff.block.solar-tariffs.table.headers.period",
											)}
										</TableHeading>
										<TableHeading as="th" variant="static">
											{t.finance(
												"finance.solar_interest_tariff.block.solar-tariffs.table.headers.fixed_surcharge",
											)}
										</TableHeading>
										<TableHeading as="th" variant="static">
											{t.finance(
												"finance.solar_interest_tariff.block.solar-tariffs.table.headers.average_endex_tariff",
											)}
											<div className="hidden">
												{t.finance(
													"finance.solar_interest_tariff.block.solar-tariffs.table.headers.average_endex_tariff.tooltip",
												)}
											</div>
										</TableHeading>
										<TableHeading
											as="th"
											variant="static"
											className="w-20"
										>
											{t.finance(
												"finance.solar_interest_tariff.block.solar-tariffs.table.headers.export_link",
											)}
										</TableHeading>
									</tr>
								</TableHead>
								{data && data.length > 0 && (
									<TableBody role="rowgroup">
										{data.map(
											(
												tariff: EndexTariff,
												index: number,
											) => {
												return (
													<TableRowCell
														key={index}
														isOdd={index % 2 === 0}
														role="row"
													>
														<TableDataCell className="break-word whitespace-pre-wrap">
															{`${tariff.start} - ${tariff.end}`}
														</TableDataCell>
														<TableDataCell>
															{t.finance(
																"finance.solar_interest_tariff.graph.tooltip.average_tariffs.value",
																{
																	price: tariff.fixed_surcharge,
																},
															)}
														</TableDataCell>
														<TableDataCell>
															{t.finance(
																"finance.solar_interest_tariff.graph.tooltip.average_tariffs.value",
																{
																	price: parseFloat(
																		tariff.average_endex_tariff,
																	).toFixed(
																		2,
																	),
																},
															)}
														</TableDataCell>
														<TableDataCell className="w-20">
															<a
																href={
																	tariff.export_link
																}
																className={classNames(
																	"text-gray-500 hover:text-gray-700",
																	"group px-4",
																	"whitespace-nowrap text-sm font-medium",
																)}
															>
																<FiDownload />
															</a>
														</TableDataCell>
													</TableRowCell>
												)
											},
										)}
									</TableBody>
								)}
							</Table>
						</Card>
						<Card>
							<Heading
								as="h3"
								styleAs="h6"
								className="mr-2 lg:mr-0"
							>
								Endex 12-0-12
							</Heading>
							{`Endex 12-0-12 (${dataEndexPrice?.start} - ${dataEndexPrice?.end}): ${dataEndexPrice?.price}`}
						</Card>
					</div>
				</div>
			</PageTemplate>
		</>
	)
}

function FinancePerformanceSelect() {
	const { data } = useFinancePerformanceQuery()
	const currentProject = useCurrentFinanceProject()
	const [query, setQuery] = useState<string>(currentProject?.name ?? "")
	const navigate = useNavigate()

	const sortedProjects = useMemo(() => {
		return (
			data?.projects?.sort(
				(a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0,
			) ?? []
		)
	}, [data?.projects])

	const filteredProjects =
		query === "" || query === currentProject?.name
			? sortedProjects
			: sortedProjects?.filter((project) => {
					return project?.name
						?.toLowerCase()
						.includes(query.toLowerCase())
			  })

	function onChange(id: string) {
		sendEvent("finance", "performance_project_filter", {
			label: id,
		})
		navigate(`${Pages.FinanceSolarInterestTariff}/${id}`)

		// set query to next project name
		const nextProject = data?.projects?.find(
			(project) => project.id === id,
		)?.name
		if (nextProject) {
			setQuery(nextProject)
		}
	}

	return (
		<Combobox
			as={Fragment}
			value={currentProject?.id}
			onChange={(value) => {
				if (value) onChange(value)
			}}
		>
			<div className="relative mt-1 w-full max-w-xs">
				<Combobox.Button as={Fragment}>
					<Input
						className="w-full"
						onChange={(e) => {
							setQuery(e.currentTarget.value)
						}}
						value={query ?? ""}
					/>
				</Combobox.Button>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<SelectorIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Combobox.Button>

				{filteredProjects && filteredProjects?.length > 0 && (
					<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredProjects.map((project) => (
							<Combobox.Option
								key={project.id}
								value={project.id}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active
											? "bg-primary-500"
											: "text-gray-900",
									)
								}
							>
								{({ selected }) => (
									<>
										<div className="flex">
											<span
												className={classNames(
													"truncate",
													selected && "font-semibold",
												)}
											>
												{project.name}
											</span>
										</div>

										{selected && (
											<span className="text-black-500 absolute inset-y-0 right-0 flex items-center pr-4">
												<CheckIcon
													className="h-5 w-5"
													aria-hidden="true"
												/>
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}
