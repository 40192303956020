import { ReactNode } from "react"
import { Heading } from "./Typography"

interface PageHeaderWithBarProps {
	content: ReactNode
	header?: ReactNode
	heading: string
}

export const PageHeaderWithBar = ({
	content,
	header,
	heading,
}: PageHeaderWithBarProps) => (
	<>
		<div
			className={`${
				header ? "pb-24" : "pb-32 sm:pb-36"
			} bg-brandgray-700`}
		>
			<div className="hidden items-center px-4 py-4 text-white sm:justify-between sm:px-6 lg:block lg:px-8">
				<div className="min-w-0 flex-1">
					<Heading as="h1" styleAs="h4" className="sm:truncate">
						{heading}
					</Heading>
				</div>
			</div>
			{header}
		</div>
		<div className="-mt-24">
			<div className="mb-6 sm:mb-8" data-testid="content">
				{content}
			</div>
		</div>
	</>
)
