import XIcon from "@heroicons/react/solid/XIcon"
import { useTrans } from "@/i18n"
import { DialogWrapper } from "./DialogWrapper"

export interface ConfirmDialogProps {
	isOpen: boolean
	description?: string
	onClose?: () => void
	onConfirm?: () => void
	buttonText?: {
		yes?: string
		no?: string
	}
}

export const ConfirmDialog = ({
	isOpen,
	description,
	onClose,
	onConfirm,
	buttonText,
}: ConfirmDialogProps) => {
	const t = useTrans("dialog")

	return (
		<>
			{isOpen && (
				<DialogWrapper>
					<div className="flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600">
						<h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
							{t("dialog.confirm.are_you_sure")}
						</h3>
						<button
							onClick={onClose}
							type="button"
							className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
							data-modal-toggle="default-modal"
						>
							<XIcon width={24} className="text-2xl text-white" />
						</button>
					</div>
					{description && (
						<div className="space-y-6 p-6">
							<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
								{description}
							</p>
						</div>
					)}
					<div
						className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-600"
						data-testid="loading-screen-minimal"
					>
						<button
							onClick={onClose}
							data-modal-toggle="default-modal"
							type="button"
							className="ml-auto rounded-lg 
										border border-gray-200 
										bg-white px-5 py-2.5 
										text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 
										focus:z-10 focus:ring-4 focus:ring-gray-300 
										dark:border-gray-500 dark:bg-gray-700 
										dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
							data-testid="close-dialog-button"
						>
							{buttonText?.no
								? buttonText?.no
								: t("dialog.confirm.button_no")}
						</button>

						<button
							onClick={onConfirm}
							data-modal-toggle="default-modal"
							type="button"
							className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
							data-testid="confirm-dialog-button"
						>
							{buttonText?.yes
								? buttonText?.yes
								: t("dialog.confirm.button_yes")}
						</button>
					</div>
				</DialogWrapper>
			)}
		</>
	)
}
