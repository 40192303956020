import { ReactElement, useEffect, useState } from "react"
import { DateTime, Duration } from "@/lib/dates"
import SunCalc from "suncalc"
import { useTrans } from "@/i18n"
import { floorTo } from "@/lib/math"

export function calculate(
	langKey: string,
	diff: Duration,
): { langKey: string; count: number; hours: number; minutes: number } {
	let hours = diff.hours
	let minutes = floorTo(diff.minutes, 15)

	let count = hours

	if (hours === 0) {
		langKey += "_only_minutes"
		count = minutes
	} else if (minutes === 0) {
		langKey += "_only_hours"
		count = hours
	}

	return {
		langKey,
		count,
		hours,
		minutes,
	}
}

export function dateDiff(from: Date, to: Date): Duration {
	return DateTime.fromJSDate(from).diff(DateTime.fromJSDate(to), [
		"hours",
		"minutes",
	])
}

function useCurrentDate(interval = 15 * 60 * 1000): Date {
	const [now, set] = useState(new Date())

	useEffect(() => {
		const work = () => {
			set(new Date())
		}

		const timeout = setInterval(work, interval)
		return () => {
			clearInterval(timeout)
		}
	}, [interval])

	return now
}

function WelcomeText(): ReactElement | null {
	const t = useTrans()
	const now = useCurrentDate()
	const sunData = SunCalc.getTimes(now, 52.1326, 5.2913)

	const render = (
		langKey: string,
		count: number,
		hours: number,
		minutes: number,
	) => {
		return (
			<>
				{t(langKey, {
					hours: Math.floor(hours),
					minutes: Math.floor(minutes),
					count,
				})}
			</>
		)
	}

	if (now > sunData.sunset) {
		const tomorrowDate = new Date(now)
		tomorrowDate.setDate(now.getDate() + 1)

		const tomorrowSunData = SunCalc.getTimes(tomorrowDate, 52.1326, 5.2913)

		const { langKey, count, hours, minutes } = calculate(
			"common.welcome_message.next_sunrise",
			dateDiff(tomorrowSunData.sunrise, now),
		)

		return render(langKey, count, hours, minutes)
	}

	if (now >= sunData.sunrise) {
		const { langKey, count, hours, minutes } = calculate(
			"common.welcome_message.last_sunrise",
			dateDiff(now, sunData.sunrise),
		)

		return render(langKey, count, hours, minutes)
	}

	if (now < sunData.sunrise) {
		const { langKey, count, hours, minutes } = calculate(
			"common.welcome_message.next_sunrise",
			dateDiff(sunData.sunrise, now),
		)

		return render(langKey, count, hours, minutes)
	}

	return null
}

export default WelcomeText
