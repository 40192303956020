import { Suspense, useState } from "react"
import { Link } from "react-router-dom"
import { Trans, useTrans } from "@/i18n"
import { VERIFICATION_CODE_LENGTH } from "./2FA/TwoFactorAuthSettings"
import { UserMFAMethod } from "@/api/rest/generated/@types"
import {
	apiAuthCodeRequestCreate,
	apiAuthCodesRegenerateCreate,
	useApiAuthMfaUserActiveMethodsList,
} from "@/api/rest/generated/api/api"
import { useToasts } from "@/context/toasts"
import { Pages } from "@/misc/pages"

// UI
import { CardBody, CardWrapper } from "@/components/Card"
import { FormGroup } from "@/components/form-controls/FormGroup"
import { Heading } from "@/components/Typography"
import { Button } from "@/components/Button"
import { PromptDialog } from "@/components/dialogs/PromptDialog"
import { TextArea } from "@/components/form-controls"

// Types
import { MethodEnum } from "@/api/rest/generated/@types"

export const ProfileSecurityBackupCodes = () => {
	const t = useTrans("profile")
	const transCommon = useTrans("common")

	const [dialogCallbackProps, setDialogCallbackProps] = useState<{
		methodName?: MethodEnum
	}>({})
	const [promptMessage, setPromptMessage] = useState<string>("")
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
	const [isInvalidAuthCode, setIsInvalidAuthCode] = useState<boolean>(false)
	const [backupCodes, setBackupCodes] = useState<string[]>()

	const toasts = useToasts()

	const { data: userMfaMethods } = useApiAuthMfaUserActiveMethodsList()

	const handleRegenerateBackupCodes = async (
		code: string,
		methodName: MethodEnum,
	) => {
		try {
			const { backup_codes } = await apiAuthCodesRegenerateCreate(
				methodName,
				{
					code: code,
				},
			)

			setBackupCodes(backup_codes)

			toasts.addToast({
				variant: "success",
				id: `backup-codes-regenerate-success-${Date.now()}`,
				text: t(`profile.security.backup_codes.success_message`),
			})

			setPromptMessage("")
			setIsDialogOpen(false)
		} catch (e) {
			toasts.addToast({
				variant: "alert",
				id: `regenerateBackupCodes`,
				text: transCommon(`common.request_failed`),
			})

			setIsInvalidAuthCode(true)

			return
		}
	}

	const regenerateBackupCodesRequest = async (
		methodName: UserMFAMethod["name"],
	) => {
		try {
			await apiAuthCodeRequestCreate({ method: methodName })
		} catch (e) {
			toasts.addToast({
				variant: "alert",
				id: `regenerateBackupCodesRequest`,
				text: transCommon(`common.request_failed`),
			})

			return
		}
	}

	return (
		<>
			<PromptDialog
				isOpen={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				title={t(
					"profile.security.two_factor_auth.state.prompt.enter_received_code.header",
				)}
				prompt={promptMessage}
				onConfirm={handleRegenerateBackupCodes}
				inputProps={{
					type: "input",
					props: {
						maxLength: VERIFICATION_CODE_LENGTH,
						type: "text",
						hasError: isInvalidAuthCode,
						placeholder: "000000",
					},
				}}
				{...dialogCallbackProps}
			/>
			<CardWrapper>
				<CardBody>
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("profile.security.backup_codes.heading")}
					</Heading>
					<Suspense fallback={null}>
						<FormGroup
							heading={
								<p className="whitespace-pre-wrap">
									<Trans
										ns="profile"
										i18nKey={
											"profile.security.backup_codes.copy"
										}
										components={{
											Link: (
												<Link
													className="font-bold"
													to={
														Pages.SettingsProfileSecurity
													}
												></Link>
											),
										}}
									/>
								</p>
							}
						>
							<div className="space-y-8">
								<div className="xl:w-3/4">
									<div className="mb-4 mt-1 text-sm text-gray-500 md:w-8/12">
										{t(
											"profile.security.backup_codes.intro",
										)}
									</div>
									<div className="mb-4">
										<div className="flex max-w-[10rem] justify-between">
											{userMfaMethods?.map((method) => {
												return (
													<Button
														size="small"
														disabled={
															!!backupCodes?.length
														}
														key={method.name}
														onClick={() => {
															setDialogCallbackProps(
																{
																	methodName:
																		method.name as MethodEnum,
																},
															)
															regenerateBackupCodesRequest(
																method.name,
															)

															const methodNameTranslated =
																t(
																	`profile.security.mfa_method.${method.name}`,
																)
															setPromptMessage(
																t(
																	"profile.security.two_factor_auth.state.prompt.enter_received_code.prompt",
																	{
																		method: methodNameTranslated,
																	},
																),
															)

															setIsDialogOpen(
																true,
															)
														}}
													>
														{t(
															`profile.security.mfa_method.${method.name}`,
														)}
													</Button>
												)
											})}
										</div>
									</div>
									{backupCodes?.length && (
										<>
											<TextArea
												id="backup_codes"
												rows={backupCodes?.length}
												className="mb-2 block w-full max-w-xs resize-none"
												value={backupCodes?.join("\n")}
												readOnly
											/>
											<p className="text-xs text-gray-500 md:w-8/12">
												{t(
													"profile.security.backup_codes.finish.helper",
												)}
											</p>
										</>
									)}
								</div>
							</div>
						</FormGroup>
					</Suspense>
				</CardBody>
			</CardWrapper>
		</>
	)
}
