import { useRef, useEffect } from "react"

// GraphQL
import { NearbyProjectType } from "@/api/graphql"

// Maps
import { Wrapper } from "@googlemaps/react-wrapper"

// Images
import ImageMapsMarkerInstallation from "../../../assets/icons/maps_marker_installation.svg"

// Env variables
const GOOGLE_MAPS_API_KEY: string =
	process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""

interface MapsProps {
	center: google.maps.LatLngLiteral | undefined
	zoom: number
	points?: Array<NearbyProjectType | undefined | null> | undefined | null
}

export function FinanceProjectsMapsOverview({
	center,
	zoom,
	points = [],
}: MapsProps) {
	return (
		<Wrapper apiKey={GOOGLE_MAPS_API_KEY}>
			<MapsComponent center={center} zoom={zoom} points={points} />
		</Wrapper>
	)
}

function MapsComponent({ center, zoom, points = [] }: MapsProps) {
	const ref = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (ref.current && center) {
			const map = new window.google.maps.Map(ref.current, {
				center,
				zoom,
				gestureHandling: "none", // Disable all gestures, including scrolling
				mapTypeControl: false,
				zoomControl: false,
				fullscreenControl: false,
				streetViewControl: false,
				styles: [
					// Copied style directly from Angular zonnepanelendelen.nl/dashboard
					{
						featureType: "administrative",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#444444",
							},
						],
					},
					{
						featureType: "landscape",
						elementType: "all",
						stylers: [
							{
								color: "#f2f2f2",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road",
						elementType: "all",
						stylers: [
							{
								saturation: -100,
							},
							{
								lightness: 45,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "all",
						stylers: [
							{
								visibility: "simplified",
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "transit",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "water",
						elementType: "all",
						stylers: [
							{
								color: "#d3d3d3",
							},
							{
								visibility: "on",
							},
						],
					},
				],
				mapTypeId: google.maps.MapTypeId.ROADMAP,
			})

			// Show all points of interest on the map
			points?.forEach((point) => {
				if (point?.latitude && point?.longitude) {
					new google.maps.Marker({
						position: {
							lat: point.latitude,
							lng: point.longitude,
						} as google.maps.LatLngLiteral,
						map: map,
						icon: {
							url: ImageMapsMarkerInstallation,
							scaledSize: new google.maps.Size(10, 10),
						},
						clickable: true,
					})
				}
			})
		}
	}, [zoom, center, points])

	return <div ref={ref} id="map" className="h-full w-full" />
}
