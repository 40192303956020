import {
	useInvestmentTestContextQuery,
	CoreInvestmentTestCategoryChoices,
	CoreInvestmentTestExperienceChoices,
	RiskKycStateChoices,
} from "@/api/graphql"

/**
 * useInvestmentTests
 * @returns
 */
export function useInvestmentTests() {
	const { data, refetch } = useInvestmentTestContextQuery()

	// Hide badges when the test is already done
	const investorProfileExperience = data?.me?.investment_tests?.filter(
		(result) =>
			result.category === CoreInvestmentTestCategoryChoices.Experience,
	)[0]
	let investorTestQuestions = data?.me?.investment_tests?.filter(
		(result) =>
			result.category === CoreInvestmentTestCategoryChoices.Questions,
	)[0]
	let investorTestRisk = data?.me?.investment_tests?.filter(
		(result) =>
			result.category ===
			CoreInvestmentTestCategoryChoices.LossCalculator,
	)[0]
	const is_kyc_verified = (data?.me?.kyc?.state ===
		RiskKycStateChoices.VerificationSuccess ||
		data?.me?.kyc?.state === RiskKycStateChoices.Uninitiated) as Boolean

	// How many required actions are there left?
	let amountOfRequiredActionsLeft = 0
	let requiredActions = {
		KYC: !Boolean(is_kyc_verified),
		EXPERIENCE: !Boolean(investorProfileExperience),
		QUESTIONS:
			investorProfileExperience?.experience ===
			CoreInvestmentTestExperienceChoices.Novice
				? !Boolean(investorTestQuestions)
				: false,
		LOSS_CALCULATOR:
			investorProfileExperience?.experience ===
			CoreInvestmentTestExperienceChoices.Novice
				? !Boolean(investorTestRisk)
				: false,
	}

	// If any of these require action, set badge
	Object.keys(requiredActions).forEach((key) => {
		if (
			requiredActions[key as CoreInvestmentTestCategoryChoices] === true
		) {
			amountOfRequiredActionsLeft++
		}
	})

	return {
		refetch,
		investorProfileExperience,
		investorTestQuestions,
		investorTestRisk,
		amountOfRequiredActionsLeft,
	}
}
