/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * ZonHub
 * Project management for Solar
 * OpenAPI spec version: 1.0.0
 */
import type {
	MutationFunction,
	UseMutationOptions,
} from "@tanstack/react-query"
import { useMutation } from "@tanstack/react-query"
import { apiWithTokenAuth } from "../../api"
import type { KycVerificationDocument } from "../@types/kycVerificationDocument"
import type { KYCVerificationDocumentResponse } from "../@types/kYCVerificationDocumentResponse"

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
	T,
>() => T extends Y ? 1 : 2
	? A
	: B

type WritableKeys<T> = {
	[P in keyof T]-?: IfEquals<
		{ [Q in P]: T[P] },
		{ -readonly [Q in P]: T[P] },
		P
	>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
	k: infer I,
) => void
	? I
	: never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
	? {
			[P in keyof Writable<T>]: T[P] extends object
				? NonReadonly<NonNullable<T[P]>>
				: T[P]
	  }
	: DistributeReadOnlyOverUnions<T>

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const usersKycDocumentsUploadCreate = (
	userUuid: string,
	kycVerificationDocument: KycVerificationDocument,
) => {
	const formData = new FormData()
	formData.append("full_first_name", kycVerificationDocument.full_first_name)
	formData.append("document_type", kycVerificationDocument.document_type)
	formData.append("expiry_date", kycVerificationDocument.expiry_date)
	formData.append("document", kycVerificationDocument.document)
	if (kycVerificationDocument.document_back !== undefined) {
		formData.append("document_back", kycVerificationDocument.document_back)
	}

	return apiWithTokenAuth<KYCVerificationDocumentResponse>({
		url: `/users/${userUuid}/kyc_documents_upload/`,
		method: "POST",
		headers: { "Content-Type": "multipart/form-data" },
		data: formData,
	})
}

export const getUsersKycDocumentsUploadCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
		TError,
		{ userUuid: string; data: NonReadonly<KycVerificationDocument> },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
	TError,
	{ userUuid: string; data: NonReadonly<KycVerificationDocument> },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
		{ userUuid: string; data: NonReadonly<KycVerificationDocument> }
	> = (props) => {
		const { userUuid, data } = props ?? {}

		return usersKycDocumentsUploadCreate(userUuid, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type UsersKycDocumentsUploadCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>
>
export type UsersKycDocumentsUploadCreateMutationBody =
	NonReadonly<KycVerificationDocument>
export type UsersKycDocumentsUploadCreateMutationError = unknown

export const useUsersKycDocumentsUploadCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
		TError,
		{ userUuid: string; data: NonReadonly<KycVerificationDocument> },
		TContext
	>
}) => {
	const mutationOptions =
		getUsersKycDocumentsUploadCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
