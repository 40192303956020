import { useContext } from "react"

// Navigation
import { matchPath, useLocation } from "react-router-dom"
import { Pages } from "@/misc/pages"
import { Link } from "@/components/Anchor"

// Translation
import { useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/classnames"

// Context
import { NextLayoutContext } from "@/layouts/NextLayout"
import { useInvestmentTests } from "@/context/investmentTests"
import { useFeatureFlags } from "@/context/user"

/**
 * InvestmentsSubMenu
 * @returns
 */
export function InvestmentsSubMenu() {
	const { setMobileMenuOpen } = useContext(NextLayoutContext)
	const location = useLocation()
	const { amountOfRequiredActionsLeft } = useInvestmentTests()
	const { getFeatureFlagValue } = useFeatureFlags()

	/**
	 * The Pages.InvestmentsProjectsDashboard page is a special case because it has nested routes in the url
	 * that need to be rendered on the same level and not both be marked as active
	 *
	 * @returns
	 */
	function isProjectSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Pages.InvestmentsProjectsDashboard) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investments/projects/:id/:tab",
				end: true,
			},
			location.pathname,
		)

		// Now exclude the FiscalOverview and Production pages from being marked as active
		return (
			matchesPath !== null &&
			!location.pathname.includes(Pages.ProvisionalInvestments) &&
			!location.pathname.includes(
				Pages.InvestmentsProjectsFiscalOverview,
			) &&
			!location.pathname.includes(Pages.InvestmentsProjectsProduction)
		)
	}

	/**
	 * Test if location path is any child page of the investor profile
	 * @returns
	 */
	function isInvestorProfileSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Pages.InvestorProfile) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investor/:tab",
				end: true,
			},
			location.pathname,
		)
		return matchesPath !== null
	}

	const transCommon = useTrans("common")

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			<Link
				className={classNames(
					isProjectSpecificSubPath() && "active-navigation-item",
					"block truncate text-sm hover:text-gray-500",
				)}
				href={Pages.InvestmentsProjectsDashboard}
				onClick={closeMenu}
			>
				{transCommon("common.navigation.investments.projects.link")}
			</Link>
			{getFeatureFlagValue("ENABLE_MENU_ITEM_ALL_PAYMENTS") && (
				<div>All Payments</div>
			)}
			<Link
				className={({ isActive }) =>
					classNames(
						isActive && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)
				}
				href={Pages.ProvisionalInvestments}
				onClick={closeMenu}
			>
				{transCommon("common.nav.provisional_investments")}
			</Link>

			<Link
				className={({ isActive }) =>
					classNames(
						isActive && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)
				}
				href={Pages.InvestmentsProjectsFiscalOverview}
				onClick={closeMenu}
			>
				{transCommon("common.nav.fiscal")}
			</Link>
			<Link
				className={({ isActive }) =>
					classNames(
						isActive && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)
				}
				href={Pages.InvestmentsProjectsProduction}
				onClick={closeMenu}
			>
				{transCommon("common.nav.total_production")}
			</Link>
			<Link
				className="flex w-full items-center focus:no-underline"
				href={Pages.InvestorProfile}
				onClick={closeMenu}
			>
				<>
					<span
						className={classNames(
							isInvestorProfileSpecificSubPath() &&
								"active-navigation-item",
							"truncate text-sm hover:text-gray-500",
						)}
					>
						{transCommon(
							"common.navigation.investor-profile.title",
						)}
					</span>

					{/** Show badge with number */}
					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<span className="ml-1 block h-4 w-4 items-center rounded-full bg-red-600 text-center text-xs text-white">
								{" "}
								{amountOfRequiredActionsLeft}
							</span>
						)}
				</>
			</Link>
		</nav>
	)
}
