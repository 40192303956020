// Features
import { initialState as initialAuthState } from "./features/authSlice"
import { initialState as initialInvestmentsOverviewState } from "./features/investmentsOverviewSlice"
import { initialState as initialUserPreferencesState } from "./features/userPreferencesSlice"

// Export migrations
export const migrations = {
	1: (state) => {
		return state
	},
	2: (state) => {
		return {
			...state,
			auth: {
				...initialAuthState,
				...state.auth,
			},
			investmentsOverview: {
				...initialInvestmentsOverviewState,
				...state.investmentsOverview,
			},
			userPreferences: {
				...initialUserPreferencesState,
				...state.userPreferences,
			},
		}
	},
}
