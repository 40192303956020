import { QueryFunctionContext } from "@tanstack/react-query"
import { store } from "@/state/store"
import { get } from "@/lib/cookies"
import { NetworkError } from "../rest/network-errors"

// Environment variables
const API_URL = process.env.REACT_APP_API_URL
const AUTH_LOGIN_URL = `${API_URL}account/login/` // We define this in its own file because we can not import from context/auth

// Sentry
import { addBreadcrumb, captureMessage } from "@sentry/react"

async function api<Data, Variables>(
	query: string,
	variables?: Variables,
	signal?: AbortSignal,
) {
	// Set headers
	const headers = new Headers()
	headers.set("Content-Type", "application/json")
	headers.set("Accept", "application/json")
	headers.set("X-CSRFToken", get("csrftoken") || "") // TODO: This hack is here to allow cookies from mijnstroom > zonhub to work

	// Set auth token
	const authToken = store.getState().auth.token
	if (authToken !== null) {
		headers.set("Authorization", `Token ${authToken}`)
	}

	// Add Sentry breadcrumb
	addBreadcrumb({
		category: "graphql",
		message: "GraphQL query",
		data: {
			query,
			variables,
		},
	})

	// Fetch response
	const res = await fetch(process.env.REACT_APP_GRAPHQL_URL as string, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers,
		signal,
		body: JSON.stringify({
			query,
			variables,
		}),
	})

	// Parse JSON
	const json = await res.json()

	/**
	 * UserNotLoggedIn is returned when the user is not authenticated.
	 * Just throw the error it will be handled by GlobalErrorBoundary > ErrorBoundary
	 */
	handleErrorExtensions(json)

	// Return json
	return json.data as Data
}

/**
 * Handle Error Extensions globally
 *
 * @param json
 */
export function handleErrorExtensions(json: any) {
	if (json?.errors) {
		for (const error of json.errors) {
			// If we find a AUTHENTICATION_ERROR
			if (
				String(error?.extensions?.type).includes(
					NetworkError.AUTHENTICATION_ERROR,
				)
			) {
				// Only redirect to login if we are not already on login!
				if (window.location.pathname !== "/login") {
					window.location.href = AUTH_LOGIN_URL
				}
			} else {
				// All other errors, log and report
				console.error(error.message)

				// Capture message in Sentry
				captureMessage(error.message, {
					level: "error",
					extra: {
						locations: error?.locations,
						path: error?.path,
					},
				})
			}
		}
	}
}

export function mutationFetcher<Data, Variables>(
	query: string,
	variables?: Variables,
) {
	return function mutationFetcherInner() {
		return api<Data, Variables>(query, variables)
	}
}

export function queryFetcher<Data, Variables>(
	query: string,
	variables?: Variables,
) {
	return function queryFetcherInner(
		context?: QueryFunctionContext<
			string | Array<string> | any | undefined
		>,
	) {
		return api<Data, Variables>(query, variables, context?.signal)
	}
}
