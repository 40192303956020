/**
 * Info
 * @returns
 */
export function Info() {
	return (
		<div className="grid grid-cols-[auto_1fr] gap-5 p-5">
			{/* https://vercel.com/docs/projects/environment-variables/system-environment-variables */}
			{[
				// The following prefixed Environment Variables will be available during the Build Step, based on the Project's selected Framework Preset.
				"REACT_APP_VERCEL_ENV", //	The Environment that the app is deployed and running on. The value can be either production, preview, or development.

				/*	The domain name of the generated deployment URL. Example: *.vercel.app. The value does not include the protocol schemehttps://. 
				NOTE: This Variable cannot be used in conjunction with Standard Deployment Protection. See Migrating to Standard Protection. */
				"REACT_APP_VERCEL_URL",
				"REACT_APP_VERCEL_BRANCH_URL", // The domain name of the generated Git branch URL. Example: *-git-*.vercel.app. The value does not include the protocol scheme https://.
				"REACT_APP_VERCEL_AUTOMATION_BYPASS_SECRET", //	The Protection Bypass for Automation value, if the secret has been generated in the project's Deployment Protection settings.
				"REACT_APP_VERCEL_GIT_PROVIDER", //	The Git Provider the deployment is triggered from. Example: github.
				"REACT_APP_VERCEL_GIT_REPO_SLUG", //	The origin repository the deployment is triggered from. Example: my-site.
				"REACT_APP_VERCEL_GIT_REPO_OWNER", //	The account that owns the repository the deployment is triggered from. Example: acme.
				"REACT_APP_VERCEL_GIT_REPO_ID", //	The ID of the repository the deployment is triggered from. Example: 117716146.
				"REACT_APP_VERCEL_GIT_COMMIT_REF", //	The git branch of the commit the deployment was triggered by. Example: improve-about-page.
				"REACT_APP_VERCEL_GIT_COMMIT_SHA", //	The git SHA of the commit the deployment was triggered by. Example: fa1eade47b73733d6312d5abfad33ce9e4068081.
				"REACT_APP_VERCEL_GIT_COMMIT_MESSAGE", //	The message attached to the commit the deployment was triggered by. Example: Update about page.
				"REACT_APP_VERCEL_GIT_COMMIT_AUTHOR_LOGIN", //	The username attached to the author of the commit that the project was deployed by. Example: johndoe.
				"REACT_APP_VERCEL_GIT_COMMIT_AUTHOR_NAME", //	The name attached to the author of the commit that the project was deployed by. Example: John Doe.
				"REACT_APP_VERCEL_GIT_PULL_REQUEST_ID", //	The pull request id the deployment was triggered by. If a deployment is created on a branch before a pull request is made, this value will be an empty string. Example: 23.
			].map((id) => {
				return (
					<>
						<h2>{id}</h2>
						<p>{process.env[id] || "n/a"}</p>
					</>
				)
			})}
		</div>
	)
}
