/// <reference types="../../../../types/react-table-config" />

import { AnimatePresence, motion } from "@/lib/animations"
import {
	Fragment,
	memo,
	MouseEventHandler,
	ReactNode,
	useCallback,
	useMemo,
	useState,
} from "react"
import { FiChevronDown, FiTrash2 } from "react-icons/fi"

// Tables
import {
	CellProps,
	ColumnInstance,
	useExpanded,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { TableWarning } from "@/components/table-controls/TableWarning"
import { TablePagination } from "@/components/table-controls/TablePagination"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// Context
import {
	PaymentPatchesActionType,
	usePaymentPatchList,
} from "@/context/paymentPatches"

import { normaliseString } from "@/lib/js"

// Hooks
import { useCurrentPaymentDetail } from "../PaymentDetailEnergySupplier"

// UI
import { classNames } from "@/lib/classnames"
import { Heading } from "@/components/Typography"
import { Card, CardBody } from "@/components/Card"
import { SearchInput } from "@/components/form-controls/Input"
import { PaymentItem } from "@/components/forms/PaymentItem"

// GraphQL
import {
	PaymentEntryEnergySupplierFragment,
	PaymentEntryType,
} from "@/api/graphql"

type Data = PaymentEntryType

const cellClassNameMap: { [key: string]: string } = {
	expander: "w-10",
}

export function generateTableRowId(
	row: Pick<Data, "name" | "supplier_account">,
	suffix = "tablerow",
) {
	return `${suffix}-${row.supplier_account}-${normaliseString(
		row.name ?? "",
	)}`
}

export const PaymentOverviewForEnergySupplier = memo(() => {
	const { formatCurrency, formatNumber } = useLang()

	// Translations
	const t = useTrans("payments")
	const transCommon = useTrans("common")

	const [skipPageReset, setSkipPageReset] = useState(false)
	const { patchList, onPatchCell } = usePaymentPatchList()

	const onPatchCellProxy: typeof onPatchCell = useCallback(
		async (args) => {
			// We also turn on the flag to not reset the page
			setSkipPageReset(true)

			onPatchCell(args)

			// Enable flag to be able to reset page again
			setSkipPageReset(false)
		},
		[onPatchCell],
	)

	const { data } = useCurrentPaymentDetail()

	const columns = useMemo(() => {
		const cols: UseTableOptions<Data>["columns"] = [
			{
				id: "expander",
				Header: ({ column }) => (
					<td {...column.getHeaderProps({ className: "w-10" })} />
				),
				Cell: ({ cell, row }: CellProps<Data, unknown>) => {
					const originalData = cell.row
						.original as PaymentEntryEnergySupplierFragment
					const paymententryId = Number(originalData.id)
					const rowData = patchList.get(paymententryId)

					return (
						<td
							{...cell.getCellProps({
								className: classNames(
									rowData?.type ===
										PaymentPatchesActionType.Delete
										? "bg-red-200 hover:bg-red-300"
										: row.isExpanded ||
										  rowData?.type ===
												PaymentPatchesActionType.Edit
										? "hover:bg-yellow-300"
										: "hover:bg-gray-200",
									"text-gray-500 hover:text-gray-700",
								),
							})}
						>
							<FiChevronDown
								className={classNames(
									"mx-auto block transform transition",
									row.isExpanded && "rotate-180",
								)}
							/>
						</td>
					)
				},
			},
			{
				accessor: "share_count",
				Header: ({ column }) => (
					<TableHeading>
						{t("payments.overview.header.number_of_shares")}
					</TableHeading>
				),
			},
			{
				accessor: "name",
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("payments.overview.header.name")}
					</DefaultTableHeader>
				),
			},
			{
				accessor: "supplier_account",
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("payments.overview.header.client_number")}
					</DefaultTableHeader>
				),
			},
			{
				accessor: "production",
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("payments.overview.header.energy_generated")}
					</DefaultTableHeader>
				),
				Cell: ({ value, cell }) => (
					<TableDataCell {...cell.getCellProps()}>
						{value ? formatNumber(value) : 0} kWh
					</TableDataCell>
				),
			},
			{
				accessor: "cost",
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("payments.overview.header.interest")}
					</DefaultTableHeader>
				),
				Cell: ({ value, cell }) => (
					<TableDataCell {...cell.getCellProps()}>
						{formatCurrency(value ?? 0)}
					</TableDataCell>
				),
			},
			{
				id: "bonus",
				accessor: "energy_supplier_bonus",
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("payments.overview.header.bonus")}
					</DefaultTableHeader>
				),
				Cell: ({ value, cell }) => (
					<TableDataCell {...cell.getCellProps()}>
						{value ? formatCurrency(value) : 0}
					</TableDataCell>
				),
			},

			{
				id: "delete",
				Header: <td className="w-12 text-center" />,
				Cell: ({ cell }: CellProps<Data, number>) => {
					const originalData = cell.row
						.original as PaymentEntryEnergySupplierFragment
					const paymententryId = Number(originalData.id)
					const rowData = patchList.get(paymententryId)

					const onClick: MouseEventHandler<HTMLTableCellElement> = (
						e,
					) => {
						e.preventDefault()
						// if it has already been flagged for deletion
						// then just clear the row
						if (rowData?.type === PaymentPatchesActionType.Delete) {
							onPatchCellProxy({
								paymententryId,
								type: PaymentPatchesActionType.Clear,
							})
						} else {
							onPatchCellProxy({
								paymententryId,
								type: PaymentPatchesActionType.Delete,
							})
						}
					}

					return (
						<TableDataCell
							{...cell.getCellProps({
								className: classNames(
									"group text-center !p-0",
									rowData?.type ===
										PaymentPatchesActionType.Delete
										? "bg-red-200 hover:bg-red-300"
										: "hover:bg-red-200",
								),
							})}
							onMouseDown={onClick}
							onClick={onClick}
						>
							<FiTrash2
								className="mx-auto block group-hover:text-red-700"
								size={16}
							/>
						</TableDataCell>
					)
				},
			},
		]

		return cols.filter(Boolean)
	}, [t, formatNumber, formatCurrency, patchList, onPatchCellProxy])

	// Table
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		rows,
		setPageSize,
		state: { pageSize, pageIndex },
		prepareRow,
		gotoPage,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		visibleColumns,
	} = useTable(
		{
			// @ts-ignore
			columns,
			data: useMemo(
				() =>
					data?.interest_payments?.results?.[0]
						?.payment_entries_with_supplier ?? [],
				[data?.interest_payments],
			),
			onPatchCell: onPatchCellProxy,
			patchList, // @ts-ignore
			defaultColumn,
			autoResetPage: !skipPageReset,
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
	)

	// Function to collapse all rows. We can not use toggleAllRowsExpanded for this.
	const collapseAllRows = () => {
		for (const row of rows) {
			if (row.isExpanded) {
				if (typeof row.toggleRowExpanded === "function") {
					row.toggleRowExpanded(false)
				}
			}
		}
	}

	return (
		<>
			<CardBody>
				<div className="flex flex-1 flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0">
					<Heading as="h2" styleAs="h5" className="sm:truncate">
						{t("payments.overview_energy_supplier.title", {
							energySupplier:
								data?.interest_payments?.results?.[0]?.project
									?.supplier?.name,
						})}
					</Heading>
					<div className="sm:order-3 sm:ml-auto">
						<SearchInput
							onChange={(evt) =>
								setGlobalFilter(evt.currentTarget.value)
							}
							label={t("payments.search.placeholder")}
							className="md:width-auto min-w-full"
						/>
					</div>
				</div>
			</CardBody>
			<div className="md:hidden">
				<TableWarning
					message={transCommon("common.table.mobile_warning.copy")}
				/>
			</div>
			<Table
				{...getTableProps({
					className: "min-w-[64rem] lg:min-w-0",
				})}
				data-testid="tablebody"
			>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								const { key } = column.getHeaderProps()

								return (
									<Fragment key={key}>
										{column.render("Header")}
									</Fragment>
								)
							})}
						</tr>
					))}
				</TableHead>
				<TableBody
					{...getTableBodyProps()}
					data-testid="tablebody-overview"
					data-pageindex={pageIndex}
				>
					{page.map((row) => {
						prepareRow(row)

						const { key, ...rest } = row.getRowProps({
							...row.getToggleRowExpandedProps(),
						})

						const isOdd = row.index % 2 === 0

						// Get row data
						const originalData =
							row.original as PaymentEntryEnergySupplierFragment
						const paymententryId = Number(originalData.id)
						const patchData = patchList.get(paymententryId)

						let className = ""
						if (
							patchData?.type === PaymentPatchesActionType.Delete
						) {
							className =
								"bg-red-100 text-gray-900 hover:bg-red-200"
						} else if (
							patchData?.type === PaymentPatchesActionType.Edit ||
							row.isExpanded
						) {
							className =
								"bg-yellow-100 hover:bg-yellow-200 text-gray-900"
						}

						return (
							<Fragment key={row.id}>
								<TableRowCell
									{...rest}
									isOdd={isOdd}
									isInvalid={
										patchData?.type ===
										PaymentPatchesActionType.Delete
									}
									className={classNames(className)}
									data-testid={generateTableRowId(
										row.original,
									)}
								>
									{row.cells.map((cell) => {
										const { key } = cell.getCellProps({
											className:
												cellClassNameMap[
													cell.column.id
												],
										})
										return (
											<Fragment key={key}>
												{cell.render("Cell")}
											</Fragment>
										)
									})}
								</TableRowCell>
								<TableRowCell
									isOdd
									withHover={false}
									className="p-0"
									data-testid={generateTableRowId(
										row.original,
										"paymentitem-container",
									)}
								>
									<td
										colSpan={visibleColumns.length}
										className="p-0"
									>
										<AnimatePresence
											key={key}
											// we use initial false, as if a row is expanded
											// and you navigate from one page to the next, and this next
											// page has a row that is expanded, it will animate in and we do not want that
											initial={false}
										>
											{row.isExpanded ? (
												<motion.div
													initial={{ height: 0 }}
													animate={{ height: "auto" }}
													exit={{ height: 0 }}
													transition={{
														ease: "easeOut",
													}}
													className="flex overflow-hidden"
													data-testid={`tablerow-expanded-${row.original.supplier_account}`}
												>
													<PaymentItemContainer // @ts-ignore
														values={row.original}
														index={row.index}
														collapseAllRows={() =>
															collapseAllRows()
														}
													/>
												</motion.div>
											) : null}
										</AnimatePresence>
									</td>
								</TableRowCell>
							</Fragment>
						)
					})}
					{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
					{page.length < pageSize && pageIndex !== 0 ? (
						<>
							{Array(Math.max(pageSize - page.length, 1))
								.fill(true)
								.map((_, index) => (
									<TableRowCell
										key={index}
										withHover={false}
										isOdd={index % 2 === 0}
									>
										<TableDataCell colSpan={columns.length}>
											<span className="dummy-text" />
										</TableDataCell>
									</TableRowCell>
								))}
						</>
					) : null}
				</TableBody>
			</Table>
			{page.length === 0 && (
				<CardBody>
					<TableEmptyState>
						{t("payments.overview_energy_supplier.empty_state")}
					</TableEmptyState>
				</CardBody>
			)}
			{rows.length !== 0 && (
				<CardBody>
					<TablePagination
						amountOfRows={rows.length}
						pageIndex={pageIndex}
						pageSize={pageSize}
						previousPage={previousPage}
						gotoPage={gotoPage}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						nextPage={nextPage}
						setPageSize={setPageSize}
						totalNumberOfItems={
							data?.interest_payments?.results?.[0]
								?.payment_entries_with_supplier?.length
						}
					/>
				</CardBody>
			)}
		</>
	)
})

/**
 * Wrap paymentItem inside styled container
 * @param param0
 * @returns
 */
const PaymentItemContainer = ({
	values,
	index,
	collapseAllRows,
}: {
	index: number
	values: Data
	collapseAllRows: Function
}) => {
	return (
		<div className="px-10 pb-7 pt-5">
			<Card data-testid={generateTableRowId(values, "paymentitem")}>
				<PaymentItem
					values={values}
					paymententryId={index}
					collapseAllRows={collapseAllRows}
				/>
			</Card>
		</div>
	)
}

const DefaultTableHeader = ({
	column,
	children,
}: {
	column: ColumnInstance<Data>
	children: ReactNode
}) => {
	return <TableHeading>{children}</TableHeading>
}

const defaultColumn = {
	Cell: ({ value }: CellProps<Data, string | number>) => (
		<TableDataCell>{value}</TableDataCell>
	),
}
