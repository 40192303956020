import { Helmet } from "react-helmet-async"
import { Container } from "@/components/Container"
import { PageHeaderWithBar } from "@/components/PageHeader"
import { RecentActivity } from "@/components/RecentActivity"
import { useTrans } from "@/i18n"

export const Recent = () => {
	const t = useTrans("recent")

	return (
		<>
			<Helmet>
				<title>{t("recent.title")}</title>
			</Helmet>
			<PageHeaderWithBar
				heading={t("recent.header")}
				content={
					<Container>
						<RecentActivity />
					</Container>
				}
			/>
		</>
	)
}
