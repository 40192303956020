// React
import { useEffect, useState } from "react"

import { DateTime } from "@/lib/dates"
import { motion } from "@/lib/animations"
import { Helmet } from "react-helmet-async"
import { emptyValue } from "@/misc/helpers"

// UI
import { Card, CardBody, CardWrapper } from "@/components/Card"
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { Heading } from "@/components/Typography"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"

// Translations
import { Trans, useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// GraphQL
import {
	useInterestPaymentsQuery,
	ProjectHorizonCategoryEnum,
	Maybe,
	PaymentStateEnum,
} from "@/api/graphql"

// Hooks
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"
import { useCurrentProject } from "../hooks"

// Dates
import { dateFormat } from "@/misc/constants"

// Env variables
const EXTERNAL_PLATFORM_PROJECT_STATISTICS =
	process.env.REACT_APP_EXTERNAL_PLATFORM_PROJECT_STATISTICS

export const ProjectRevenue = () => {
	const { formatNumber, formatCurrency } = useLang()
	const t = useTrans("project")
	const id = useCurrentProjectId()

	// State
	const [
		interestPaymentHasUnpaidPayments,
		setInterestPaymentHasUnpaidPayments,
	] = useState<boolean>(false)

	// Queries
	const { data: currentProject } = useCurrentProject()
	const { data } = useInterestPaymentsQuery({
		id: String(id),
		project_id: String(id),
	})

	// Determine if we have some invalid payments
	useEffect(() => {
		const hasUnpaidPayments = data?.me?.interest_payments?.some(
			(interestPayment) =>
				interestPayment?.payment_entries?.some(
					(element) => element?.state !== PaymentStateEnum.Done,
				),
		)

		if (hasUnpaidPayments) {
			setInterestPaymentHasUnpaidPayments(true)
		}
	}, [data?.me?.interest_payments])

	return (
		<>
			<Helmet>
				<title>
					{t("project.revenue.page_title", {
						projectName: currentProject?.project?.name,
					})}
				</title>
			</Helmet>
			<div className="space-y-6">
				<Card className="xl:w-[60%]">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("project.revenue.heading")}
					</Heading>
					<p className="mb-2 whitespace-pre-wrap text-sm text-gray-500">
						{t(`project.revenue.copy`, {
							projectName: currentProject?.project?.name,
						})}
					</p>

					{/** Bullet points */}
					<ul className="ml-0 list-disc whitespace-pre-wrap text-sm text-gray-500">
						{currentProject?.project?.supplier && (
							<li>{t("project.revenue.copy.point.supplier")}</li>
						)}
						<li>{t(`project.revenue.copy.point.date`)}</li>
						<li>
							<Trans
								ns="project"
								i18nKey="project.revenue.cta"
								components={{
									Link: (
										// eslint-disable-next-line
										<a
											href={`${EXTERNAL_PLATFORM_PROJECT_STATISTICS}${currentProject?.project?.name}`}
											target="_blank"
											className="font-medium text-secondary-300 hover:text-secondary-700"
											rel="noreferrer"
										/>
									),
								}}
							/>
						</li>
						{interestPaymentHasUnpaidPayments === true && (
							<li>
								{t(
									`project.revenue.copy.point.unpaid-payments`,
								)}
							</li>
						)}
					</ul>
				</Card>
				<CardWrapper className="overflow-hidden border-b border-gray-200">
					<div className="overflow-x-auto overflow-y-hidden">
						<motion.table className="min-w-full divide-y divide-gray-200">
							<TableHeader
								projectCategory={
									data?.me?.investment_campaigns?.at(0)
										?.category
								}
							/>
							<TableBody role="rowgroup">
								{data?.me?.interest_payments?.map(
									(interestPayment, index) => {
										const interestPeriodEndDate =
											DateTime.fromISO(
												interestPayment?.interestperiod
													?.end,
											)

										/**
										 * Sometimes this is different for the first payment, so for the first payment we should
										 * use "Rentebetaling deadline eerste periode". If that is empty, we can use the
										 * Rentebetaling deadline default to calculate the planned payout date for each year.
										 */
										const applyDelayToCurrentPayment =
											currentProject?.project
												?.payment_period_deadline_days_first_period_delay &&
											index === 0 // First payment
										const daysToAddToInterestPeriodEndDate =
											applyDelayToCurrentPayment
												? currentProject?.project
														?.payment_period_deadline_days_first_period_delay
												: currentProject?.project
														?.payment_period_deadline_days_default

										// Calculate planned payout date
										const interestPeriodPlannedPayoutDate =
											interestPeriodEndDate.plus({
												days:
													daysToAddToInterestPeriodEndDate ||
													0,
											})

										const payment =
											interestPayment?.payment_entries?.find(
												(element) =>
													element !== undefined,
											)

										const paymentSum =
											Number(payment?.cost) +
											Number(payment?.amortization) +
											Number(payment?.repayment)

										return (
											<TableRowCell
												isOdd={index % 2 === 0}
												isInvalid={
													payment?.state !==
													PaymentStateEnum.Done
												}
												key={index}
												data-testid={`item-${interestPeriodEndDate.year}-${index}`}
											>
												<TableDataCell className="w-10 font-medium">
													{index + 1}
												</TableDataCell>
												<TableDataCell className="font-medium">
													{
														interestPayment?.transaction_id
													}
												</TableDataCell>
												<TableDataCell>
													{interestPeriodEndDate
														? interestPeriodEndDate.toFormat(
																dateFormat,
														  )
														: emptyValue()}
												</TableDataCell>

												{/** Planned payout date */}
												<TableDataCell>
													{interestPeriodPlannedPayoutDate
														? interestPeriodPlannedPayoutDate.toFormat(
																dateFormat,
														  )
														: emptyValue()}
												</TableDataCell>
												<TableDataCell>
													{payment?.share_count
														? formatNumber(
																payment.share_count,
														  )
														: emptyValue()}
												</TableDataCell>
												<TableDataCell>
													{payment?.cost
														? formatCurrency(
																payment.cost,
														  )
														: emptyValue()}
												</TableDataCell>
												<TableDataCell>
													{payment?.amortization
														? formatCurrency(
																payment.amortization,
														  )
														: emptyValue()}
												</TableDataCell>
												<TableDataCell>
													{payment?.repayment
														? formatCurrency(
																payment.repayment,
														  )
														: emptyValue()}
												</TableDataCell>

												<TableDataCell>
													{paymentSum
														? formatCurrency(
																paymentSum,
														  )
														: emptyValue()}
												</TableDataCell>
												<TableDataCell>
													{payment?.supplier
														?.is_solar_interest_paid_to_supplier ===
													true
														? payment?.supplier
																?.name
														: "Bank"}
												</TableDataCell>
											</TableRowCell>
										)
									},
								)}
							</TableBody>
						</motion.table>
					</div>
					{data?.me?.interest_payments?.length === 0 && (
						<CardBody>
							<TableEmptyState>
								{t("project.revenue.table.empty_state", {
									date: DateTime.fromISO(
										currentProject?.project
											?.current_interest_period?.end,
									).toFormat(dateFormat),
								})}
							</TableEmptyState>
						</CardBody>
					)}
				</CardWrapper>
			</div>
		</>
	)
}

interface TableHeaderProps {
	projectCategory: Maybe<ProjectHorizonCategoryEnum | undefined>
}
const TableHeader = ({ projectCategory }: TableHeaderProps) => {
	const t = useTrans("project")

	return (
		<TableHead>
			<tr role="row">
				<TableHeading variant="static" className="w-10">
					{t("project.revenue.table.headers.interest_period")}
				</TableHeading>
				<TableHeading variant="static">
					{t("project.revenue.table.headers.transaction_id")}
				</TableHeading>

				<TableHeading variant="static">
					{t("project.revenue.table.headers.date")}
				</TableHeading>
				<TableHeading variant="static">
					{t("project.revenue.table.headers.planned-payout-date")}
				</TableHeading>
				<TableHeading variant="static">
					{projectCategory === "PRO"
						? t("project.revenue.table.headers.solar_bonds")
						: t("project.revenue.table.headers.solar_shares")}
				</TableHeading>

				<TableHeading variant="static">
					{t("project.revenue.table.headers.interest")}
				</TableHeading>
				<TableHeading variant="static">
					{t("project.revenue.table.headers.amortization")}
				</TableHeading>
				<TableHeading variant="static">
					{t("project.revenue.table.headers.compensation")}
				</TableHeading>
				<TableHeading variant="static">
					{t("project.revenue.table.headers.payment")}
				</TableHeading>
				<TableHeading variant="static">
					{t("project.revenue.table.headers.payment_via")}
				</TableHeading>
			</tr>
		</TableHead>
	)
}
