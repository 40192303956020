/// <reference types="../../../types/react-table-config" />

import { Fragment, useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"

// Queries
import {
	InvestmentStateEnum,
	InvestmentType,
	useInvestmentsQuery,
	Maybe,
} from "@/api/graphql"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Animations
import { AnimatePresence, motion } from "@/lib/animations"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Icons
import { FiChevronDown } from "react-icons/fi"

// Tables
import {
	CellProps,
	useExpanded,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { PaginationAsButtons } from "@/components/PaginationAsButtons"
import { classNames } from "@/lib/classnames"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"

/**
 * ProvisionalInvestments
 * @returns
 */
export const ProvisionalInvestments = () => {
	// State
	const [currentPage, setCurrentPage] = useState(0)
	const limit = 5

	const t = { project: useTrans("project"), common: useTrans("common") }
	const { data } = useInvestmentsQuery({
		limit,
		offset: currentPage * limit,
	})
	const { formatCurrency } = useLang()

	// Memo
	const investments = useMemo(() => {
		return data?.me?.provisional_investments?.results
	}, [data?.me?.provisional_investments?.results])

	const columns = useMemo(() => {
		const cols: UseTableOptions<InvestmentType>["columns"] = [
			{
				id: "expander",
				accessor: (investments) => investments as InvestmentType,
				Header: ({ column }) => (
					<td {...column.getHeaderProps({ className: "w-10" })} />
				),
				Cell: ({
					value,
					cell,
					row,
				}: CellProps<InvestmentType, unknown>) => {
					if (
						// @ts-ignore
						value?.refund_reason || // @ts-ignore
						value?.crowdfunding_campaign?.project?.project
							?.issuance_date_in_information_memorandum || // @ts-ignore
						value?.agreement_file
					) {
						return (
							<td
								{...cell.getCellProps({
									className: classNames(
										row.isExpanded
											? "hover:bg-yellow-300"
											: "hover:bg-gray-200",
										"text-gray-500 hover:text-gray-700 w-10",
									),
								})}
							>
								<FiChevronDown
									className={classNames(
										"mx-auto block transform transition",
										row.isExpanded && "rotate-180",
									)}
								/>
							</td>
						)
					}

					// Default empty
					return <td></td>
				},
			},
			{
				id: "project",
				accessor: (investments) =>
					investments.crowdfunding_campaign?.project?.title,
				Header: () => <TableHeading>Project</TableHeading>,
				Cell: ({ value, cell }: CellProps<InvestmentType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{value}
					</TableDataCell>
				),
			},
			{
				accessor: "amount_total",
				Header: () => (
					<TableHeading>
						{t.project(
							"project.provisional_investments.table.heading.total",
						)}
					</TableHeading>
				),
				Cell: ({
					value,
					cell,
				}: CellProps<InvestmentType, Maybe<number> | undefined>) => (
					<TableDataCell {...cell.getCellProps()}>
						{formatCurrency(value || 0)}
					</TableDataCell>
				),
			},
			{
				accessor: "created_at",
				Header: () => (
					<TableHeading>
						{t.project(
							"project.provisional_investments.table.heading.date",
						)}
					</TableHeading>
				),
				Cell: ({ value, cell }: CellProps<InvestmentType, string>) => (
					<TableDataCell {...cell.getCellProps()}>
						{DateTime.fromISO(value).toFormat(dateFormat)}
					</TableDataCell>
				),
			},
			{
				accessor: "state",
				Header: () => (
					<TableHeading>
						{t.project(
							"project.provisional_investments.table.heading.state",
						)}
					</TableHeading>
				),
				Cell: ({
					value,
					row,
				}: CellProps<
					InvestmentType,
					Maybe<InvestmentStateEnum> | undefined
				>) => (
					<TableDataCell>
						{t.common(`common.investment.status.${value}`)}
					</TableDataCell>
				),
			},
		]

		return cols.filter(Boolean)
	}, [])

	// Table
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		state: { pageSize, pageIndex },
		prepareRow,
		visibleColumns,
	} = useTable(
		{
			// @ts-ignore
			columns,
			data: investments as InvestmentType[],
			defaultColumn: {
				Cell: ({
					value,
				}: CellProps<InvestmentType, string | number>) => (
					<TableDataCell>{value}</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
	)

	return (
		<>
			<Helmet>
				<title>
					{t.project("project.provisional_investments.title")}
				</title>
			</Helmet>

			<Card className="ml-5 mr-5">
				<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
					{t.project("project.provisional_investments.title")}
				</Heading>
				<p className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
					{t.project("project.provisional_investments.copy")}
				</p>
			</Card>

			<CardWrapper className="m-5">
				<CardBody>
					<Table
						{...getTableProps({
							className: "min-w-[64rem] lg:min-w-0",
						})}
						data-testid="tablebody"
					>
						<TableHead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => {
										const { key } = column.getHeaderProps()

										return (
											<Fragment key={key}>
												{column.render("Header")}
											</Fragment>
										)
									})}
								</tr>
							))}
						</TableHead>
						<TableBody
							{...getTableBodyProps()}
							data-testid="tablebody-overview"
							data-pageindex={pageIndex}
						>
							{page.map((row) => {
								prepareRow(row)

								const { key, ...rest } = row.getRowProps({
									...row.getToggleRowExpandedProps(),
								})

								const isOdd = row.index % 2 === 0
								let className = ""
								if (row.isExpanded) {
									className =
										"bg-yellow-100 hover:bg-yellow-200 text-gray-900"
								}

								return (
									<Fragment key={row.id}>
										<TableRowCell
											{...rest}
											isOdd={isOdd}
											className={classNames(className)}
										>
											{row.cells.map((cell) => {
												const { key } =
													cell.getCellProps()
												return (
													<Fragment key={key}>
														{cell.render("Cell")}
													</Fragment>
												)
											})}
										</TableRowCell>
										<TableRowCell
											isOdd
											withHover={false}
											className="p-0"
										>
											<td
												colSpan={visibleColumns.length}
												className="p-0"
											>
												<AnimatePresence
													key={key}
													initial={false}
												>
													{row.isExpanded ? (
														<motion.div
															initial={{
																height: 0,
															}}
															animate={{
																height: "auto",
															}}
															exit={{
																height: 0,
															}}
															transition={{
																ease: "easeOut",
															}}
															className="flex overflow-hidden"
														>
															<InvestmentsTableRowExpanded
																investment={
																	row.original
																}
															/>
														</motion.div>
													) : null}
												</AnimatePresence>
											</td>
										</TableRowCell>
									</Fragment>
								)
							})}
							{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
							{page.length < pageSize && pageIndex !== 0 ? (
								<>
									{Array(Math.max(pageSize - page.length, 1))
										.fill(true)
										.map((_, index) => (
											<TableRowCell
												key={index}
												withHover={false}
												isOdd={index % 2 === 0}
											>
												<TableDataCell
													colSpan={columns.length}
												>
													<span className="dummy-text" />
												</TableDataCell>
											</TableRowCell>
										))}
								</>
							) : null}
						</TableBody>
					</Table>

					{investments?.length !== 0 && (
						<div className="mt-6 flex w-full justify-center px-4">
							<PaginationAsButtons
								countPerPage={limit}
								totalCount={
									data?.me?.provisional_investments
										?.totalCount ?? 0
								}
								itemType={
									"common.pagination.item_types.investment"
								}
								currentPage={currentPage + 1}
								currentItemsAmount={
									data?.me?.provisional_investments?.results
										?.length ?? 0
								}
								onNextPage={() =>
									setCurrentPage(
										(currentPage) => currentPage + 1,
									)
								}
								onPrevPage={() =>
									setCurrentPage((currentPage) =>
										Math.max(currentPage - 1, 0),
									)
								}
								analyticsId="investments"
							/>
						</div>
					)}
				</CardBody>
			</CardWrapper>
		</>
	)
}

function InvestmentsTableRowExpanded({ investment }: any) {
	return (
		<div className="p-5">
			{/** Refund */}
			{investment?.refund_reason !== null && (
				<div>
					<b>Refund reason: </b>
					{investment?.refund_reason}
				</div>
			)}

			{/** Agreement file */}
			{investment.agreement_file && (
				<div>
					<b>Agreement file: </b>
					<a
						target="_blank"
						rel="noreferrer"
						href={investment.agreement_file}
						className={classNames(
							"text-gray-500 hover:text-gray-700",
							"text-sm font-medium",
						)}
					>
						Download Agreement
					</a>
				</div>
			)}

			{/** Share Issuance date */}
			{investment.crowdfunding_campaign?.project?.project
				?.issuance_date_in_information_memorandum && (
				<div>
					<b>The project will formaly issue the bonds: </b>
					{DateTime.fromISO(
						investment.crowdfunding_campaign?.project?.project
							?.issuance_date_in_information_memorandum,
					).toFormat(dateFormat)}
				</div>
			)}
		</div>
	)
}
