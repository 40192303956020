// Import all pages
import { NotFound } from "@/pages/NotFound"
import { Info } from "@/pages/Info"
import { Register } from "@/pages/Register"
import { Confirm } from "@/pages/Confirm"
import { SignIn } from "@/pages/SignIn"
import { Reset } from "@/pages/Reset"
import { Recover } from "@/pages/Recover"
import { Dashboard } from "@/pages/dashboard"
import { Profile } from "@/pages/profile"
import { Investor } from "@/pages/investor"
import { Recent } from "@/pages/Recent"
import { FinanceDashboard } from "@/pages/finance/pages/Dashboard"
import { FinancePerformance } from "@/pages/finance/pages/Performance"
import { FinanceSolarInterestTariff } from "@/pages/finance/pages/SolarInterestTariff"
import { Payments } from "@/pages/payments"
import { Project } from "@/pages/project"
import { ProjectNotFound } from "@/pages/project/ProjectNotFound"
import { InvestmentsPage } from "@/pages/investments"

// TODO: This should reflect src/pages folder structure
export enum Pages {
	// Auth / Login related pages. TODO: UNUSED
	Register = "/register",
	RegisterConfirm = "/confirm",
	Login = "/login",
	RecoverPassword = "/recover",
	ResetPassword = "/reset",

	// Investments
	Investments = "/investments",
	InvestmentsProjects = "/investments/projects",
	InvestmentsProjectsDashboard = "/investments/projects/dashboard",
	InvestmentsProjectsFiscalOverview = "/investments/projects/dashboard/fiscal-overview",
	InvestmentsProjectsProduction = "/investments/projects/dashboard/production",
	ProvisionalInvestments = "/investments/projects/dashboard/provisional",
	InvestmentsDashboard = "/investments/dashboard",

	// Finance B2B
	Finance = "/finance",
	FinanceProjects = "/finance/projects",
	FinanceInterestPayments = "finance/interest-payments",
	FinanceSolarInterestTariff = "/finance/solar-interest-tariff",

	// Investor profile
	InvestorProfile = "/investor",
	InvestorIdentity = "/investor/identity",
	InvestorTest = "/investor/test",
	InvestorRisk = "/investor/risk",
	InvestorFunds = "/investor/funds",

	// Settings
	Settings = "/settings", // TODO: UNUSED?
	SettingsProfile = "/settings/profile",
	SettingsProfilePersonalDetails = "/settings/profile",
	SettingsProfileCompanyDetails = "/settings/profile/company-details",
	SettingsProfileBankDetails = "/settings/profile/bank-details",
	SettingsProfileChangePassword = "/settings/profile/change-password",
	SettingsProfileNotifications = "/settings/profile/notifications",
	SettingsProfileSecurity = "/settings/profile/security",
	SettingsProfileSecurityRegenerateBackupCodes = "/settings/profile/security/regenerate-backup-codes",

	Info = "/info",
	Communications = "/communications",
}

/**
 * Lazy loading components
 * DOCS: https://react.dev/reference/react/lazy
 */
export const LazyLoadingPages = {
	NotFound,
	Info,
	Register,
	Confirm,
	SignIn,
	Reset,
	Recover,
	Dashboard,
	Profile,
	Investor,
	Recent,
	FinanceDashboard,
	FinancePerformance,
	FinanceSolarInterestTariff,
	Payments,
	Project,
	ProjectNotFound,
	Investments: InvestmentsPage,
}
